import {driversAPI} from "../api/register-api";
import {setVisibleSelectProfileWindow} from "./reducers/partnerReducer";

export const checkDraftProfile = async (id, dispatch) =>{
    // проверяем наличие нормальной анкерты
   let response = await driversAPI.getData(id);
   if (!response || !response.data) return false;
   let data = response.data;
   if ((data.hasOwnProperty('draft') && data.draft)  || !data.id) return false;

   // проверяем наличие черновика анкерты
   response = await driversAPI.getData(id,true);
   if (!response || !response.data || !Array.isArray(response.data) || !response.data.length) return false;
   let data_draft = response.data[0];
   if (!data_draft.draft || !data_draft.id) return false;

   return new Promise((resolve)=> {
      dispatch(setVisibleSelectProfileWindow([data, data_draft]));
      document.addEventListener('SelectProfileActive', () => {
         resolve(data);
         document.removeEventListener('SelectProfileActive', this);
      });
      document.addEventListener('SelectProfileDraft', () => {
         resolve(data_draft);
         document.removeEventListener('SelectProfileDraft', this);
      });
   })


}
