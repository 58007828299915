import { forwardRef, useRef, useState, useEffect } from "react";
import Loader from "react-loader-spinner";

const Select = forwardRef((props, ref) => {
	const dropdownRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [focus, setFocus] = useState(-1);
	const self = useRef(null);
	const [selected, setSelected] = useState(false);

	useEffect(() => {
		function handleClickOutside(e) {
			if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
				setOpen(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	});

	useEffect(() => {
		if (props.close && open) {
			setOpen(false);
			if (props.onClose) props.onClose();
		}
	}, [props, open]);

	useEffect(() => {
		if (open && dropdownRef.current && props.options && props.options.length > 0 && focus >= 0) {
			dropdownRef.current.children[focus].focus();
		}
	}, [open, props.options, focus]);
	return (
		<>
			<div className="select" tabIndex="1" ref={self}
				onFocus={(e) => {
					if (self.current.contains(e.target) && (!selected || props.leaveOpen)) {
						setOpen(true);
					}
				}}
				onBlur={(e) => {
					if (!self.current.contains(e.target) || (selected && !props.leaveOpen)) {
						setOpen(false);
						setSelected(false);
					}
				}}
				onKeyDown={(e) => {
					if (e.key === 'ArrowDown' && dropdownRef.current && props.options &&
						props.options.length > 0 && focus < props.options.length - 1) {
						e.preventDefault();
						dropdownRef.current.children[focus + 1].focus();
						setFocus(focus + 1);
					}
					if (e.key === 'ArrowUp' && dropdownRef.current && props.options &&
						props.options.length > 0 && focus > 0) {
						e.preventDefault();
						dropdownRef.current.children[focus - 1].focus();
						setFocus(focus - 1);
					}
					if (e.key === 'Escape') {
						e.preventDefault();
						setOpen(false);
					}
					if (e.key === 'Enter' && dropdownRef.current && props.options &&
						props.options.length > 0 && focus >= 0) {
						e.preventDefault();
						setSelected(true);
						if (props.onSelect) props.onSelect(props.options[focus]);
						if (!props.leaveOpen) setOpen(false);
					}
				}}
			>
				<input id={props.id || ''} className={props.className ? ' ' + props.className : ''}
					readOnly type="text" placeholder={props.loading ? '' : props.placeholder} ref={ref}
					value={props.loading || (!props.value && !props.contentRenderer) ? '' : (props.contentRenderer ?
						props.contentRenderer() : (props.display ?
						props.display(props.fieldName ? props.value[props.fieldName] : props.value) :
						(props.fieldName ? props.value[props.fieldName] : props.value)))}
					onMouseDown={(e) => {
						e.preventDefault();
						e.stopPropagation();
						if (!open && props.onOpen) props.onOpen();
						setOpen(!open);
						if (!open) self.current.focus();
					}} />
				{props.loading ?
					<Loader className="select_loader" type="Puff" color="#FFB700" height={30} width={30} />
				:
					<>
						<div ref={dropdownRef} className={'select_dropdown' + (open ?
							' select_dropdown-open' : '')}>
							{props.dropdownRenderer ?
								(props.dropdownRenderer() ? props.dropdownRenderer() :
									<div className="select_dropdown_nodata">Нет данных</div>
								)
							:
								(props.options && props.options.length > 0 ?
									props.options.map((option, index) => (
										<div key={'option_' + index} className="select_dropdown_item" tabIndex="1"
											onMouseDown={() => {
												setSelected(true);
												if (props.onSelect) props.onSelect(option);
												if (!props.leaveOpen) setOpen(false);
											}}
										>
											<div className="select_dropdown_item_text">
												{props.display ? props.display(props.fieldName ?
													option[props.fieldName] : option) :
													props.fieldName ? option[props.fieldName] : option
												}
											</div>
										</div>
									))
								:
									<div className="select_dropdown_nodata">Нет данных</div>
								)
							}
						</div>
						<div className="select_dropdown_handle" onMouseDown={(e) => {
							e.preventDefault();
							e.stopPropagation();
							if (!open && props.onOpen) props.onOpen();
							setOpen(!open);
						}}></div>
					</>
				}
			</div>
		</>
	);
});

export default Select;
