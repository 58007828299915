import { tinAPI, driversAPI, getPhoto } from "../../api/register-api";
import { partiesAPI, getAddress } from "../../api/dadata-api";
import { getBoolean, createNumber } from "../../components/validate";
import { validateOwner, setOwnerComplete } from "./ownerReducer";
import { validateDriver, setDriverComplete } from "./driverReducer";
import { validateBank, setBankComplete } from "./bankReducer";
import { validateVehicle, setVehicleComplete } from "./vehicleReducer";
import { checkDraftProfile } from "./../common_functions";

export const SINGLE_CAR_OWNER = 'SINGLE_CAR_OWNER';
export const MULTIPLE_CARS_OWNER = 'MULTIPLE_CARS_OWNER';
export const TIN_IP = 'TIN_IP';
export const TIN_SAM = 'TIN_SAM';

const EMPTY = '00000000-0000-0000-0000-000000000000';
const MEDICAL_BOOK = '9cebde1b-25e2-11ea-a9ad-00155d8e4e05';
const DRIVER_LOADER = '9cebde1a-25e2-11ea-a9ad-00155d8e4e05';
const RAMP = 'fc3de6d0-40dd-11ea-a9c6-00155d8e4e05';
const PASS_1_CKAD = '7a03cb82-5ee8-4095-acc0-fb352c48cf44';
const PASS_2_MKAD = '60bdcf2e-ae63-11e2-90ae-001a64b04e42';
const PASS_3_TTK = '22dba865-4bd4-11df-ab17-005056000008';
const PASS_4_SK = '22dba867-4bd4-11df-ab17-005056000008';


const SET_ID = 'SET_ID';
const SET_OWNER_TYPE = 'SET_OWNER_TYPE';
const SET_TIN = 'SET_TIN';
const SET_LATER = 'SET_LATER';
const SET_TIN_VERIFY_STATUS = 'SET_TIN_VERIFY_STATUS';
const SHOW_BANK_FORM = 'SHOW_BANK_FORM';
const SET_BANK_DATA = 'SET_BANK_DATA';
const SHOW_DRIVER_FORM = 'SHOW_DRIVER_FORM';
const SET_OWNER_DATA = 'SET_OWNER_DATA';
const SET_DRIVER_DATA = 'SET_DRIVER_DATA';
const REMOVE_DRIVER = 'REMOVE_DRIVER';
const SHOW_VEHICLE_FORM = 'SHOW_VEHICLE_FORM';
const SET_VEHICLE_DATA = 'SET_VEHICLE_DATA';
const REMOVE_VEHICLE = 'REMOVE_VEHICLE';
const SHOW_REFERRER_FORM = 'SHOW_REFERRER_FORM';
const SET_REFERRER_DATA = 'SET_REFERRER_DATA';
const SET_CHANGED = 'SET_CHANGED';
const SET_NEEDS_SAVE = 'SET_NEEDS_SAVE';
const SET_DRAFT = 'SET_DRAFT';
const SET_IS_EDIT_USER = 'SET_IS_EDIT_USER';
const SET_LOADING_DATA = 'SET_LOADING_DATA';
const SET_VISIBLE_SELECT_PROFILE_WINDOW = 'SET_VISIBLE_SELECT_PROFILE_WINDOW';

let initialState = {
	id: '',
	owner_type: '',
	tin_type: '',
	tin: '',
	later: false,
	tin_verify_status: '',
	show_bank_form: false,
	show_driver_form: false,
	show_vehicle_form: false,
	show_referrer_form: false,
	is_owner: false,
	bank: null,
	owner: null,
	drivers: [],
	vehicles: [],
	referrers: [],
	changed: false,
	needs_save: false,
	draft: true,
	is_edit_user: false,
	is_loading_data: false,
	is_select_profile_visible: undefined,
};

const partnerReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_ID:
			return {
				...state,
				id: action.id
			};
		case SET_OWNER_TYPE:
			return {
				...state,
				owner_type: action.owner_type
			};
		case SET_TIN:
			return {
				...state,
				tin_type: action.tin_type,
				tin: action.tin
			}
		case SET_LATER:
			return {
				...state,
				later: action.later
			};
		case SET_TIN_VERIFY_STATUS:
			return {
				...state,
				tin_verify_status: action.status
			};
		case SHOW_BANK_FORM:
			return {
				...state,
				show_bank_form: action.show
			};
		case SET_BANK_DATA:
			return {
				...state,
				bank: action.bank
			};
		case SHOW_DRIVER_FORM:
			return {
				...state,
				show_driver_form: action.show,
				is_owner: action.is_owner
			}
		case SET_VISIBLE_SELECT_PROFILE_WINDOW:
			return {
				...state,
				is_select_profile_visible: action.value,
			}
		case SET_LOADING_DATA:
			return {
				...state,
				is_loading_data: action.value
			}
		case SET_OWNER_DATA:
			return {
				...state,
				owner: {
					name: action.owner.name,
					phones: action.owner.phones,
					email: action.owner.email,
					birthdate: action.owner.birthdate,
					passport_number: action.owner.passport_number,
					passport_issued_by: action.owner.passport_issued_by,
					passport_issue_date: action.owner.passport_issue_date,
					passport_department: action.owner.passport_department,
					passport_registration: action.owner.passport_registration,
					address: action.owner.address,
					same_address: action.owner.same_address,
					passport_photo_id_0: action.owner.passport_photo_id_0,
					passport_photo_id_1: action.owner.passport_photo_id_1,
					passport_photo_id_2: action.owner.passport_photo_id_2,
					passport_photo_0: action.owner.passport_photo_0,
					passport_photo_1: action.owner.passport_photo_1,
					passport_photo_2: action.owner.passport_photo_2,
					is_driver: action.owner.is_driver,
					license_number: action.owner.license_number,
					license_issue_date: action.owner.license_issue_date,
					license_validity: action.owner.license_validity,
					license_issued_by: action.owner.license_issued_by,
					license_country: action.owner.license_country,
					license_categories: action.owner.license_categories,
					license_photo_id_0: action.owner.license_photo_id_0,
					license_photo_id_1: action.owner.license_photo_id_1,
					license_photo_0: action.owner.license_photo_0,
					license_photo_1: action.owner.license_photo_1,
					complete: action.owner.complete
				}
			}
		case SET_DRIVER_DATA:
			let drivers = state.drivers;
			let driver = {
				name: action.driver.name,
				phones: action.driver.phones,
				email: action.driver.email,
				birthdate: action.driver.birthdate,
				passport_number: action.driver.passport_number,
				passport_issued_by: action.driver.passport_issued_by,
				passport_issue_date: action.driver.passport_issue_date,
				passport_department: action.driver.passport_department,
				passport_registration: action.driver.passport_registration,
				address: action.driver.address,
				same_address: action.driver.same_address,
				passport_photo_id_0: action.driver.passport_photo_id_0,
				passport_photo_id_1: action.driver.passport_photo_id_1,
				passport_photo_id_2: action.driver.passport_photo_id_2,
				passport_photo_0: action.driver.passport_photo_0,
				passport_photo_1: action.driver.passport_photo_1,
				passport_photo_2: action.driver.passport_photo_2,
				license_number: action.driver.license_number,
				license_issue_date: action.driver.license_issue_date,
				license_validity: action.driver.license_validity,
				license_issued_by: action.driver.license_issued_by,
				license_country: action.driver.license_country,
				license_categories: action.driver.license_categories,
				license_photo_id_0: action.driver.license_photo_id_0,
				license_photo_id_1: action.driver.license_photo_id_1,
				license_photo_0: action.driver.license_photo_0,
				license_photo_1: action.driver.license_photo_1,
				complete: action.driver.complete
			};
			if (action.index < drivers.length) {
				drivers[action.index] = driver;
			} else {
				drivers.push(driver);
			}
			return {
				...state,
				drivers: drivers
			}
		case REMOVE_DRIVER:
			let driversToRemove = state.drivers;
			driversToRemove.splice(action.index, 1);
			return {
				...state,
				drivers: driversToRemove
			}
		case SHOW_VEHICLE_FORM:
			return {
				...state,
				show_vehicle_form: action.show
			}
		case SET_VEHICLE_DATA:
			let vehicles = state.vehicles;
			let pass = action.vehicle.characteristics.find((x)=> x.id === PASS_1_CKAD && x.value === true);
			if (!pass) pass = action.vehicle.characteristics.find((x)=> x.id === PASS_2_MKAD && x.value === true);
			if (!pass) pass = action.vehicle.characteristics.find((x)=> x.id === PASS_3_TTK && x.value === true);
			if (!pass) pass = action.vehicle.characteristics.find((x)=> x.id === PASS_4_SK && x.value === true);
			if (!pass) pass = '';
			let vehicle = {
				car_type: action.vehicle.car_type,
				body_option: action.vehicle.body_option,
				characteristics: action.vehicle.characteristics,
				pass: pass,
				hydroboard: action.vehicle.hydroboard,
				ramp: action.vehicle.ramp,
				length: action.vehicle.length,
				width: action.vehicle.width,
				height: action.vehicle.height,
				garage: action.vehicle.garage,
				medical: action.vehicle.medical,
				loader: action.vehicle.loader,
				vehicle_photo_id_0: action.vehicle.vehicle_photo_id_0,
				vehicle_photo_id_1: action.vehicle.vehicle_photo_id_1,
				vehicle_photo_id_2: action.vehicle.vehicle_photo_id_2,
				vehicle_photo_0: action.vehicle.vehicle_photo_0,
				vehicle_photo_1: action.vehicle.vehicle_photo_1,
				vehicle_photo_2: action.vehicle.vehicle_photo_2,
				license_plate: action.vehicle.license_plate,
				vin: action.vehicle.vin,
				brand: action.vehicle.brand,
				model: action.vehicle.model,
				type: action.vehicle.type,
				year: action.vehicle.year,
				ecology: action.vehicle.ecology,
				sts_series: action.vehicle.sts_series,
				sts_number: action.vehicle.sts_number,
				max_weight: action.vehicle.max_weight,
				load_weight: action.vehicle.load_weight,
				certificate_photo_id_0: action.vehicle.certificate_photo_id_0,
				certificate_photo_id_1: action.vehicle.certificate_photo_id_1,
				certificate_photo_0: action.vehicle.certificate_photo_0,
				certificate_photo_1: action.vehicle.certificate_photo_1,
				driver: action.vehicle.driver,
				complete: action.vehicle.complete
			}
			if (action.index < vehicles.length) {
				vehicles[action.index] = vehicle;
			} else {
				vehicles.push(vehicle);
			}
			return {
				...state,
				vehicles: vehicles
			}
		case REMOVE_VEHICLE:
			let vehiclesToRemove = state.vehicles;
			vehiclesToRemove.splice(action.index, 1);
			return {
				...state,
				vehicles: vehiclesToRemove
			}
		case SHOW_REFERRER_FORM:
			return {
				...state,
				show_referrer_form: action.show
			}
		case SET_REFERRER_DATA:
			let refs = [];
			if (action.referrer0) refs.push(action.referrer0);
			if (action.referrer1) refs.push(action.referrer1);
			if (action.referrer2) refs.push(action.referrer2);
			return {
				...state,
				referrers: refs
			}
		case SET_CHANGED:
			return {
				...state,
				changed: action.value
			}
		case SET_NEEDS_SAVE:
			return {
				...state,
				needs_save: action.value
			}
		case SET_DRAFT:
			return {
				...state,
				draft: action.value
			}
		case SET_IS_EDIT_USER:
			return {
				...state,
				is_edit_user: action.value
			}
		default:
			return state;
	}
};

export const setId = (id) => ({type: SET_ID, id});
export const setOwnerType = (owner_type) => ({type: SET_OWNER_TYPE, owner_type});
export const setTin = (tin_type, tin) => ({type: SET_TIN, tin_type, tin});
export const setTinVerifyStatus = (status) => ({type: SET_TIN_VERIFY_STATUS, status});
export const setLater = (later) => ({type: SET_LATER, later});
export const showBankForm = (show) => ({type: SHOW_BANK_FORM, show});
const setBankData = (bank) => ({type: SET_BANK_DATA, bank});
export const showDriverForm = (show, is_owner) => ({type: SHOW_DRIVER_FORM, show, is_owner});
const setOwnerData = (owner) => ({type: SET_OWNER_DATA, owner});
const setDriverData = (index, driver) => ({type: SET_DRIVER_DATA, index, driver});
export const removeDriver = (index) => ({type: REMOVE_DRIVER, index});
export const showVehicleForm = (show) => ({type: SHOW_VEHICLE_FORM, show});
export const setVehicleData = (index, vehicle) => ({type: SET_VEHICLE_DATA, index, vehicle});
export const removeVehicle = (index) => ({type: REMOVE_VEHICLE, index});
export const showReferrerForm = (show) => ({type: SHOW_REFERRER_FORM, show});
export const setReferrerData = (referrer0, referrer1, referrer2) => (
	{type: SET_REFERRER_DATA, referrer0, referrer1, referrer2}
);
export const setChanged = (value) => ({type: SET_CHANGED, value});
export const setNeedsSave = (value) => ({type: SET_NEEDS_SAVE, value});
export const setDraft = (value) => ({type: SET_DRAFT, value});
export const setIsEditUser = (value) => ({type: SET_IS_EDIT_USER, value});
export const setLoadingData = (value) => ({type: SET_LOADING_DATA, value});
export const setVisibleSelectProfileWindow = (value) => ({type: SET_VISIBLE_SELECT_PROFILE_WINDOW, value});

export const verifyTinThunk = (tin) => async (dispatch) => {
	dispatch(setTinVerifyStatus('verifying'));
	let response = await tinAPI.checkTin(tin);
	if (response.status === 200 && response.data) {
		if (response.data.exist) {
			dispatch(setTinVerifyStatus('exists'));
		} else {
			dispatch(setTinVerifyStatus('OK'));
		}
	} else {
		dispatch(setTinVerifyStatus('error'));
	}
}

export const storeBankData = () => (dispatch, getState) => {
	let bank = getState().bankReducer;
	dispatch(setBankData(bank));
}

export const storeOwnerData = () => (dispatch, getState) => {
	let owner = getState().ownerReducer;
	dispatch(setOwnerData(owner));
}

export const storeDriverData = (index) => (dispatch, getState) => {
	let driver = getState().driverReducer;
	dispatch(setDriverData(index, driver));
}

export const storeVehicleData = (index) => (dispatch, getState) => {
	let vehicle = getState().vehicleReducer;
	dispatch(setVehicleData(index, vehicle));
}

export const storeReferrerData = () => (dispatch, getState) => {
	let referrer0 = getState().referrerReducer.referrer0;
	let referrer1 = getState().referrerReducer.referrer1;
	let referrer2 = getState().referrerReducer.referrer2;
	dispatch(setReferrerData(referrer0, referrer1, referrer2));
}

export const loadData = (id, driverId) => async (dispatch, getState) => {

	dispatch(setLoadingData(true));


	let response;
	let data = null;
	if (id && id !== '') {
		response = await driversAPI.getData(id);
		if (response && response.data && response.data.id) data = response.data;
		if (response && response.data && Array.isArray(response.data)) data = response.data[0];
	}
	if (driverId && driverId !== '') {
		response = await driversAPI.getDriverData(driverId);
		if (response && response.data) data = response.data;
	}



	if (data && data.status !== 'error') {

		let tmp = await checkDraftProfile(data.id, dispatch);
		if (tmp) data = tmp;

		console.log('selected', tmp);

		dispatch(setDraft(data.hasOwnProperty('draft') ? data.draft : false));
		let multiple = true;
		if (data.multiple_car_owner === false) multiple = false;
		if (multiple) {
			dispatch(setOwnerType(MULTIPLE_CARS_OWNER));
			if (data.TIN) {
				dispatch(setTin(TIN_IP, data.TIN));
				dispatch(setTinVerifyStatus('OK'));
			} else {
				dispatch(setLater(true));
			}
		} else {
			dispatch(setOwnerType(SINGLE_CAR_OWNER));
			if (data.TIN) {
				if (data.type === 'SELF_EMPLOYED') {
					dispatch(setTin(TIN_SAM, data.TIN));
				} else {
					dispatch(setTin(TIN_IP, data.TIN));
				}
				dispatch(setTinVerifyStatus('OK'));
			} else {
				dispatch(setTin(TIN_IP, ''));
				dispatch(setLater(true));
			}
		}
		if (data.banking) {
			const bankAddress = await getAddress(data.banking.bank.adress);
			let bank = {
				account: data.banking.account_number,
				bic: data.banking.bank.BIK,
				name: data.banking.bank.name,
				corr: data.banking.bank.correction_number,
				inn: data.banking.bank.TIN,
				kpp: data.banking.bank.KPP,
				address: bankAddress
			}
			bank.complete = validateBank(bank);
			dispatch(setBankData(bank));
		}
		const name = data.person.full_name;
		let owner = null;
		if (multiple && data.person && data.passport) {
			const ownerAddress = await getAddress(data.passport.real_adress);
			owner = {
				name,
				phones: Array.isArray(data.person.phone_number) ?
					data.person.phone_number : [data.person.phone_number],
				email: data.person.email,
				birthdate: data.passport.birth_date ? new Date(
					data.passport.birth_date.slice(0, 4),
					data.passport.birth_date.slice(5, 7) - 1,
					data.passport.birth_date.slice(8, 10)
				) : null,
				passport_number: createNumber(data.passport.series, data.passport.number),
				passport_issue_date: data.passport.issue_date ? new Date(
					data.passport.issue_date.slice(0, 4),
					data.passport.issue_date.slice(5, 7) - 1,
					data.passport.issue_date.slice(8, 10)
				) : null,
				passport_issued_by: data.passport.issued_by,
				address: ownerAddress,
				passport_photo_id_0: data.passport.files.length > 0 ?
					data.passport.files[0] : '',
				passport_photo_id_1: data.passport.files.length > 1 ?
					data.passport.files[1] : '',
				passport_photo_id_2: data.passport.files.length > 2 ?
					data.passport.files[2] : '',
				passport_photo_0: data.passport.files.length > 0 ?
					await getPhoto(data.passport.files[0], 'owner-passport-front.jpg') : null,
				passport_photo_1: data.passport.files.length > 1 ?
					await getPhoto(data.passport.files[1], 'owner-passport-registration.jpg') : null,
				passport_photo_2: data.passport.files.length > 2 ?
					await getPhoto(data.passport.files[2], 'owner-selfie.jpg') : null,
				is_driver: false
			}
			owner.complete = validateOwner(owner);
		}
		let drivers = [];
		let driver = null;
		let driverAddress;
		let driverIndex = 0;
		if (data.drivers) {
			for (let ii=0; ii< data.drivers.length; ii++)
			{
				let dr = data.drivers[ii];
				driverAddress = (owner && owner.name === dr.passport.full_name) ? null :
					await getAddress(dr.passport.real_adress);
				driver = {
					name: dr.passport.full_name,
					phones: Array.isArray(dr.phone_number) ? dr.phone_number : [dr.phone_number],
					birthdate: dr.passport.birth_date ? new Date(
						dr.passport.birth_date.slice(0, 4),
						dr.passport.birth_date.slice(5, 7) - 1,
						dr.passport.birth_date.slice(8, 10)
					) : null,
					passport_number: createNumber(dr.passport.series, dr.passport.number),
					passport_issued_by: dr.passport.issued_by,
					passport_issue_date: dr.passport.issue_date ? new Date(
						dr.passport.issue_date.slice(0, 4),
						dr.passport.issue_date.slice(5, 7) - 1,
						dr.passport.issue_date.slice(8, 10)
					) : null,
					address: driverAddress,
					passport_photo_id_0: dr.passport.files.length > 0 ? dr.passport.files[0] : '',
					passport_photo_id_1: dr.passport.files.length > 1 ? dr.passport.files[1] : '',
					passport_photo_id_2: dr.passport.files.length > 2 ? dr.passport.files[2] : '',
					passport_photo_0: (!owner || owner.name !== dr.passport.full_name) && dr.passport.files.length > 0 ?
						await getPhoto(dr.passport.files[0], 'driver-passport-front.jpg') : null,
					passport_photo_1: (!owner || owner.name !== dr.passport.full_name) && dr.passport.files.length > 1 ?
						await getPhoto(dr.passport.files[1], 'driver-passport-registration.jpg') : null,
					passport_photo_2: (!owner || owner.name !== dr.passport.full_name) && dr.passport.files.length > 2 ?
						await getPhoto(dr.passport.files[2], 'driver-selfie.jpg') : null,
					license_number: createNumber(dr.driver_certificate.serial, dr.driver_certificate.number),
					license_issue_date: dr.driver_certificate.issue_date ? new Date(
						dr.driver_certificate.issue_date.slice(0, 4),
						dr.driver_certificate.issue_date.slice(5, 7) - 1,
						dr.driver_certificate.issue_date.slice(8, 10)
					) : null,
					license_validity: dr.driver_certificate.validity ? new Date(
						dr.driver_certificate.validity.slice(0, 4),
						dr.driver_certificate.validity.slice(5, 7) - 1,
						dr.driver_certificate.validity.slice(8, 10)
					) : null,
					license_issued_by: dr.driver_certificate.issued_by,
					license_country: dr.driver_certificate.country,
					license_categories: dr.driver_certificate.category,
					license_photo_id_0: dr.driver_certificate.files.length > 0 ? dr.driver_certificate.files[0] : '',
					license_photo_id_1: dr.driver_certificate.files.length > 1 ? dr.driver_certificate.files[1] : '',
					license_photo_0: dr.driver_certificate.files.length > 0 ?
						await getPhoto(dr.driver_certificate.files[0], 'driver-license-front.jpg') : null,
					license_photo_1: dr.driver_certificate.files.length > 1 ?
						await getPhoto(dr.driver_certificate.files[1], 'driver-license-rear.jpg') : null
				}
				driver.complete = validateDriver(driver);
				if (owner && owner.name === driver.name) {
					owner.is_driver = true;
					owner.license_number = driver.license_number;
					owner.license_issue_date = driver.license_issue_date;
					owner.license_validity = driver.license_validity;
					owner.license_issued_by = driver.license_issued_by;
					owner.license_country = driver.license_country;
					owner.license_categories = driver.license_categories;
					owner.license_photo_id_0 = driver.license_photo_id_0;
					owner.license_photo_id_1 = driver.license_photo_id_1;
					owner.license_photo_0 = driver.license_photo_0;
					owner.license_photo_1 = driver.license_photo_1;
					owner.complete = validateOwner(owner);
					dispatch(setOwnerData(owner));
				} else {
					drivers.push(driver);
					dispatch(setDriverData(driverIndex++, driver));
				}
			}
		}
		let vehicles = [];
		let vehicle = null;
		if (data.cars) {
			for (let carIndex=0; carIndex< data.cars.length; carIndex++)
			 {
				 // data.cars.forEach(async (car, carIndex) =>
				let car = data.cars[carIndex];

				vehicle = {
					car_type: car.car_type_id === EMPTY ? '' : car.car_type_id,
					body_option: car.car_type_id === EMPTY || car.body_option_id === EMPTY ? '' : car.body_option_id,
					characteristics: [],
					pass: car.pass ? car.pass : '',
					ramp: '',
					length: car.size && car.size.length && car.size.length > 0 ? car.size.length.toString() : '',
					width: car.size && car.size.width && car.size.width > 0 ? car.size.width.toString() : '',
					height: car.size && car.size.height && car.size.height > 0 ? car.size.height.toString() : '',
					medical: false,
					loader: false,
					vehicle_photo_id_0: car.files.length > 0 ? car.files[0] : '',
					vehicle_photo_id_1: car.files.length > 1 ? car.files[1] : '',
					vehicle_photo_id_2: car.files.length > 2 ? car.files[2] : '',
					vehicle_photo_0: car.files.length > 0 ?
						await getPhoto(car.files[0], 'car-inside.jpg') : null,
					vehicle_photo_1: car.files.length > 1 ?
						await getPhoto(car.files[1], 'car-front.jpg') : null,
					vehicle_photo_2: car.files.length > 2 ?
						await getPhoto(car.files[2], 'car-side.jpg') : null,
					license_plate: car.certificate.number ? car.certificate.number : '',
					vin: car.certificate.VIN ? car.certificate.VIN : '',
					brand: car.certificate.brand ? car.certificate.brand : '',
					model: car.certificate.model ? car.certificate.model : '',
					year: car.certificate.date ? car.certificate.date : '',
					sts_series: car.certificate.certificate_serial ? car.certificate.certificate_serial : '',
					sts_number: car.certificate.certificate_number ? car.certificate.certificate_number : '',
					max_weight: car.certificate.max_weight && car.certificate.max_weight > 0 ?
						car.certificate.max_weight : '',
					load_weight: car.certificate.free_weight && car.certificate.free_weight > 0 ?
						car.certificate.free_weight : '',
					certificate_photo_id_0: car.certificate.files.length > 0 ? car.certificate.files[0] : '',
					certificate_photo_id_1: car.certificate.files.length > 1 ? car.certificate.files[1] : '',
					certificate_photo_0: car.certificate.files.length > 0 ?
						await getPhoto(car.certificate.files[0], 'car-certificate-front.jpg') : null,
					certificate_photo_1: car.certificate.files.length > 1 ?
						await getPhoto(car.certificate.files[1], 'car-certificate-rear.jpg') : null,
					driver: null
				}
				if (vehicle.car_type !== '' && vehicle.body_option !== '') {
					let ramp = '';
					let medical = false;
					let loader = false;
					car.body_option_characteristics.forEach((ch) => {
						if (ch.value === EMPTY) return;
						switch (ch.id) {
							case RAMP:
								ramp = ch.value;
								break;
							case MEDICAL_BOOK:
								medical = getBoolean(ch.value);
								break;
							case DRIVER_LOADER:
								loader = getBoolean(ch.value);
								break;
							default:
								break;
						}
					});

					let pass = car.body_option_characteristics.find((x)=> x.id === PASS_1_CKAD && x.value === true);
					if (!pass) pass = car.body_option_characteristics.find((x)=> x.id === PASS_2_MKAD && x.value === true);
					if (!pass) pass = car.body_option_characteristics.find((x)=> x.id === PASS_3_TTK && x.value === true);
					if (!pass) pass = car.body_option_characteristics.find((x)=> x.id === PASS_4_SK && x.value === true);
					if (!pass) pass = '';

					vehicle.characteristics = car.body_option_characteristics;
					vehicle.ramp = ramp;
					vehicle.pass = pass;
					vehicle.medical = medical;
					vehicle.loader = loader;
				}
				vehicle.complete = validateVehicle(vehicle);
				let found;
				let vehicleIndex = 0;
				car.drivers.forEach(async (dr, i) => {
					found = drivers.find(d => d.name === dr.passport.full_name);
					if (found) {
						if (i === 0) vehicle.driver = found;
						return;
					}
					driverAddress = (owner && owner.name === dr.passport.full_name) ? null :
						await getAddress(dr.passport.real_adress);
					driver = {
						name: dr.passport.full_name ? dr.passport.full_name : '',
						phones: Array.isArray(dr.phone_number) ? dr.phone_number : [dr.phone_number],
						birthdate: dr.passport.birth_date ? new Date(
							dr.passport.birth_date.slice(0, 4),
							dr.passport.birth_date.slice(5, 7) - 1,
							dr.passport.birth_date.slice(8, 10)
						) : null,
						passport_number: createNumber(dr.passport.series, dr.passport.number),
						passport_issued_by: dr.passport.issued_by ? dr.passport.issued_by : '',
						passport_issue_date: dr.passport.issue_date ? new Date(
							dr.passport.issue_date.slice(0, 4),
							dr.passport.issue_date.slice(5, 7) - 1,
							dr.passport.issue_date.slice(8, 10)
						) : null,
						address: driverAddress,
						passport_photo_id_0: (!owner || owner.name !== dr.passport.full_name) && dr.passport.files.length > 0 ?
							dr.passport.files[0] : '',
						passport_photo_id_1: (!owner || owner.name !== dr.passport.full_name) && dr.passport.files.length > 1 ?
							dr.passport.files[1] : '',
						passport_photo_id_2: (!owner || owner.name !== dr.passport.full_name) && dr.passport.files.length > 2 ?
							dr.passport.files[2] : '',
						passport_photo_0: (!owner || owner.name !== dr.passport.full_name) && dr.passport.files.length > 0 ?
							await getPhoto(dr.passport.files[0], 'driver-passport-front.jpg') : null,
						passport_photo_1: (!owner || owner.name !== dr.passport.full_name) && dr.passport.files.length > 1 ?
							await getPhoto(dr.passport.files[1], 'driver-passport-registration.jpg') : null,
						passport_photo_2: (!owner || owner.name !== dr.passport.full_name) && dr.passport.files.length > 2 ?
							await getPhoto(dr.passport.files[2], 'driver-selfie.jpg') : null,
						license_number: createNumber(dr.driver_certificate.serial, dr.driver_certificate.number),
						license_issue_date: dr.driver_certificate.issue_date ? new Date(
							dr.driver_certificate.issue_date.slice(0, 4),
							dr.driver_certificate.issue_date.slice(5, 7) - 1,
							dr.driver_certificate.issue_date.slice(8, 10)
						) : null,
						license_validity: dr.driver_certificate.validity ? new Date(
							dr.driver_certificate.validity.slice(0, 4),
							dr.driver_certificate.validity.slice(5, 7) - 1,
							dr.driver_certificate.validity.slice(8, 10)
						) : null,
						license_issued_by: dr.driver_certificate.issued_by ? dr.driver_certificate.issued_by : '',
						license_country: dr.driver_certificate.country ? dr.driver_certificate.country : '',
						license_categories: dr.driver_certificate.category ? dr.driver_certificate.category : [],
						license_photo_id_0: dr.driver_certificate.files.length > 0 ? dr.driver_certificate.files[0] : '',
						license_photo_id_1: dr.driver_certificate.files.length > 1 ? dr.driver_certificate.files[1] : '',
						license_photo_0: dr.driver_certificate.files.length > 0 ?
							await getPhoto(dr.driver_certificate.files[0], 'driver-license-front.jpg') : null,
						license_photo_1: dr.driver_certificate.files.length > 1 ?
							await getPhoto(dr.driver_certificate.files[1], 'driver-license-rear.jpg') : null
					}
					driver.complete = validateDriver(driver);
					if (owner && owner.name === driver.name) {
						owner.is_driver = true;
						owner.license_number = driver.license_number;
						owner.license_issue_date = driver.license_issue_date;
						owner.license_validity = driver.license_validity;
						owner.license_issued_by = driver.license_issued_by;
						owner.license_country = driver.license_country;
						owner.license_categories = driver.license_categories;
						owner.license_photo_0 = driver.license_photo_0;
						owner.license_photo_1 = driver.license_photo_1;
						if (i === 0) vehicle.driver = owner;
						dispatch(setVehicleData(vehicleIndex++, vehicle));
						owner.complete = validateOwner(owner);
						dispatch(setOwnerData(owner));
					} else {
						if (i === 0) vehicle.driver = driver;
						dispatch(setVehicleData(vehicleIndex++, vehicle));
					}
				});
				if (vehicle) {
					vehicle.complete = validateVehicle(vehicle);
					vehicles.push(vehicle);
					dispatch(setVehicleData(carIndex, vehicle));
				}
			};
		}
		if (owner) dispatch(setOwnerData(owner));
		vehicles.forEach((v, i) => {
			dispatch(setVehicleData(i, v));
		});
		if (data.recommendations) {
			const refs = data.recommendations;
			let ref0 = null;
			let ref1 = null;
			let ref2 = null;
			if (refs.length > 0) {
				ref0 = {name: refs[0].full_name, phone: refs[0].phone_number, job: refs[0].postion};
			}
			if (refs.length > 1) {
				ref1 = {name: refs[1].full_name, phone: refs[1].phone_number, job: refs[1].postion};
			}
			if (refs.length > 2) {
				ref2 = {name: refs[2].full_name, phone: refs[2].phone_number, job: refs[2].postion};
			}
			dispatch(setReferrerData(ref0, ref1, ref2));
		}
	}
	dispatch(setLoadingData(false));
	return true;
}

export default partnerReducer;
