import React, { useRef, useState, useEffect } from "react";
import InputMask from "react-input-mask";
import {
	validateName,
	validatePhone
} from "../validate";

const Referrer = ({
	state,
	setReferrer,
	removeReferrer,
	setReferrersComplete,
	showReferrerForm,
	storeReferrerData,
	setChanged,
	setNeedsSave
}) => {
	const [error, setError] = useState(false);
	const nameRef = useRef(null);
	const phoneRef = useRef(null);
	const jobRef = useRef(null);
	const name1Ref = useRef(null);
	const phone1Ref = useRef(null);
	const job1Ref = useRef(null);
	const name2Ref = useRef(null);
	const phone2Ref = useRef(null);
	const job2Ref = useRef(null);

	useEffect(() => {
		const onBackButtonEvent = (e) => {
			e.preventDefault();
			showReferrerForm(false);
			window.history.pushState(null, null, window.location.pathname);
		}
		window.history.pushState(null, null, window.location.pathname);
		window.addEventListener('popstate', onBackButtonEvent);
		return () => {
			window.removeEventListener('popstate', onBackButtonEvent);  
		};
	}, [showReferrerForm]);

	const validateReferrer = (index) => {
		switch (index) {
			case 0:
				return state.referrer0 &&
					validateName(state.referrer0.name) &&
					validatePhone(state.referrer0.phone) &&
					state.referrer0.job !== '';
			case 1:
				return state.referrer1 &&
					validateName(state.referrer1.name) &&
					validatePhone(state.referrer1.phone) &&
					state.referrer1.job !== '';
			case 2:
				return state.referrer2 &&
					validateName(state.referrer2.name) &&
					validatePhone(state.referrer2.phone) &&
					state.referrer2.job !== '';
			default:
				return false;
		}
	}

	return (
		<div className="active_tab">
			{/*<div className="active_tab_modal"></div>*/}
			<div className="active_tab_heading">
				<div className="active_tab_title desktop_only">Вас рекомендуют</div>
				<div className="active_tab_title mobile_only">Регистрация</div>
			</div>
			<div className="active_tab_body">
				<div className="referrer_container">
					<div className="referrer_title mobile_only">
						Контакты того, кто может Вас порекомендовать
					</div>
					<div className="referrer_subcontainer">
						<div className="referrer_container_item">
							<label htmlFor="referrer_0_name" className="referrer_input_label">ФИО</label>
							<input id="referrer_0_name" value={state.referrer0 ? state.referrer0.name : ''}
								className={'referrer_input' +
								(state.referrer0 && validateName(state.referrer0.name) ?
								' referrer_input-complete' : (error ? ' error' : ''))} ref={nameRef}
								onChange={(e) => {
									setReferrer(0,
										e.target.value,
										state.referrer0 ? state.referrer0.phone : '',
										state.referrer0 ? state.referrer0.job : ''
									);
									if (validateName(e.target.value)) {
										storeReferrerData();
										setChanged(true);
									}
									setReferrersComplete(false);
								}}
								placeholder="Иванов Иван Иванович" type="text"
							/>
						</div>
						<div className="referrer_container_item">
							<label htmlFor="referrer_0_phone" className="referrer_input_label">Телефон</label>
							<InputMask mask="+79999999999" value={state.referrer0 ? state.referrer0.phone : ''}
								onChange={(e) => {
									setReferrer(0,
										state.referrer0 ? state.referrer0.name : '',
										e.target.value,
										state.referrer0 ? state.referrer0.job : ''
									);
									if (validatePhone(e.target.value)) {
										storeReferrerData();
										setChanged(true);
									}
									setReferrersComplete(false);
								}}>
								{(inputProps) => <input {...inputProps} id="referrer_0_phone"
									className={'referrer_input' + (state.referrer0 &&
										validatePhone(state.referrer0.phone) ? ' referrer_input-complete' :
										(error ? ' error' : ''))} ref={phoneRef}
										placeholder="+79012345678" type="tel" />
								}
							</InputMask>
						</div>
						<div className="referrer_container_item">
							<label htmlFor="referrer_0_job" className="referrer_input_label">Должность</label>
							<input id="referrer_0_job" value={state.referrer0 ? state.referrer0.job : ''}
								className={'referrer_input' +
								(state.referrer0 && state.referrer0.job !== '' ?
								' referrer_input-complete' : (error ? ' error' : ''))} ref={jobRef} type="text"
								onChange={(e) => {
									setReferrer(0,
										state.referrer0 ? state.referrer0.name : '',
										state.referrer0 ? state.referrer0.phone : '',
										e.target.value
									);
									if (e.target.value !== '') {
										storeReferrerData();
										setChanged(true);
									}
									setReferrersComplete(false);
								}}
							/>
						</div>
						{state.referrer0 && validateReferrer(0) &&
							<div className="referrer_remove" onClick={() => {
								removeReferrer(0);
								setError(false);
								storeReferrerData();
								setChanged(true);
								setReferrersComplete(false);
							}}></div>
						}
					</div>
					{state.referrer1 &&
						<div className="referrer_subcontainer">
							<div className="referrer_container_item">
								<label htmlFor="referrer_1_name" className="referrer_input_label">ФИО</label>
								<input id="referrer_1_name" value={state.referrer1.name}
									className={'referrer_input' + (validateName(state.referrer1.name) ?
									' referrer_input-complete' : (error ? ' error' : ''))} ref={name1Ref}
									onChange={(e) => {
										setReferrer(1, e.target.value, state.referrer1.phone, state.referrer1.job);
										if (validateName(e.target.value)) {
											storeReferrerData();
											setChanged(true);
										}
										setReferrersComplete(false);
									}}
									placeholder="Иванов Иван Иванович" type="text"
							/>
							</div>
							<div className="referrer_container_item">
								<label htmlFor="referrer_1_phone" className="referrer_input_label">Телефон</label>
								<InputMask mask="+79999999999" value={state.referrer1.phone}
									onChange={(e) => {
										setReferrer(1, state.referrer1.name, e.target.value, state.referrer1.job);
										if (validatePhone(e.target.value)) {
											storeReferrerData();
											setChanged(true);
										}
										setReferrersComplete(false);
									}}>
									{(inputProps) => <input {...inputProps} id="referrer_1_phone"
										className={'referrer_input' + (validatePhone(state.referrer1.phone) ?
										' referrer_input-complete' : (error ? ' error' : ''))} ref={phone1Ref}
										placeholder="+79012345678" type="tel" />
									}
								</InputMask>
							</div>
							<div className="referrer_container_item">
								<label htmlFor="referrer_1_job" className="referrer_input_label">Должность</label>
								<input id="referrer_1_job" value={state.referrer1.job} ref={job1Ref}
									className={'referrer_input' + (state.referrer1.job !== '' ?
									' referrer_input-complete' : (error ? ' error' : ''))} type="text"
									onChange={(e) => {
										setReferrer(1, state.referrer1.name, state.referrer1.phone, e.target.value);
										if (e.target.value !== '') {
											storeReferrerData();
											setChanged(true);
										}
										setReferrersComplete(false);
									}}
								/>
							</div>
							<div className="referrer_remove" onClick={() => {
								removeReferrer(1);
								setError(false);
								storeReferrerData();
								setChanged(true);
								setReferrersComplete(false);
							}}></div>
						</div>
					}
					{state.referrer2 &&
						<div className="referrer_subcontainer">
							<div className="referrer_container_item">
								<label htmlFor="referrer_2_name" className="referrer_input_label">ФИО</label>
								<input id="referrer_2_name" value={state.referrer2.name} ref={name2Ref}
									className={'referrer_input' + (validateName(state.referrer2.name) ?
									' referrer_input-complete' : (error ? ' error' : ''))}
									onChange={(e) => {
										setReferrer(2, e.target.value, state.referrer2.phone, state.referrer2.job);
										if (validateName(e.target.value)) {
											storeReferrerData();
											setChanged(true);
										}
										setReferrersComplete(false);
									}}
									placeholder="Иванов Иван Иванович" type="text"
							/>
							</div>
							<div className="referrer_container_item">
								<label htmlFor="referrer_2_phone" className="referrer_input_label">Телефон</label>
								<InputMask mask="+79999999999" value={state.referrer2.phone}
									onChange={(e) => {
										setReferrer(2, state.referrer2.name, e.target.value, state.referrer2.job);
										if (validatePhone(e.target.value)) {
											storeReferrerData();
											setChanged(true);
										}
										setReferrersComplete(false);
									}}>
									{(inputProps) => <input {...inputProps} id="referrer_2_phone"
										className={'referrer_input' + (validatePhone(state.referrer2.phone) ?
										' referrer_input-complete' : (error ? ' error' : ''))} ref={phone2Ref}
										placeholder="+79012345678" type="tel" />
									}
								</InputMask>
							</div>
							<div className="referrer_container_item">
								<label htmlFor="referrer_2_job" className="referrer_input_label">Должность</label>
								<input id="referrer_2_job" value={state.referrer2.job} ref={job2Ref}
									className={'referrer_input' + (state.referrer2.job !== '' ?
									' referrer_input-complete' : (error ? ' error' : ''))} type="text"
									onChange={(e) => {
										setReferrer(2, state.referrer2.name, state.referrer2.phone, e.target.value);
										if (e.target.value !== '') {
											storeReferrerData();
											setChanged(true);
										}
										setReferrersComplete(false);
									}}
								/>
							</div>
							<div className="referrer_remove" onClick={() => {
								removeReferrer(2);
								setError(false);
								storeReferrerData();
								setChanged(true);
								setReferrersComplete(false);
							}}></div>
						</div>
					}
				</div>
				{((state.referrer0 && validateReferrer(0) && !state.referrer1) ||
					(state.referrer1 && validateReferrer(0) && validateReferrer(1) && !state.referrer2)) &&
					<div className="referrer_add" onClick={() => {
						setReferrer(state.referrer1 ? 2 : 1, '', '', '');
						setError(false);
					}}>Добавить еще одну рекомендацию</div>
				}
				<div className={'active_tab_button' + (!state.referrer0 || !validateReferrer(0) ||
					(state.referrer1 && !validateReferrer(1)) ||
					(state.referrer2 && !validateReferrer(2)) ? ' active_tab_button-inactive' : '')}
					onClick={() => {
						setChanged(true);
						setNeedsSave(true);
						if (!state.referrer0 || !validateName(state.referrer0.name)) {
							setError(true);
							nameRef.current.focus();
							return;
						}
						if (!validatePhone(state.referrer0.phone)) {
							setError(true);
							phoneRef.current.focus();
							return;
						}
						if (state.referrer0.job === '') {
							setError(true);
							jobRef.current.focus();
							return;
						}
						if (state.referrer1 && !validateName(state.referrer1.name)) {
							setError(true);
							name1Ref.current.focus();
							return;
						}
						if (state.referrer1 && !validatePhone(state.referrer1.phone)) {
							setError(true);
							phone1Ref.current.focus();
							return;
						}
						if (state.referrer1 && state.referrer1.job === '') {
							setError(true);
							job1Ref.current.focus();
							return;
						}
						if (state.referrer2 && !validateName(state.referrer2.name)) {
							setError(true);
							name2Ref.current.focus();
							return;
						}
						if (state.referrer2 && !validatePhone(state.referrer2.phone)) {
							setError(true);
							phone2Ref.current.focus();
							return;
						}
						if (state.referrer2 && state.referrer2.job === '') {
							setError(true);
							job2Ref.current.focus();
							return;
						}
						setReferrersComplete(true);
						storeReferrerData();
						showReferrerForm(false);
						setChanged(true);
					}}>
					<div className="active_tab_button_text">Сохранить</div>
				</div>
				<div className="active_tab_back" onClick={() => showReferrerForm(false)}>Назад</div>
			</div>
		</div>
	);
}

export default Referrer;
