import React, { useRef, useState, useEffect } from "react";
import InputMask from "react-input-mask";
import Webcam from "webcam-easy";
import DatePicker from "react-date-picker";
import { Checkbox, DaData } from "../Elements";
import { SINGLE_CAR_OWNER } from "../../redux/reducers/partnerReducer";
import {
	validateName,
	validatePhone,
	validateEmail,
	validateDate,
	validateFutureDate,
	validateOrg
} from "../validate";
import { addressAPI } from "../../api/dadata-api";

const categories = [
	'A',
	'B',
	'C',
	'B1',
	'C1',
	'C1E',
	'D1',
	'D1E',
	'BE',
	'CE',
	'DE'
];

const Driver = ({
	driverIndex,
	state,
	partner,
	isOwner,
	setOwnerName,
	addOwnerPhone,
	removeOwnerPhone,
	setOwnerEmail,
	setOwnerBirthdate,
	setOwnerPassportNumber,
	setOwnerPassportIssuedBy,
	setOwnerPassportIssueDate,
	setOwnerPassportDepartment,
	setOwnerPassportRegistration,
	setOwnerAddress,
	setOwnerPassportPhoto,
	setIsDriver,
	setOwnerLicenseNumber,
	setOwnerLicenseIssueDate,
	setOwnerLicenseValidity,
	setOwnerLicenseIssuedBy,
	setOwnerLicenseCountry,
	setOwnerLicenseCategories,
	setOwnerLicensePhoto,
	setOwnerComplete,
	setDriverName,
	addDriverPhone,
	removeDriverPhone,
	setDriverEmail,
	setDriverBirthdate,
	setDriverPassportNumber,
	setDriverPassportIssuedBy,
	setDriverPassportIssueDate,
	setDriverPassportDepartment,
	setDriverPassportRegistration,
	setDriverAddress,
	setDriverPassportPhoto,
	setDriverLicenseNumber,
	setDriverLicenseIssueDate,
	setDriverLicenseValidity,
	setDriverLicenseIssuedBy,
	setDriverLicenseCountry,
	setDriverLicenseCategories,
	setDriverLicensePhoto,
	clearDriverData,
	setDriverComplete,
	setDriver,
	showDriverForm,
	storeOwnerData,
	storeDriverData,
	setVehicleData,
	setChanged,
	setNeedsSave
}) => {
	const nameRef = useRef(null);
	const phoneRef = useRef(null);
	const phone1Ref = useRef(null);
	const phone2Ref = useRef(null);
	const passportNumber = useRef(null);
	const birthdate = useRef(null);
	const passportIssuedBy = useRef(null);
	const passportIssueDate = useRef(null);
	//const passportDepartment = useRef(null);
	//const registration = useRef(null);
	//const registrationHouse = useRef(null);
	const address = useRef(null);
	const addressHouse = useRef(null);
	const licenseNumber = useRef(null);
	const licenseIssueDate = useRef(null);
	const licenseValidity = useRef(null);
	const licenseIssuedBy = useRef(null);
	const licenseCountry = useRef(null);
	const passportPhoto = useRef(null);
	const registrationPhoto = useRef(null);
	const selfie = useRef(null);
	const licenseFront = useRef(null);
	const licenseRear = useRef(null);
	const videoRef = useRef(null);
	const pictureRef = useRef(null);
	const webcam = useRef(null);
	const [webcamActive, setWebcamActive] = useState(false);
	const [error, setError] = useState(false);
	const [webcamReady, setWebcamReady] = useState(false);
	const [photoSuccessful, setPhotoSuccessful] = useState(false);

	useEffect(() => {
		const onBackButtonEvent = (e) => {
			e.preventDefault();
			showDriverForm(false, false);
			window.history.pushState(null, null, window.location.pathname);
		}
		window.history.pushState(null, null, window.location.pathname);
		window.addEventListener('popstate', onBackButtonEvent);
		return () => {
			window.removeEventListener('popstate', onBackButtonEvent);  
		};
	}, [showDriverForm]);

	const validatePhones = () => {
		if (state.phones.length === 0) return false;
		if (!validatePhone(state.phones[0])) return false;
		if (state.phones.length === 1) return true;
		if (!validatePhone(state.phones[1])) return false;
		if (state.phones.length === 2) return true;
		if (!validatePhone(state.phones[2])) return false;
		return true;
	}

	const setBirthdate = (date) => {
		if (isOwner) {
			setOwnerBirthdate(date);
			setOwnerComplete(false);
			storeOwnerData();
		} else {
			setDriverBirthdate(date);
			setDriverComplete(false);
			storeDriverData(driverIndex);
		}
		setChanged(true);
	}

	const setPassportIssueDate = (date) => {
		if (isOwner) {
			setOwnerPassportIssueDate(date);
			setOwnerComplete(false);
			storeOwnerData();
		} else {
			setDriverPassportIssueDate(date);
			setDriverComplete(false);
			storeDriverData(driverIndex);
		}
		setChanged(true);
	}

	const setLicenseIssueDate = (date) => {
		if (isOwner) {
			setOwnerLicenseIssueDate(date);
			setOwnerComplete(false);
			storeOwnerData();
		} else {
			setDriverLicenseIssueDate(date);
			setDriverComplete(false);
			storeDriverData(driverIndex);
		}
		setChanged(true);
	}

	const setLicenseValidity = (date) => {
		if (isOwner) {
			setOwnerLicenseValidity(date);
			setOwnerComplete(false);
			storeOwnerData();
		} else {
			setDriverLicenseValidity(date);
			setDriverComplete(false);
			storeDriverData(driverIndex);
		}
		setChanged(true);
	}

	return (
		<div className="active_tab">
			{/*<div className="active_tab_modal"></div>*/}
			<div className="active_tab_heading">
				<div className="active_tab_title">
					{isOwner ? 'Данные владельца' : 'Данные водителя'}
				</div>
			</div>
			<div className="active_tab_body">
				<div className="driver_container">
					<div className="driver_container_item">
						<label htmlFor="owner_name" className="driver_input_label">ФИО</label>
						<input id="owner_name" ref={nameRef} value={state.name}
							className={'driver_input' + (validateName(state.name) ?
							' driver_input-complete' : (error ? ' error' : ''))}
							onChange={(e) => {
								if (isOwner) {
									setOwnerName(e.target.value);
									setOwnerComplete(false);
								} else {
									setDriverName(e.target.value);
									setDriverComplete(false);
								}
								if (validateName(e.target.value)) {
									if (isOwner) storeOwnerData()
										else storeDriverData(driverIndex);
									setChanged(true);
								}
							}}
							placeholder="Иванов Иван Иванович" type="text"
						/>
					</div>
					<div className="driver_container_item">
						<label htmlFor="owner_phone" className="driver_input_label">Телефон</label>
						<div className="driver_subcontainer">
							<div className="driver_phone">
								<InputMask mask="+79999999999" value={state.phones.length > 0 ?
									state.phones[0] : ''}
									onChange={(e) => {
										if (isOwner) {
											addOwnerPhone(0, e.target.value);
											setOwnerComplete(false);
										} else {
											addDriverPhone(0, e.target.value);
											setDriverComplete(false);
										}
										if (validatePhone(e.target.value)) {
											if (isOwner) storeOwnerData()
												else storeDriverData(driverIndex);
											setChanged(true);
										}
									}}
									onBlur={() => {
										if (state.phones.length > 1 && !validatePhone(state.phones[0])) {
											if (isOwner) removeOwnerPhone(0)
												else removeDriverPhone(0);
											if (isOwner) storeOwnerData()
												else storeDriverData(driverIndex);
											setChanged(true);
										}
									}}>
									{(inputProps) => <input {...inputProps} id="owner_phone"
										ref={phoneRef} className={'driver_input' + (state.phones.length > 0 &&
										validatePhone(state.phones[0]) ? ' driver_input-complete' :
										(error ? ' error' : ''))}
										placeholder="+79012345678" type="tel" />
									}
								</InputMask>
								<div className="driver_phone_controls">
									{state.phones.length === 1 && validatePhone(state.phones[0]) &&
										<div className="driver_phone_add_mobile mobile_only"
											onClick={() => {
												if (isOwner) addOwnerPhone(1, '')
													else addDriverPhone(1, '');
											}}>
										</div>
									}
								</div>
								{state.phones.length === 1 && validatePhone(state.phones[0]) &&
									<div className="driver_phone_add desktop_only"
										onClick={() => {
											if (isOwner) addOwnerPhone(1, '')
												else addDriverPhone(1, '');
										}}>
										Добавить номер телефона
									</div>
								}
							</div>
							{state.phones.length > 1 &&
								<div className="driver_phone">
									<InputMask mask="+79999999999" value={state.phones.length > 1 ?
										state.phones[1] : ''}
										onChange={(e) => {
											if (isOwner) addOwnerPhone(1, e.target.value)
												else addDriverPhone(1, e.target.value);
											if (validatePhone(e.target.value)) {
												if (isOwner) storeOwnerData()
													else storeDriverData(driverIndex);
												setChanged(true);
											}
										}}
										onBlur={() => {
											if (state.phones.length > 2 && !validatePhone(state.phones[1])) {
												if (isOwner) removeOwnerPhone(1)
													else removeDriverPhone(1);
												if (isOwner) storeOwnerData()
													else storeDriverData(driverIndex);
												setChanged(true);
											}
										}}>
										{(inputProps) => <input {...inputProps}
											ref={phone1Ref} className={'driver_input' + (state.phones.length > 1 &&
											validatePhone(state.phones[1]) ? ' driver_input-complete' :
											(error ? ' error' : ''))}
											placeholder="+79012345678" type="tel" />
										}
									</InputMask>
									<div className="driver_phone_controls">
										{state.phones.length === 2 && validatePhone(state.phones[1]) &&
											<div className="driver_phone_add_mobile mobile_only"
												onClick={() => {
													if (isOwner) addOwnerPhone(2, '')
														else addDriverPhone(2, '');
												}}>
											</div>
										}
										<div className="driver_phone_remove" onClick={() => {
											if (isOwner) removeOwnerPhone(1)
												else removeDriverPhone(1);
											if (isOwner) storeOwnerData()
												else storeDriverData(driverIndex);
											setChanged(true);
										}}></div>
									</div>
									{state.phones.length === 2 && validatePhone(state.phones[1]) &&
										<div className="driver_phone_add desktop_only"
											onClick={() => {
												if (isOwner) addOwnerPhone(2, '')
													else addDriverPhone(2, '');
											}}>
											Добавить номер телефона
										</div>
									}
								</div>
							}
							{state.phones.length > 2 &&
								<div className="driver_phone">
									<InputMask mask="+79999999999" value={state.phones.length > 2 ?
										state.phones[2] : ''}
										onChange={(e) => {
											if (isOwner) addOwnerPhone(2, e.target.value)
												else addDriverPhone(2, e.target.value);
											if (validatePhone(e.target.value)) {
												if (isOwner) storeOwnerData()
													else storeDriverData(driverIndex);
												setChanged(true);
											}
										}}>
										{(inputProps) => <input {...inputProps}
											ref={phone2Ref} className={'driver_input' + (state.phones.length > 2 &&
											validatePhone(state.phones[2]) ? ' driver_input-complete' :
											(error ? ' error' : ''))}
											placeholder="+79012345678" type="tel" />
										}
									</InputMask>
								<div className="driver_phone_controls">
									<div className="driver_phone_remove" onClick={() => {
										if (isOwner) removeOwnerPhone(2)
											else removeDriverPhone(2);
										if (isOwner) storeOwnerData()
											else storeDriverData(driverIndex);
										setChanged(true);
									}}></div>
								</div>
							</div>
							}
						</div>
					</div>
					<div className="driver_container_item">
						<label htmlFor="owner_email" className="driver_input_label">E-mail</label>
						<input id="owner_email" value={state.email}
							className={'driver_input' + (state.email !== '' && validateEmail(state.email) ?
							' driver_input-complete' : '')}
							onChange={(e) => {
								if (isOwner) setOwnerEmail(e.target.value)
									else setDriverEmail(e.target.value);
								if (validateEmail(e.target.value)) {
									if (isOwner) storeOwnerData()
										else storeDriverData(driverIndex);
									setChanged(true);
								}
							}}
							placeholder="user@email.ru" type="email"
						/>
					</div>
				</div>
				<div className="driver_container">
					<div className="driver_subtitle desktop_only">Паспортные данные</div>
					<div className="driver_container_item">
						<label htmlFor="owner_passport_name" className="driver_input_label">ФИО</label>
						<input id="owner_passport_name" value={state.name}
							className={'driver_input' + (validateName(state.name) ?
							' driver_input-complete' : (error ? ' error' : ''))}
							onChange={(e) => {
								if (isOwner) {
									setOwnerName(e.target.value);
									setOwnerComplete(false);
								} else {
									setDriverName(e.target.value);
									setDriverComplete(false);
								}
								if (validateName(e.target.value)) {
									if (isOwner) storeOwnerData()
										else storeDriverData(driverIndex);
									setChanged(true);
								}
							}}
							placeholder="Иванов Иван Иванович" type="text"
						/>
					</div>
					<div className="driver_container_item driver_container_item-short">
						<label htmlFor="owner_birthdate" className="driver_input_label">Дата рождения</label>
						<DatePicker
							id="owner_birthdate"
							ref={birthdate}
							className={validateDate(state.birthdate) ? ' driver_input-complete' :
								(error ? ' error' : '')}
							value={state.birthdate}
							clearIcon={null}
							showLeadingZeros={true}
							closeCalendar={true}
							dayPlaceholder="ДД"
							monthPlaceholder="ММ"
							yearPlaceholder="ГГГГ"
							format="dd.MM.y"
							locale="ru-RU"
							maxDate={new Date()}
							onChange={setBirthdate}
						/>
					</div>
					<div className="driver_container_item driver_container_item-short">
						<label htmlFor="owner_passport_number" className="driver_input_label">
							Серия и № паспорта
						</label>
						<InputMask mask="99 99 999999" value={state.passport_number} onChange={(e) => {
							if (isOwner) {
								setOwnerPassportNumber(e.target.value);
								setOwnerComplete(false);
							} else {
								setDriverPassportNumber(e.target.value);
								setDriverComplete(false);
							}
							if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
								if (isOwner) storeOwnerData()
									else storeDriverData(driverIndex);
								setChanged(true);
							}
						}}>
							{(inputProps) => <input {...inputProps} id="owner_passport_number"
								ref={passportNumber} className={'driver_input' +
								(state.passport_number !== '' && state.passport_number.indexOf('_') === -1 ?
								' driver_input-complete' : (error ? ' error' : ''))}
								placeholder="введите серию и номер паспорта" type="tel" />
							}
						</InputMask>
					</div>
				</div>
				<div className="driver_container">
					<div className="driver_container_item" style={{ flexGrow: '1' }}>
						<label htmlFor="owner_passport_issued_by" className="driver_input_label">
							Кем выдан (как в паспорте)
						</label>
						<input id="owner_passport_issued_by"
							ref={passportIssuedBy} value={state.passport_issued_by}
							className={'driver_input driver_input-long' + (validateOrg(state.passport_issued_by) ?
							' driver_input-complete' : (error ? ' error' : ''))}
							placeholder="наименование подразделения" type="text"
							onChange={(e) => {
								if (isOwner) {
									setOwnerPassportIssuedBy(e.target.value);
									setOwnerComplete(false);
								} else {
									setDriverPassportIssuedBy(e.target.value);
									setDriverComplete(false);
								}
								if (validateOrg(e.target.value)) {
									if (isOwner) storeOwnerData()
										else storeDriverData(driverIndex);
									setChanged(true);
								}
							}}
						/>
					</div>
					<div className="driver_container_item driver_container_item-short">
						<label htmlFor="owner_passport_issue_date" className="driver_input_label">
							Дата выдачи
						</label>
						<DatePicker
							id="owner_passport_issue_date"
							ref={passportIssueDate}
							className={validateDate(state.passport_issue_date) ? ' driver_input-complete' :
								(error ? ' error' : '')}
							value={state.passport_issue_date}
							clearIcon={null}
							showLeadingZeros={true}
							closeCalendar={true}
							dayPlaceholder="ДД"
							monthPlaceholder="ММ"
							yearPlaceholder="ГГГГ"
							format="dd.MM.y"
							locale="ru-RU"
							maxDate={new Date()}
							onChange={setPassportIssueDate}
						/>
					</div>
					{/*<div className="driver_container_item driver_container_item-short">
						<label htmlFor="owner_passport_department" className="driver_input_label">
							Код подразделения
						</label>
						<InputMask mask="999-999" value={state.passport_department} onChange={(e) => {
							if (isOwner) setOwnerPassportDepartment(e.target.value)
								else setDriverPassportDepartment(e.target.value);
						}}>
							{(inputProps) => <input {...inputProps} id="owner_passport_department"
								ref={passportDepartment} key="owner_passport_department"
								className={'driver_input' + (state.passport_department !== '' &&
								state.passport_department.indexOf('_') === -1 ? ' driver_input-complete' :
								(error ? ' error' : ''))} placeholder="введите код подразделения" type="tel" />
							}
						</InputMask>
					</div>*/}
				</div>
				{/*<div className="driver_container">
					<div className="driver_container_item">
						<label htmlFor="owner_registration" className="driver_input_label">
							Адрес регистрации
						</label>
						<div className="driver_subcontainer">
							<DaData id="owner_registration" keyId="owner_registration"
								value={state.passport_registration && state.passport_registration.data ?
									state.passport_registration.data.country + (state.passport_registration.data.city_fias_id ?
									', ' + state.passport_registration.data.city_with_type :
									state.passport_registration.data.settlement_fias_id ?
									', ' + state.passport_registration.data.settlement_with_type : '') +
									(state.passport_registration.data.street_fias_id ? ', ' +
									state.passport_registration.data.street_with_type : '') : ''}
								className="driver_dadata driver_dadata_nomargin" inputClassName={'driver_dadata_input' +
									(state.passport_registration ? ' driver_input-complete' : (error ? ' error' : ''))}
								ref={registration}
								onBlur={async () => {
									if (registration.current && registration.current.value !== '') {
										let data = await addressAPI.getSuggestions(registration.current.value);
										if (data && data.data && data.data.suggestions) {
											let address = data.data.suggestions[0];
											if (isOwner) setOwnerPassportRegistration(address, state.same_address)
												else setDriverPassportRegistration(address, state.same_address);
										}
									}
								}}
								onChange={async (value) => {
									if (isOwner) setOwnerPassportRegistration(null, state.same_address)
										else setDriverPassportRegistration(null, state.same_address);
									let data = await addressAPI.getSuggestions(value);
									if (data && data.data && data.data.suggestions) return data.data.suggestions;
									return [];
								}}
								onSelect={(suggestion) => {
									if (isOwner) setOwnerPassportRegistration(suggestion, state.same_address)
										else setDriverPassportRegistration(suggestion, state.same_address);
								}} placeholder="Страна, город, улица" type="text"
							/>
							<div className="driver_subcontainer">
								<input id="owner_registration_house" key="owner_registration_house" ref={registrationHouse}
									value={state.passport_registration && state.passport_registration.data.house ?
										state.passport_registration.data.house : ''}
									className={'driver_input driver_input-short' +
										(state.passport_registration && state.passport_registration.data.house ?
										' driver_input-complete' : (error ? ' error' : ''))}
									onChange={(e) => {
										let address = state.passport_registration;
										if (!address || (!address.data.city && !address.data.settlement) ||
											(address.data.city && !address.data.street)) return;
										if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
										address.data.house = e.target.value;
										address.value = address.data.country + (address.data.city ?
											', ' + address.data.city_with_type : address.data.settlement ?
											', ' + address.data.settlement : '') + (address.data.street ?
											', ' + address.data.street_with_type : '') + ', д ' + e.target.value +
											(address.data.block ? ', к ' + address.data.block : '') +
											(address.data.flat ? ', кв ' + address.data.flat : '');
										if (isOwner) setOwnerPassportRegistration(address, state.same_address)
											else setDriverPassportRegistration(address, state.same_address);
									}} placeholder="Дом" type="text"
								/>
								<input id="owner_registration_block" key="owner_registration_block"
									value={state.passport_registration && state.passport_registration.data.block ?
										state.passport_registration.data.block : ''}
									className={'driver_input driver_input-short' +
										(state.passport_registration && state.passport_registration.data.block ?
										' driver_input-complete' : '')}
									onChange={(e) => {
										let address = state.passport_registration;
										if (!address || (!address.data.city && !address.data.settlement) ||
											(address.data.city && !address.data.street)) return;
										if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
										address.data.block = e.target.value;
										address.value = address.data.country + (address.data.city ?
											', ' + address.data.city_with_type : address.data.settlement ?
											', ' + address.data.settlement : '') + (address.data.street ?
											', ' + address.data.street_with_type : '') + (address.data.house ?
											', д ' + address.data.house : '') + ', к ' + e.target.value +
											(address.data.flat ? ', кв ' + address.data.flat : '');
										if (isOwner) setOwnerPassportRegistration(address, state.same_address)
											else setDriverPassportRegistration(address, state.same_address);
									}} placeholder="Корп." type="text"
								/>
								<input id="owner_registration_flat" key="owner_registration_flat"
									value={state.passport_registration && state.passport_registration.data.flat ?
										state.passport_registration.data.flat : ''}
									className={'driver_input driver_input-short' +
										(state.passport_registration && state.passport_registration.data.flat ?
										' driver_input-complete' : '')}
									onChange={(e) => {
										let address = state.passport_registration;
										if (!address || (!address.data.city && !address.data.settlement) ||
											(address.data.city && !address.data.street)) return;
										if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
										address.data.flat = e.target.value;
										address.value = address.data.country + (address.data.city ?
											', ' + address.data.city_with_type : address.data.settlement ?
											', ' + address.data.settlement : '') + (address.data.street ?
											', ' + address.data.street_with_type : '') + (address.data.house ?
											', д ' + address.data.house : '') + (address.data.block ?
											', к ' + address.data.block : '') + ', кв ' + e.target.value;
										if (isOwner) setOwnerPassportRegistration(address, state.same_address)
											else setDriverPassportRegistration(address, state.same_address);
									}} placeholder="Кв." type="text"
								/>
							</div>
						</div>
					</div>
				</div>*/}
				{/*<div className="driver_container">
					<div className="driver_container_item">
						<table className="driver_checkbox_container" onClick={() => {
							if (isOwner) setOwnerPassportRegistration(state.passport_registration, !state.same_address)
								else setDriverPassportRegistration(state.passport_registration, !state.same_address);
						}}>
							<tbody>
								<tr>
									<td className="driver_checkbox">
										<Checkbox checked={state.same_address} />
									</td>
									<td className="driver_checkbox_label">Совпадает с адресом проживания</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>*/}
				<div className="driver_container">
					<div className="driver_container_item">
						<label htmlFor="owner_address" className="driver_input_label">
							Место гаража (стоянки) ТС или адрес проживания
						</label>
						<div className="driver_subcontainer">
							<DaData id="owner_address"
								value={state.address && state.address.data ? state.address.data.country +
									(state.address.data.city_fias_id ? ', ' + state.address.data.city_with_type :
									state.address.data.settlement_fias_id ? ', ' +
									state.address.data.settlement_with_type : '') +
									(state.address.data.street_fias_id ? ', ' +
									state.address.data.street_with_type : '') : ''}
								className="driver_dadata driver_dadata_nomargin" inputClassName={'driver_dadata_input' +
									(state.address ? ' driver_input-complete' : (error ? ' error' : ''))}
								ref={address}
								onBlur={async () => {
									if (address.current && address.current.value !== '') {
										let data = await addressAPI.getSuggestions(address.current.value);
										if (data && data.data && data.data.suggestions) {
											let address = data.data.suggestions[0];
											if (isOwner) setOwnerAddress(address)
												else setDriverAddress(address);
											if (isOwner) storeOwnerData()
												else storeDriverData(driverIndex);
											setChanged(true);
										}
									}
								}}
								onChange={async (value) => {
									if (isOwner) {
										setOwnerAddress(null);
										setOwnerComplete(false);
									} else {
										setDriverAddress(null);
										setDriverComplete(false);
									}
									setChanged(true);
									let data = await addressAPI.getSuggestions(value);
									if (data && data.data && data.data.suggestions) return data.data.suggestions;
									return [];
								}}
								onSelect={(suggestion) => {
									if (isOwner) setOwnerAddress(suggestion)
										else setDriverAddress(suggestion);
									if (isOwner) storeOwnerData()
										else storeDriverData(driverIndex);
									setChanged(true);
								}} placeholder="Страна, город, улица" type="text"
							/>
							<div className="driver_subcontainer">
								<input id="owner_address_house" ref={addressHouse}
									value={state.address && state.address.data.house ? state.address.data.house : ''}
									className={'driver_input driver_input-short' +
										(state.address && state.address.data.house ?
										' driver_input-complete' : (error ? ' error' : ''))}
									onChange={(e) => {
										let address = state.address;
										if (!address || (!address.data.city && !address.data.settlement) ||
											(address.data.city && !address.data.street)) return;
										if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
										address.data.house = e.target.value;
										address.value = address.data.country + (address.data.city ?
											', ' + address.data.city_with_type : address.data.settlement ?
											', ' + address.data.settlement : '') + (address.data.street ?
											', ' + address.data.street_with_type : '') + ', д ' + e.target.value +
											(address.data.block ? ', к ' + address.data.block : '') +
											(address.data.flat ? ', кв ' + address.data.flat : '');
										if (isOwner) {
											setOwnerAddress(address);
											setOwnerComplete(false);
										} else {
											setDriverAddress(address);
											setDriverComplete(false);
										}
										if (isOwner) storeOwnerData()
											else storeDriverData(driverIndex);
										setChanged(true);
									}} placeholder="Дом" type="text"
								/>
								<input id="owner_address_block"
									value={state.address && state.address.data.block ? state.address.data.block : ''}
									className={'driver_input driver_input-short' +
										(state.address && state.address.data.block ? ' driver_input-complete' : '')}
									onChange={(e) => {
										let address = state.address;
										if (!address || (!address.data.city && !address.data.settlement) ||
											(address.data.city && !address.data.street)) return;
										if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
										address.data.block = e.target.value;
										address.value = address.data.country + (address.data.city ?
											', ' + address.data.city_with_type : address.data.settlement ?
											', ' + address.data.settlement : '') + (address.data.street ?
											', ' + address.data.street_with_type : '') + (address.data.house ?
											', д ' + address.data.house : '') + ', к ' + e.target.value +
											(address.data.flat ? ', кв ' + address.data.flat : '');
										if (isOwner) {
											setOwnerAddress(address);
											setOwnerComplete(false);
										} else {
											setDriverAddress(address);
											setDriverComplete(false);
										}
										if (isOwner) storeOwnerData()
											else storeDriverData(driverIndex);
										setChanged(true);
									}} placeholder="Корп." type="text"
								/>
								<input id="owner_address_flat"
									value={state.address && state.address.data.flat ? state.address.data.flat : ''}
									className={'driver_input driver_input-short' +
										(state.address && state.address.data.flat ? ' driver_input-complete' : '')}
									onChange={(e) => {
										let address = state.address;
										if (!address || (!address.data.city && !address.data.settlement) ||
											(address.data.city && !address.data.street)) return;
										if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return;
										address.data.flat = e.target.value;
										address.value = address.data.country + (address.data.city ?
											', ' + address.data.city_with_type : address.data.settlement ?
											', ' + address.data.settlement : '') + (address.data.street ?
											', ' + address.data.street_with_type : '') + (address.data.house ?
											', д ' + address.data.house : '') + (address.data.block ?
											', к ' + address.data.block : '') + ', кв ' + e.target.value;
										if (isOwner) {
											setOwnerAddress(address);
											setOwnerComplete(false);
										} else {
											setDriverAddress(address);
											setDriverComplete(false);
										}
										if (isOwner) storeOwnerData()
											else storeDriverData(driverIndex);
										setChanged(true);
									}} placeholder="Кв." type="text"
								/>
							</div>
						</div>
						{navigator.geolocation &&
							<div className="driver_geo" onClick={() => {
								navigator.geolocation.getCurrentPosition(async (pos) => {
									let response = await addressAPI.getAddressByCoords(pos.coords.latitude,
										pos.coords.longitude);
									if (response && response.data && response.data.suggestions.length > 0) {
										if (isOwner) setOwnerAddress(response.data.suggestions[0])
											else setDriverAddress(response.data.suggestions[0]);
										if (isOwner) storeOwnerData()
											else storeDriverData(driverIndex);
										setChanged(true);
									}
								});
							}}>
								Моё местоположение
							</div>
						}
					</div>
				</div>
				<div className="driver_container" style={{flexDirection: 'column'}}>
					<div className="driver_subtitle">Фото паспорта</div>
					<div className="driver_photo_container" style={{display: 'flex', flexWrap: 'wrap'}}>
						<div className="driver_photo">
							<label className="driver_photo_label">Страницы 2-3</label>
							<label htmlFor="driver_passport_photo_0" ref={passportPhoto} tabIndex="1"
								className={'driver_photo_button' + (state.passport_photo_0 ?
								' driver_photo_button-complete' : (error ? ' error' : ''))}>
								<div className="driver_photo_button_text">
									{state.passport_photo_0 ? state.passport_photo_0.name : 'Фото 1'}
								</div>
							</label>
							<input type="file" id="driver_passport_photo_0"
								accept="image/*"
								onChange={async (e) => {
									if (e.target.files.length !== 0) {
										if (isOwner) await setOwnerPassportPhoto(0, e.target.files[0])
											else await setDriverPassportPhoto(0, e.target.files[0]);
										if (isOwner) storeOwnerData()
											else storeDriverData(driverIndex);
										setChanged(true);
									}
								}}
							/>
						</div>
						<div className="driver_photo">
							<label className="driver_photo_label">Страницы 4-5</label>
							<label htmlFor="driver_passport_photo_1" ref={registrationPhoto} tabIndex="1"
								className={'driver_photo_button' + (state.passport_photo_1 ?
								' driver_photo_button-complete' : (error ? ' error' : ''))}>
								<div className="driver_photo_button_text">
									{state.passport_photo_1 ? state.passport_photo_1.name : 'Фото 2'}
								</div>
							</label>
							<input type="file" id="driver_passport_photo_1"
								accept="image/*"
								onChange={async (e) => {
									if (e.target.files.length !== 0) {
										if (isOwner) await setOwnerPassportPhoto(1, e.target.files[0])
											else await setDriverPassportPhoto(1, e.target.files[0]);
										if (isOwner) storeOwnerData()
											else storeDriverData(driverIndex);
										setChanged(true);
									}
								}}
							/>
						</div>
						<div className="driver_photo">
							<label className="driver_photo_label">Вы с паспортом</label>
							<div className="driver_photo_subcontainer">
								<label htmlFor="driver_passport_photo_2" ref={selfie} tabIndex="1"
									className={'driver_photo_button' + (state.passport_photo_2 ?
									' driver_photo_button-complete' : (error ? ' error' : ''))}>
									<div className="driver_photo_button_text">
										{state.passport_photo_2 ? state.passport_photo_2.name : 'Прикрепить'}
									</div>
								</label>
								<input type="file" id="driver_passport_photo_2"
									accept="image/*"
									onClick={() => {
										setWebcamActive(false);
										setWebcamReady(false);
										if (webcam.current) webcam.current.stop();
									}}
									onChange={async (e) => {
										if (e.target.files.length !== 0) {
											if (isOwner) await setOwnerPassportPhoto(2, e.target.files[0])
												else await setDriverPassportPhoto(2, e.target.files[0]);
											if (isOwner) storeOwnerData()
												else storeDriverData(driverIndex);
											setChanged(true);
											setWebcamActive(false);
											setWebcamReady(false);
											if (webcam.current) webcam.current.stop();
										}
									}}
								/>
								<label className="driver_photo_button"
									onClick={() => {
									if (!webcamActive) {
										webcam.current = new Webcam(videoRef.current, 'user', pictureRef.current);
										webcam.current.start();
										setWebcamActive(true);
									}
								}}>
									<div className="driver_photo_button_text">Сделать</div>
								</label>
							</div>
						</div>
					</div>
					<table className="driver_checkbox_container" onClick={() => {}}>
						<tbody>
							<tr>
								<td className="driver_checkbox-info">
									<div className="driver_checkbox-info_sign">i</div>
								</td>
								<td className="driver_checkbox_label-info">Как правильно сделать фото</td>
							</tr>
						</tbody>
					</table>
					<div className="driver_video_container" style={{zIndex: (webcamActive ? '10' : '-1')}}>
						<video ref={videoRef} className="driver_video" autoPlay playsInline
							style={{display: (webcamActive && webcamReady ? 'unset' : 'none')}}
							onCanPlay={() => setWebcamReady(true)}>
						</video>
						<canvas ref={pictureRef} className="hidden"></canvas>
						{webcamActive ?
							webcamReady ?
								<label className="driver_photo_button driver_photo_button_snap" onClick={async () => {
									const picture = webcam.current.snap();
									const blobBin = atob(picture.split(',')[1]);
									const array = [];
									for (let i = 0; i < blobBin.length; i++) {
										array.push(blobBin.charCodeAt(i));
									}
									const blob = new Blob([new Uint8Array(array)], {type: 'image/jpg'});
									const file = new File([blob], 'selfie.jpg');
									if (isOwner) await setOwnerPassportPhoto(2, file)
										else await setDriverPassportPhoto(2, file);
									if (isOwner) storeOwnerData()
										else storeDriverData(driverIndex);
									setChanged(true);
									setWebcamActive(false);
									setWebcamReady(false);
									webcam.current.stop();
									setPhotoSuccessful(true);
									setTimeout(setPhotoSuccessful, 5000, false);
								}}>Сделать фото</label>
							:
								<div className="camera_wait">
									Пожалуйста, подождите.<br />
									Идет инициализация камеры.
								</div>
							: null
						}
					</div>
					{photoSuccessful &&
						<div className="driver_photo_label">Фото успешно загружено</div>
					}
				</div>
				{isOwner &&
					<div className="driver_container">
						<div className="driver_container_item">
							<table className="driver_checkbox_container" onClick={() => {
								setIsDriver(!state.is_driver);
							}}>
								<tbody>
									<tr>
										<td className="driver_checkbox">
											<Checkbox checked={state.is_driver} />
										</td>
										<td className="driver_checkbox_label">Я – водитель</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				}
				{state.is_driver &&
					<>
						<div className="driver_container">
							<div className="driver_subtitle desktop_only">Данные водительского удостоверения</div>
							<div className="driver_container_item">
								<label htmlFor="owner_license_number" className="driver_input_label">
									Серия и номер ВУ
								</label>
								<InputMask mask="99 99 999999" value={state.license_number} onChange={(e) => {
									if (isOwner) {
										setOwnerLicenseNumber(e.target.value);
										setOwnerComplete(false);
									} else {
										setDriverLicenseNumber(e.target.value);
										setDriverComplete(false);
									}
									if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
										if (isOwner) storeOwnerData()
											else storeDriverData(driverIndex);
										setChanged(true);
									}
								}}>
									{(inputProps) => <input {...inputProps} id="owner_license_number" ref={licenseNumber}
										className={'driver_input driver_input_license' + (state.license_number &&
										state.license_number !== '' && state.license_number.indexOf('_') === -1 ?
										' driver_input-complete' : (error ? ' error' : ''))}
										placeholder="серия и номер ВУ" type="tel" />
									}
								</InputMask>
							</div>
							<div className="driver_container_item driver_container_item-short">
								<label htmlFor="owner_license_issue_date" className="driver_input_label">
									Дата выдачи
								</label>
								<DatePicker
									id="owner_license_issue_date"
									ref={licenseIssueDate}
									className={'driver_input_license' + (validateDate(state.license_issue_date) ?
										' driver_input-complete' : (error ? ' error' : ''))}
									value={state.license_issue_date}
									clearIcon={null}
									showLeadingZeros={true}
									closeCalendar={true}
									dayPlaceholder="ДД"
									monthPlaceholder="ММ"
									yearPlaceholder="ГГГГ"
									format="dd.MM.y"
									locale="ru-RU"
									maxDate={new Date()}
									onChange={setLicenseIssueDate}
								/>
							</div>
							<div className="driver_container_item driver_container_item-short mobile_right">
								<label htmlFor="owner_license_validity" className="driver_input_label">
									Срок действия
								</label>
								<DatePicker
									id="owner_license_validity"
									ref={licenseValidity}
									className={'driver_input_license' + (validateFutureDate(state.license_validity) ?
										' driver_input-complete' : (error ? ' error' : ''))}
									value={state.license_validity}
									clearIcon={null}
									showLeadingZeros={true}
									closeCalendar={true}
									dayPlaceholder="ДД"
									monthPlaceholder="ММ"
									yearPlaceholder="ГГГГ"
									format="dd.MM.y"
									locale="ru-RU"
									onChange={setLicenseValidity}
								/>
							</div>
							<div className="driver_container_item driver_container_item-short">
								<label htmlFor="owner_license_issued_by" className="driver_input_label">
									Кем выдано
								</label>
								<input id="owner_license_issued_by"
									value={state.license_issued_by} className={'driver_input driver_input_license' +
									(validateOrg(state.license_issued_by) ? ' driver_input-complete' :
									(error ? ' error' : ''))} ref={licenseIssuedBy}
									onChange={(e) => {
										if (isOwner) {
											setOwnerLicenseIssuedBy(e.target.value);
											setOwnerComplete(false);
										} else {
											setDriverLicenseIssuedBy(e.target.value);
											setDriverComplete(false);
										}
										if (validateOrg(e.target.value)) {
											if (isOwner) storeOwnerData()
												else storeDriverData(driverIndex);
											setChanged(true);
										}
									}}
									placeholder="ГИБДД" type="text"
								/>
							</div>
							<div className="driver_container_item driver_container_item-short">
								<label htmlFor="owner_license_country" className="driver_input_label">
									Страна
								</label>
								<DaData id="owner_license_country"
									value={state.license_country || ''} className="driver_dadata driver_input_license"
									inputClassName={'driver_dadata_input driver_input-normal' +
									(state.license_country && state.license_country.length >= 3 ? ' driver_input-complete' :
									(error ? ' error' : ''))} ref={licenseCountry}
									onBlur={async () => {
										if (licenseCountry.current && licenseCountry.current.value !== '') {
											let data = await addressAPI.getCountries(licenseCountry.current.value);
											if (data && data.data && data.data.suggestions && data.data.suggestions.length > 0) {
												if (isOwner) setOwnerLicenseCountry(data.data.suggestions[0].value)
													else setDriverLicenseCountry(data.data.suggestions[0].value);
												if (isOwner) storeOwnerData()
													else storeDriverData(driverIndex);
												setChanged(true);
											}
										}
									}}
									onChange={async (value) => {
										if (isOwner) {
											setOwnerLicenseCountry('');
											setOwnerComplete(false);
										} else {
											setDriverLicenseCountry('');
											setDriverComplete(false);
										}
										setChanged(true);
										let data = await addressAPI.getCountries(value);
										if (data && data.data && data.data.suggestions) return data.data.suggestions;
										return [];
									}}
									onSelect={(suggestion) => {
										if (isOwner) setOwnerLicenseCountry(suggestion.value)
											else setDriverLicenseCountry(suggestion.value);
										if (isOwner) storeOwnerData()
											else storeDriverData(driverIndex);
										setChanged(true);
									}} placeholder="Россия" type="text"
								/>
							</div>
						</div>
						<div className="driver_container">
							<div className="driver_container_item">
								<label className="driver_input_label">Категории</label>
								<div className={'driver_category_container' +
									((!state.license_categories || state.license_categories.length === 0) && error ?
									' error' : '')}>
									{categories.map((category, index) => (
										<div key={'category_' + index} className="driver_category_item"
											onClick={() => {
											let cat = state.license_categories;
											if (!cat) cat = [];
											let index = cat.findIndex((c) => c === category);
											if (index >= 0) {
												cat.splice(index, 1);
											} else {
												cat.push(category);
											}
											if (isOwner) {
												setOwnerLicenseCategories(cat);
												if (cat.length === 0) setOwnerComplete(false);
											} else {
												setDriverLicenseCategories(cat);
												if (cat.length === 0) setDriverComplete(false);
											}
											if (isOwner) storeOwnerData()
												else storeDriverData(driverIndex);
											setChanged(true);
										}}>
											<span className="driver_category_check">
												<Checkbox checked={state.license_categories &&
													state.license_categories.includes(category)} />
											</span>
											<span className="driver_category_label">{category}</span>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="driver_container" style={{flexDirection: 'column'}}>
							<div className="driver_subtitle">Прикрепить фото водительского удостоверения</div>
							<div className="driver_photo_container">
								<div className="driver_photo">
									<label className="driver_photo_label">Фото 1</label>
									<label htmlFor="driver_license_photo_0" ref={licenseFront} tabIndex="1"
										className={'driver_photo_button' + (state.license_photo_0 ?
										' driver_photo_button-complete' : (error ? ' error' : ''))}>
										<div className="driver_photo_button_text">
											{state.license_photo_0 ? state.license_photo_0.name : 'Фото 1'}
										</div>
									</label>
									<input type="file" id="driver_license_photo_0"
										accept="image/*"
										onChange={async (e) => {
											if (e.target.files.length !== 0) {
												if (isOwner) await setOwnerLicensePhoto(0, e.target.files[0])
													else await setDriverLicensePhoto(0, e.target.files[0]);
												if (isOwner) storeOwnerData()
													else storeDriverData(driverIndex);
												setChanged(true);
											}
										}}
									/>
								</div>
								<div className="driver_photo">
									<label className="driver_photo_label">Фото 2</label>
									<label htmlFor="driver_license_photo_1" ref={licenseRear} tabIndex="1"
										className={'driver_photo_button' + (state.license_photo_1 ?
										' driver_photo_button-complete' : (error ? ' error' : ''))}>
										<div className="driver_photo_button_text">
											{state.license_photo_1 ? state.license_photo_1.name : 'Фото 2'}
										</div>
									</label>
									<input type="file" id="driver_license_photo_1"
										accept="image/*"
										onChange={async (e) => {
											if (e.target.files.length !== 0) {
												if (isOwner) await setOwnerLicensePhoto(1, e.target.files[0])
													else await setDriverLicensePhoto(1, e.target.files[0]);
												if (isOwner) storeOwnerData()
													else storeDriverData(driverIndex);
												setChanged(true);
											}
										}}
									/>
								</div>
							</div>
						</div>
					</>
				}
				<div className={'active_tab_button' + (!validateName(state.name) ||
					!validatePhones() || !validateDate(state.birthdate) || state.passport_number === '' ||
					!state.passport_number || state.passport_number.indexOf('_') !== -1 ||
					!validateOrg(state.passport_issued_by) || !validateDate(state.passport_issue_date) ||
					!state.address || !state.address.data.house || !state.passport_photo_0 ||
					!state.passport_photo_1 || !state.passport_photo_2 || (state.is_driver &&
					(state.license_number === '' || !state.license_number ||
					state.license_number.indexOf('_') !== -1 || !validateDate(state.license_issue_date) ||
					!validateFutureDate(state.license_validity) || !validateOrg(state.license_issued_by) ||
					!state.license_country || state.license_country.length < 3 ||
					!state.license_categories || state.license_categories.length === 0 || !state.license_photo_0 ||
					!state.license_photo_1)) ? ' active_tab_button-inactive' : '')}
					onClick={() => {
						setChanged(true);
						setNeedsSave(true);
						if (!validateName(state.name)) {
							setError(true);
							nameRef.current.focus();
							return;
						}
						if (state.phones.length === 0 || !validatePhone(state.phones[0])) {
							setError(true);
							phoneRef.current.focus();
							return;
						}
						if (state.phones.length > 1 && !validatePhone(state.phones[1])) {
							setError(true);
							phone1Ref.current.focus();
							return;
						}
						if (state.phones.length > 2 && !validatePhone(state.phones[2])) {
							setError(true);
							phone2Ref.current.focus();
							return;
						}
						if (!validateDate(state.birthdate)) {
							setError(true);
							birthdate.current.wrapper.firstChild.firstChild.children[1].focus();
							return;
						}
						if (state.passport_number === '' || state.passport_number.indexOf('_') !== -1) {
							setError(true);
							passportNumber.current.focus();
							return;
						}
						if (!validateOrg(state.passport_issued_by)) {
							setError(true);
							passportIssuedBy.current.focus();
							return;
						}
						if (!validateDate(state.passport_issue_date)) {
							setError(true);
							passportIssueDate.current.wrapper.firstChild.firstChild.children[1].focus();
							return;
						}
						/*if (state.passport_department === '' ||
							state.passport_department.indexOf('_') !== -1) {
							setError(true);
							passportDepartment.current.focus();
							return;
						}*/
						/*if (!state.passport_registration) {
							setError(true);
							registration.current.focus();
							return;
						}
						if (!state.passport_registration.data.house) {
							setError(true);
							registrationHouse.current.focus();
							return;
						}*/
						if (!state.address) {
							setError(true);
							address.current.focus();
							return;
						}
						if (!state.address.data.house) {
							setError(true);
							addressHouse.current.focus();
							return;
						}
						if (!state.passport_photo_0) {
							setError(true);
							passportPhoto.current.focus();
							return;
						}
						if (!state.passport_photo_1) {
							setError(true);
							registrationPhoto.current.focus();
							return;
						}
						if (!state.passport_photo_2) {
							setError(true);
							selfie.current.focus();
							return;
						}
						if (state.is_driver && (state.license_number === '' ||
							state.license_number.indexOf('_') !== -1)) {
							setError(true);
							licenseNumber.current.focus();
							return;
						}
						if (state.is_driver && !validateDate(state.license_issue_date)) {
							setError(true);
							licenseIssueDate.current.wrapper.firstChild.firstChild.children[1].focus();
							return;
						}
						if (state.is_driver && !validateFutureDate(state.license_validity)) {
							setError(true);
							licenseValidity.current.wrapper.firstChild.firstChild.children[1].focus();
							return;
						}
						if (state.is_driver && !validateOrg(state.license_issued_by)) {
							setError(true);
							licenseIssuedBy.current.focus();
							return;
						}
						if (state.is_driver && state.license_country.length < 3) {
							setError(true);
							licenseCountry.current.focus();
							return;
						}
						if (state.is_driver && state.license_categories.length === 0) {
							setError(true);
							return;
						}
						if (state.is_driver && !state.license_photo_0) {
							setError(true);
							licenseFront.current.focus();
							return;
						}
						if (state.is_driver && !state.license_photo_1) {
							setError(true);
							licenseRear.current.focus();
							return;
						}
						if (isOwner) {
							setOwnerComplete(true);
							storeOwnerData();
						} else {
							setDriverComplete(true);
							storeDriverData(driverIndex);
							clearDriverData();
							if (partner.owner_type === SINGLE_CAR_OWNER &&
								partner.vehicles.length > 0 && partner.vehicles[0]) {
								let vehicle = partner.vehicles[0];
								vehicle.driver = partner.drivers[0];
								setVehicleData(0, vehicle);
							}
						}
						showDriverForm(false, false);
						setChanged(true);
					}}>
					<div className="active_tab_button_text">Сохранить</div>
				</div>
				<div className="active_tab_back" onClick={() => {
					if (!isOwner && state.should_clear) clearDriverData();
					showDriverForm(false, false);
				}}>Назад</div>
			</div>
		</div>
	);
}

export default Driver;
