import {
	validateName,
	validateDate,
	validateFutureDate,
	validatePhone,
	validateOrg
} from "../../components/validate";
import { sendPhoto } from "../../api/register-api";

const SET_OWNER_NAME = 'SET_OWNER_NAME';
const ADD_OWNER_PHONE = 'ADD_OWNER_PHONE';
const REMOVE_OWNER_PHONE = 'REMOVE_OWNER_PHONE';
const DELETE_OWNER_PHONES = 'DELETE_OWNER_PHONES';
const SET_OWNER_EMAIL = 'SET_OWNER_EMAIL';
const SET_OWNER_BIRTHDATE = 'SET_OWNER_BIRTHDATE';
const SET_OWNER_PASSPORT_NUMBER = 'SET_OWNER_PASSPORT_NUMBER';
const SET_OWNER_PASSPORT_ISSUED_BY = 'SET_OWNER_PASSPORT_ISSUED_BY';
const SET_OWNER_PASSPORT_ISSUE_DATE = 'SET_OWNER_PASSPORT_ISSUE_DATE';
const SET_OWNER_PASSPORT_DEPARTMENT = 'SET_OWNER_PASSPORT_DEPARTMENT';
const SET_OWNER_PASSPORT_REGISTRATION = 'SET_OWNER_PASSPORT_REGISTRATION';
const SET_OWNER_ADDRESS = 'SET_OWNER_ADDRESS';
const SET_OWNER_PASSPORT_PHOTO = 'SET_OWNER_PASSPORT_PHOTO';
const SET_IS_DRIVER = 'SET_IS_DRIVER';
const SET_OWNER_LICENSE_NUMBER = 'SET_OWNER_LICENSE_NUMBER';
const SET_OWNER_LICENSE_ISSUE_DATE = 'SET_OWNER_LICENSE_ISSUE_DATE';
const SET_OWNER_LICENSE_VALIDITY = 'SET_OWNER_LICENSE_VALIDITY';
const SET_OWNER_LICENSE_ISSUED_BY = 'SET_OWNER_LICENSE_ISSUED_BY';
const SET_OWNER_LICENSE_COUNTRY = 'SET_OWNER_LICENSE_COUNTRY';
const SET_OWNER_LICENSE_CATEGORIES = 'SET_OWNER_LICENSE_CATEGORIES';
const SET_OWNER_LICENSE_PHOTO = 'SET_OWNER_LICENSE_PHOTO';
const SET_OWNER_COMPLETE = 'SET_OWNER_COMPLETE';

let initialState = {
	name: '',
	phones: [],
	email: '',
	birthdate: null,
	passport_number: '',
	passport_issued_by: '',
	passport_issue_date: null,
	passport_department: '',
	passport_registration: null,
	address: null,
	same_address: false,
	passport_photo_id_0: '',
	passport_photo_id_1: '',
	passport_photo_id_2: '',
	passport_photo_0: null,
	passport_photo_1: null,
	passport_photo_2: null,
	is_driver: false,
	license_number: '',
	license_issue_date: null,
	license_validity: null,
	license_issued_by: '',
	license_country: '',
	license_categories: [],
	license_photo_id_0: '',
	license_photo_id_1: '',
	license_photo_0: null,
	license_photo_1: null,
	complete: false
};

const ownerReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_OWNER_NAME:
			return {
				...state,
				name: action.name
			}
		case ADD_OWNER_PHONE:
			let phonesToAdd = [...state.phones];
			if (phonesToAdd.length < action.index) {
				phonesToAdd.push(action.phone);
			} else {
				phonesToAdd[action.index] = action.phone;
			}
			return {
				...state,
				phones: phonesToAdd
			}
		case REMOVE_OWNER_PHONE:
			let phonesToRemove = [...state.phones];
			phonesToRemove.splice(action.index, 1);
			return {
				...state,
				phones: phonesToRemove
			}
		case DELETE_OWNER_PHONES:
			return {
				...state,
				phones: []
			}
		case SET_OWNER_EMAIL:
			return {
				...state,
				email: action.email
			}
		case SET_OWNER_BIRTHDATE:
			return {
				...state,
				birthdate: action.date
			}
		case SET_OWNER_PASSPORT_NUMBER:
			return {
				...state,
				passport_number: action.number
			}
		case SET_OWNER_PASSPORT_ISSUED_BY:
			return {
				...state,
				passport_issued_by: action.org
			}
		case SET_OWNER_PASSPORT_ISSUE_DATE:
			return {
				...state,
				passport_issue_date: action.date
			}
		case SET_OWNER_PASSPORT_DEPARTMENT:
			return {
				...state,
				passport_department: action.department
			}
		case SET_OWNER_PASSPORT_REGISTRATION:
			return {
				...state,
				passport_registration: action.address,
				same_address: action.same_address,
				address: action.same_address ? action.address :
					action.same_address === state.same_address ? state.address : null
			}
		case SET_OWNER_ADDRESS:
			return {
				...state,
				address: action.address
			}
		case SET_OWNER_PASSPORT_PHOTO:
			return {
				...state,
				passport_photo_0: action.index === 0 ? action.photo : state.passport_photo_0,
				passport_photo_1: action.index === 1 ? action.photo : state.passport_photo_1,
				passport_photo_2: action.index === 2 ? action.photo : state.passport_photo_2,
				passport_photo_id_0: action.index === 0 ? action.id : state.passport_photo_id_0,
				passport_photo_id_1: action.index === 1 ? action.id : state.passport_photo_id_1,
				passport_photo_id_2: action.index === 2 ? action.id : state.passport_photo_id_2
			}
		case SET_IS_DRIVER:
			return {
				...state,
				is_driver: action.is_driver
			}
		case SET_OWNER_LICENSE_NUMBER:
			return {
				...state,
				license_number: action.number
			}
		case SET_OWNER_LICENSE_ISSUE_DATE:
			let validity = state.license_validity;
			if (action.date && validateDate(action.date)) {
				validity = new Date(action.date);
				validity.setFullYear(action.date.getFullYear() + 10);
			}
			return {
				...state,
				license_issue_date: action.date,
				license_validity: validity
			}
		case SET_OWNER_LICENSE_VALIDITY:
			return {
				...state,
				license_validity: action.date
			}
		case SET_OWNER_LICENSE_ISSUED_BY:
			return {
				...state,
				license_issued_by: action.org
			}
		case SET_OWNER_LICENSE_COUNTRY:
			return {
				...state,
				license_country: action.country
			}
		case SET_OWNER_LICENSE_CATEGORIES:
			return {
				...state,
				license_categories: action.categories
			}
		case SET_OWNER_LICENSE_PHOTO:
			return {
				...state,
				license_photo_0: action.index === 0 ? action.photo : state.license_photo_0,
				license_photo_1: action.index === 1 ? action.photo : state.license_photo_1,
				license_photo_id_0: action.index === 0 ? action.id : state.license_photo_id_0,
				license_photo_id_1: action.index === 1 ? action.id : state.license_photo_id_1
			}
		case SET_OWNER_COMPLETE:
			return {
				...state,
				complete: action.value
			}
		default:
			return state;
	}
};

export const setOwnerName = (name) => ({type: SET_OWNER_NAME, name});
export const addOwnerPhone = (index, phone) => ({type: ADD_OWNER_PHONE, index, phone});
export const removeOwnerPhone = (index) => ({type: REMOVE_OWNER_PHONE, index});
const deleteOwnerPhones = () => ({type: DELETE_OWNER_PHONES});
export const setOwnerEmail = (email) => ({type: SET_OWNER_EMAIL, email});
export const setOwnerBirthdate = (date) => ({type: SET_OWNER_BIRTHDATE, date});
export const setOwnerPassportNumber = (number) => ({type: SET_OWNER_PASSPORT_NUMBER, number});
export const setOwnerPassportIssuedBy = (org) => ({type: SET_OWNER_PASSPORT_ISSUED_BY, org});
export const setOwnerPassportIssueDate = (date) => ({type: SET_OWNER_PASSPORT_ISSUE_DATE, date});
export const setOwnerPassportDepartment = (department) => (
	{type: SET_OWNER_PASSPORT_DEPARTMENT, department}
);
export const setOwnerPassportRegistration = (address, same_address) => (
	{type: SET_OWNER_PASSPORT_REGISTRATION, address, same_address}
);
export const setOwnerAddress = (address) => ({type: SET_OWNER_ADDRESS, address});
export const setOwnerPassportPhoto = (index, photo, id) => ({type: SET_OWNER_PASSPORT_PHOTO, index, photo, id});
export const setIsDriver = (is_driver) => ({type: SET_IS_DRIVER, is_driver});
export const setOwnerLicenseNumber = (number) => ({type: SET_OWNER_LICENSE_NUMBER, number});
export const setOwnerLicenseIssueDate = (date) => ({type: SET_OWNER_LICENSE_ISSUE_DATE, date});
export const setOwnerLicenseValidity = (date) => ({type: SET_OWNER_LICENSE_VALIDITY, date});
export const setOwnerLicenseIssuedBy = (org) => ({type: SET_OWNER_LICENSE_ISSUED_BY, org});
export const setOwnerLicenseCountry = (country) => ({type: SET_OWNER_LICENSE_COUNTRY, country});
export const setOwnerLicenseCategories = (categories) => (
	{type: SET_OWNER_LICENSE_CATEGORIES, categories}
);
export const setOwnerLicensePhoto = (index, photo, id) => ({type: SET_OWNER_LICENSE_PHOTO, index, photo, id});
export const setOwnerComplete = (value) => ({type: SET_OWNER_COMPLETE, value});

export const setOwnerPassportPhotoThunk = (index, photo) => async (dispatch) => {
	const id = await sendPhoto(photo);
	dispatch(setOwnerPassportPhoto(index, photo, id));
}

export const setOwnerLicensePhotoThunk = (index, photo) => async (dispatch) => {
	const id = await sendPhoto(photo);
	dispatch(setOwnerLicensePhoto(index, photo, id));
}

export const validateOwner = (owner) => {
	return validateName(owner.name) &&
		owner.phones.length > 0 && validatePhone(owner.phones[0]) &&
		(owner.phones.length <= 1 || validatePhone(owner.phones[1])) &&
		(owner.phones.length <= 2 || validatePhone(owner.phones[2])) &&
		validateDate(owner.birthdate) &&
		owner.passport_number !== '' && owner.passport_number.indexOf('_') < 0 &&
		validateOrg(owner.passport_issued_by) &&
		validateDate(owner.passport_issue_date) &&
		owner.address && owner.address.data.house &&
		owner.passport_photo_0 && owner.passport_photo_1 && owner.passport_photo_2 &&
		(!owner.is_driver ||
		(owner.license_number !== '' && owner.license_number.indexOf('_') < 0 &&
		validateDate(owner.license_issue_date) &&
		validateFutureDate(owner.license_validity) &&
		validateOrg(owner.license_issued_by) &&
		owner.license_country.length >= 3 &&
		owner.license_categories.length > 0 &&
		owner.license_photo_0 && owner.license_photo_1));
}

export const loadOwnerData = () => (dispatch, getState) => {
	let owner = getState().partnerReducer.owner;
	let phone = getState().phoneReducer.phone;
	if (owner) {
		dispatch(setOwnerName(owner.name));
		dispatch(deleteOwnerPhones());
		owner.phones.forEach((phone, index) => dispatch(addOwnerPhone(index, phone)));
		dispatch(setOwnerEmail(owner.email));
		dispatch(setOwnerBirthdate(owner.birthdate));
		dispatch(setOwnerPassportNumber(owner.passport_number));
		dispatch(setOwnerPassportIssuedBy(owner.passport_issued_by));
		dispatch(setOwnerPassportIssueDate(owner.passport_issue_date));
		//dispatch(setOwnerPassportDepartment(owner.passport_department));
		dispatch(setOwnerAddress(owner.address));	
		//dispatch(setOwnerPassportRegistration(owner.passport_registration, owner.same_address));
		dispatch(setOwnerPassportPhoto(0, owner.passport_photo_0, owner.passport_photo_id_0));
		dispatch(setOwnerPassportPhoto(1, owner.passport_photo_1, owner.passport_photo_id_1));
		dispatch(setOwnerPassportPhoto(2, owner.passport_photo_2, owner.passport_photo_id_2));
		dispatch(setIsDriver(owner.is_driver));
		dispatch(setOwnerLicenseNumber(owner.license_number));
		dispatch(setOwnerLicenseIssueDate(owner.license_issue_date));
		dispatch(setOwnerLicenseValidity(owner.license_validity));
		dispatch(setOwnerLicenseIssuedBy(owner.license_issued_by));
		dispatch(setOwnerLicenseCountry(owner.license_country));
		dispatch(setOwnerLicenseCategories(owner.license_categories));
		dispatch(setOwnerLicensePhoto(0, owner.license_photo_0, owner.license_photo_id_0));
		dispatch(setOwnerLicensePhoto(1, owner.license_photo_1, owner.license_photo_id_1));
		dispatch(setOwnerComplete(validateOwner(owner)));
	} else {
		dispatch(addOwnerPhone(0, phone));
		dispatch(setOwnerComplete(false));
	}
}

export default ownerReducer;
