import { forwardRef, useRef, useState, useEffect } from "react";

const DaData = forwardRef((props, inputRef) => {
	const dropdownRef = useRef(null);
	const [open, setOpen] = useState(false);
	const [suggestions, setSuggestions] = useState([]);
	const [focus, setFocus] = useState(-1);

	useEffect(() => {
		function handleClickOutside(e) {
			if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
				setOpen(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	});

	useEffect(() => {
		if (inputRef && inputRef.current) inputRef.current.value = props.value;
	}, [props.value, inputRef]);

	return (
		<>
			<div className={'dadata' + (props.className ? ' ' + props.className : '')}>
				<input id={props.id || ''} key={props.keyId || ''} className={props.inputClassName || ''}
					placeholder={props.placeholder || ''} defaultValue={props.value || ''} ref={inputRef}
					onBlur={() => {
						setOpen(false);
						if (props.onBlur) props.onBlur();
					}}
					onChange={async (e) => {
						if (props.onChange) {
							let data = await props.onChange(e.target.value);
							setSuggestions(data);
							setOpen(true);
						}
					}}
					onKeyDown={(e) => {
						if (e.key === 'ArrowDown') {
							e.preventDefault();
							if (dropdownRef.current && suggestions.length > 0 && focus < suggestions.length - 1) {
								inputRef.current.value = suggestions[focus + 1].value;
								setFocus(focus + 1);
							}
							return;
						}
						if (e.key === 'ArrowUp') {
							e.preventDefault();
							if (dropdownRef.current && suggestions.length > 0 && focus >= 0) {
								inputRef.current.value = focus > 0 ? suggestions[focus - 1].value : '';
								setFocus(focus - 1);
							}
							return;
						}
						if (e.key === 'Enter') {
							e.preventDefault();
							if (dropdownRef.current && suggestions.length > 0 && focus >= 0) {
								setOpen(false);
								if (props.onSelect) props.onSelect(suggestions[focus]);
							}
						}
						if (e.key === 'Escape') {
							e.preventDefault();
							setOpen(false);
						}
						setFocus(-1);
					}}
				/>
				{open && suggestions.length > 0 &&
					<div ref={dropdownRef} className="dadata">
						<div className="dadata_suggestions" style={{minWidth: props.minWidth}}>
							{suggestions.map((suggestion, index) => (
								<div key={'suggestion_' + index} className="dadata_suggestion" onMouseDown={() => {
									if (inputRef && inputRef.current) inputRef.current.value = suggestion.value;
									setOpen(false);
									if (props.onSelect) props.onSelect(suggestion);
								}}>
									{suggestion.value}
								</div>
							))}
						</div>
					</div>
				}
			</div>
		</>
	);
});

export default DaData;
