const Message = ({
	message
}) => (
	<div className="message_window">
		<div className="message">
			<div className="message_text">{message}</div>
		</div>
	</div>
);

export default Message;
