const Checkbox = ({
	checked
}) => (
	<div className="checkbox">
		<input type="checkbox" checked={checked} onChange={() => {}} />
		<div className="checkbox_mark"></div>
	</div>
);

export default Checkbox;
