import {
	applyMiddleware,
	combineReducers,
	compose,
	createStore
} from "redux";
import thunkMiddleware from "redux-thunk";
import {
	phoneReducer,
	partnerReducer,
	bankReducer,
	ownerReducer,
	driverReducer,
	vehicleReducer,
	referrerReducer
} from "./reducers";

let reducers = combineReducers({
	phoneReducer,
	partnerReducer,
	bankReducer,
	ownerReducer,
	driverReducer,
	vehicleReducer,
	referrerReducer
});

const composeEnhancers = compose;
let store = createStore(reducers, composeEnhancers(applyMiddleware(thunkMiddleware)));

export default store;
