import * as axios from "axios";
import axiosRetry from 'axios-retry';
import { apiBaseUrl } from "../deployment";

axiosRetry(axios, { retries: 50 });

const instance = axios.create({
	baseURL: apiBaseUrl,
});

export const phoneAPI = {
	sendSms(phone) {
		let requestData = {phone};
		return instance.post('/send-sms-code', requestData)
			.then(response => response)
			.catch(error => error);
	},
	checkSms(phone, code) {
		return instance.get('/check-sms-code?phone=' + phone + '&code=' + code)
			.then(response => response)
			.catch(error => error);
	},
};

export const tinAPI = {
	checkTin(tin) {
		return instance.get('/check-tin?bik=' + tin)
			.then(response => response)
			.catch(error => error);
	}
};

export const vehicleAPI = {
	getCarTypes() {
		return instance.get('/car-types')
			.then(response => response)
			.catch(error => error);
	},
	getBodyOptions(carTypeId) {
		return instance.get('/body-options?car_type_id=' + carTypeId)
			.then(response => response)
			.catch(error => error);
	},
	getBodyOptionChs(bodyOptionId, bodyTypeId, carTypeId) {
		return instance.get('/body-option-characteristics?body_option_id=' +
			bodyOptionId + '&body_type_id=' + bodyTypeId +
			'&car_type_id=' + carTypeId)
			.then(response => response)
			.catch(error => error);
	},
	getBodyOptionChValues(bodyOptionChId, carTypeId) {
		return instance.get('/body-option-characteristics-values' +
			'?body_option_characteristics_id=' + bodyOptionChId +
			'&car_type_id=' + carTypeId)
			.then(response => response)
			.catch(error => error);
	},
	getCarCategories() {
		return instance.get('/car-categories')
			.then(response => response)
			.catch(error => error);
	},
	getCarPasses() {
		return instance.get('/car-pass')
			.then(response => response)
			.catch(error => error);
	}
};

export const driversAPI = {
	postData(data) {
		return instance.post('/drivers', data)
			.then(response => response)
			.catch(error => error);
	},
	postFiles(data) {
		return instance.post('/driver-files', data)
			.then(response => response)
			.catch(error => error);
	},
	getData(id, draft = undefined) {
		return instance.get('/drivers?id=' + id + (draft ? '&draft=true': ''))
			.then(response => response)
			.catch(error => error);
	},
	getDriverData(id) {
		return instance.get('/drivers?driver_id=' + id)
			.then(response => response)
			.catch(error => error);
	},
	getFile(id) {
		return instance.get('/driver-files?id=' + id)
			.then(response => {
				if (!response.data.file) {
					console.log('Error fetching file');
					return null;
				}
				return response.data.file;
			})
			.catch(error => error);
	}
}

export const toBase64 = file => new Promise((resolve, reject) => {
	const reader = new FileReader();
	reader.readAsDataURL(file);
	reader.onload = () => resolve(reader.result);
	reader.onerror = error => reject(error);
});

export const toFile = (base64, name, contentType = 'image/jpg', sliceSize = 512) => {
	const byteCharacters = atob(base64);
	const byteArrays = [];
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		const slice = byteCharacters.slice(offset, offset + sliceSize);
		const byteNumbers = new Array(slice.length);
		for (let i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}
		const byteArray = new Uint8Array(byteNumbers);
		byteArrays.push(byteArray);
	}
  const blob = new Blob(byteArrays, {type: contentType});
  return new File([blob], name);
}

export const getPhoto = async (id, name) => {
	let data = await driversAPI.getFile(id);
	if (data) {
		return toFile(data, name);
	} else {
		return null;
	}
}

export const sendPhoto = async (file) => {
	const data = await toBase64(file);
	let response = await driversAPI.postFiles({
		name: file.name,
		data: data.split(',')[1]
	});
	if (response.status === 200 && response.data && response.data.id) {
		return response.data.id;
	} else {
		return '';
	}
}

export const cookiesAPI = {
	setCookie(name, value, days) {
		let date = new Date();
		date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		document.cookie = name + '=' + value + '; expires=' + date.toUTCString() + '; path=/';
	},
	getCookie(name) {
		let n = name + '=';
		let ca = document.cookie.split(';');
		let c;
		for (let i = 0; i < ca.length; i++) {
			c = ca[i];
			while (c.charAt(0) === ' ') c = c.substring(1, c.length);
			if (c.indexOf(n) === 0) {
				return c.substring(n.length, c.length);
			}
		}
		return '';
	},
	deleteCookie(name) {
		document.cookie = name + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/';
	}
}

export const auth = (phone, code) => {
	let requestData = { phone_number: phone, sms_code: code };
	return instance.post('/auth', requestData)
		.then(response => response)
		.catch(error => error);
}
