import React, { useRef, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import InputMask from "react-input-mask";
import Loader from "react-loader-spinner";
import { PartySuggestions } from "react-dadata";
import { token } from "../../api/dadata-api";
import { driversAPI, cookiesAPI } from "../../api/register-api";
import {
	Checkbox,
	Radio,
	Message
} from "../Elements";
import {
	BankContainer,
	DriverContainer,
	DriverSelectContainer,
	VehicleContainer,
	ReferrerContainer
} from "../"
import {
	SINGLE_CAR_OWNER,
	MULTIPLE_CARS_OWNER,
	TIN_IP,
	TIN_SAM, setIsEditUser
} from "../../redux/reducers/partnerReducer";
import {trimPhone, convertDate, getBoolean} from "../validate";
import { autoSaveInterval } from "../../deployment";

const editingChars = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab', 'Home', 'End'];

const onCloseBrowser = (ev) =>{

	return ev.returnValue = true;
};

const Partner = ({
	state,
	phone,
	setOwnerType,
	setTin,
	setLater,
	verifyTin,
	setTinVerifyStatus,
	showBankForm,
	showDriverForm,
	loadBankData,
	loadOwnerData,
	loadDriverData,
	removeDriver,
	showVehicleForm,
	loadVehicleData,
	removeVehicle,
	setReferrerData,
	showReferrerForm,
	loadReferrerData,
	setChanged,
	setNeedsSave,
	setId,
	loadData,
	setDraft
}) => {
	const interval = useRef(null);
	const tinIP = useRef(null);
	const tinSAM = useRef(null);
	const [message, setMessage] = useState('');
	const [driverIndex, setDriverIndex] = useState(-1);
	const [vehicleIndex, setVehicleIndex] = useState(-1);
	const [sendingData, setSendingData] = useState(false);
	const [driverSelect, showDriverSelect] = useState(false);
	const [cancelClick, setCancelClick] = useState(false);
	const [error, setError] = useState(false);
	const dispatch = useDispatch();
	const history = useHistory();
	const isChanged = useRef(false);
	const currentState = useRef(null);
	const { partner} = useSelector((state) => ({ partner: state.partnerReducer }));
	const save = async (is_auto_save=true) => {
		if (isChanged.current) {
			dispatch(setDraft(true));
			sendData(currentState.current, is_auto_save ? true : undefined);
		}
	}

	useEffect(() => {
		let query = window.location.search;
		if (query !== '' && query.slice(0, 4) === '?id=') query = query.slice(4);
		if (query.length > 0) {
			dispatch(setId(query));
			dispatch(setIsEditUser(true));
			loadData(query).then(() => {});
			cookiesAPI.deleteCookie('id');
			cookiesAPI.setCookie('id', query, 1);
		}
		if (interval.current) clearInterval(interval.current);
		if (autoSaveInterval && autoSaveInterval > 0) {
			interval.current = setInterval(save, autoSaveInterval);
		}

		window.current_state = state;


	}, []);

	useEffect(() => {
		currentState.current = state;
		console.log('partner.draft', partner.draft);
		window.removeEventListener("beforeunload", onCloseBrowser);
		if (partner.draft)
			window.addEventListener("beforeunload", onCloseBrowser);

	}, [partner, state, state.draft, partner.draft]);

	useEffect(() => {
		isChanged.current = state.changed;
	}, [state.changed]);

	useEffect(() => {
		if (state.needs_save) save();
	}, [state.needs_save]);

	useEffect(() => {
		switch (state.tin_verify_status) {
			case 'exists':
				setMessage('ИНН ' + state.tin + ' уже зарегистрирован в системе');
				setTinVerifyStatus('');
				break;
			case 'error':
				setMessage('Ошибка. Попробуйте еще раз.');
				setTinVerifyStatus('');
				break;
			default:
				return;
		}
		setTimeout(setMessage, 5000, '');
	}, [state.tin_verify_status, state.tin, setTinVerifyStatus]);

	useEffect(() => {
		if (state.tin_type === TIN_IP && tinIP.current)
			tinIP.current.setInputValue(state.tin);
	}, [state.tin_type, state.tin]);

	useEffect(() => {
		if (state.tin_type === TIN_SAM && tinSAM.current)
			tinSAM.current.value = state.tin;
	}, [state.tin_type, state.tin]);

	const packPhones = (phones) => {
		if (!phones || phones.length === 0) return '';
		if (phones.length === 1) return trimPhone(phones[0]);
		return phones.map((phone) => trimPhone(phone));
	}

	const getDate = () => {
		const two = (val) => (''+val).length < 2 ? '0'+val : '' + val;
		let dt = new Date();
		return dt.getFullYear() + '-' + two(dt.getMonth()) + '-' + two(dt.getDay())  + 'T' + two(dt.getHours()) + '-' + two(dt.getMinutes()) + '-' + two(dt.getSeconds());
	}

	const sendData = async (state, is_draft = undefined) => {
		let owner = null;
		let response;
		let drivers = [];
		if (state.owner_type === MULTIPLE_CARS_OWNER) owner = state.owner;
		if (owner && owner.is_driver && !state.drivers.find((dr) => dr.name === owner.name)) {
			drivers.push(
				{
					phone_number: packPhones(owner.phones),
					passport: {
						full_name: owner.name ? owner.name : '',
						birth_date: owner.birthdate ? convertDate(owner.birthdate) : '',
						series: owner.passport_number ?
							owner.passport_number.replace(/ /g, '').slice(0, 4).replace(/_/g, '') : '',
						number: owner.passport_number ?
							owner.passport_number.replace(/ /g, '').slice(4).replace(/_/g, '') : '',
						issued_by: owner.passport_issued_by ? owner.passport_issued_by : '',
						department_code: '',
						issue_date: owner.passport_issue_date ? convertDate(owner.passport_issue_date) : '',
						adress: '',
						real_adress: owner.address ? owner.address.value : '',
						files: [
							owner.passport_photo_id_0 ? owner.passport_photo_id_0 : '',
							owner.passport_photo_id_1 ? owner.passport_photo_id_1 : '',
							owner.passport_photo_id_2 ? owner.passport_photo_id_2 : ''
						]
					},
					driver_certificate: {
						full_name: owner.name ? owner.name : '',
						serial: owner.license_number ?
							owner.license_number.replace(/ /g, '').slice(0, 4).replace(/_/g, '') : '',
						number: owner.license_number ?
							owner.license_number.replace(/ /g, '').slice(4).replace(/_/g, '') : '',
						issue_date: owner.license_issue_date ? convertDate(owner.license_issue_date) : '',
						validity: owner.license_validity ? convertDate(owner.license_validity) : '',
						issued_by: owner.license_issued_by ? owner.license_issued_by : '',
						country: owner.license_country ? owner.license_country : '',
						category: owner.license_categories ? owner.license_categories : [],
						files: [
							owner.license_photo_id_0 ? owner.license_photo_id_0 : '',
							owner.license_photo_id_1 ? owner.license_photo_id_1 : ''
						]
					}
				}
			);
		}
		let vehicles = state.vehicles;
		let cars = [];
		let car;
		vehicles.forEach((vehicle) => {

			vehicle.characteristics.forEach((characteristic, index) => {
				switch (characteristic.name) {
					case 'Медкнижка':
						vehicle.characteristics[index].value = vehicle.medical;
						break;
					case 'Водитель грузчик':
						vehicle.characteristics[index].value = vehicle.loader;
						break;
					/*case 'Гидроборт':
						vehicle.characteristics[index].value = vehicle.hydroboard;
						break;*/
					case 'Тип пандуса':
						vehicle.characteristics[index].value = vehicle.ramp;
						break;
					default:
						if (characteristic.type === 'Boolean' && !characteristic.value)
							vehicle.characteristics[index].value = false;
						if (characteristic.type === 'number' && !characteristic.value)
							vehicle.characteristics[index].value = '0';
						if (characteristic.type === 'ref' && !characteristic.value)
							vehicle.characteristics[index].value = '';
						break;
				}
			});

			// if (vehicle.pass && vehicle.pass !== 'none')
			// 	vehicle.characteristics.push({id:'60bdcf2e-ae63-11e2-90ae-001a64b04e42', value: vehicle.pass});

			if (vehicle.ramp && vehicle.ramp !== '' && !vehicle.characteristics.find((ch) => ch.name === 'Тип пандуса')) {
				vehicle.characteristics.push({
					name: 'Тип пандуса',
					type: 'ref',
					id: 'fc3de6d0-40dd-11ea-a9c6-00155d8e4e05',
					value: vehicle.ramp
				});
			}
			vehicle.characteristics = vehicle.characteristics.filter(x => x.value !== false && x.value !== '');

			car = {
				car_type_id: vehicle.car_type ? vehicle.car_type : '',
				body_option_id: vehicle.body_option ? vehicle.body_option : '',
				body_option_characteristics: vehicle.characteristics,
				//pass: (!vehicle.pass || vehicle.pass === 'none') ? '' : vehicle.pass,
				size: {
					length: vehicle.length ? parseFloat(vehicle.length.replace(',', '.')) : '0',
					width: vehicle.width ? parseFloat(vehicle.width.replace(',', '.')) : '0',
					height: vehicle.height ? parseFloat(vehicle.height.replace(',', '.')) : '0'
				},
				adress: {
					adress: vehicle.driver && vehicle.driver.address ? vehicle.driver.address.value : '',
					longitude: vehicle.driver && vehicle.driver.address ?
						parseFloat(vehicle.driver.address.data.geo_lon) : '0',
					latitude: vehicle.driver && vehicle.driver.address ?
						parseFloat(vehicle.driver.address.data.geo_lat) : '0'
				},
				files: [
					vehicle.vehicle_photo_id_0 ? vehicle.vehicle_photo_id_0 : '',
					vehicle.vehicle_photo_id_1 ? vehicle.vehicle_photo_id_1 : '',
					vehicle.vehicle_photo_id_2 ? vehicle.vehicle_photo_id_2 : ''
				],
				certificate: {
					number: vehicle.license_plate ? vehicle.license_plate.replace(/_/g, '') : '',
					VIN: vehicle.vin ? vehicle.vin.replace(/_/g, '') : '',
					brand: vehicle.brand ? vehicle.brand : '',
					model: vehicle.model ? vehicle.model : '',
					type: '',
					category: '',
					date: vehicle.year ? vehicle.year : '',
					class: '',
					passport_serial: '',
					passport_number: '',
					certificate_serial: vehicle.sts_series ? vehicle.sts_series.replace(' ', '').replace(/_/g, '') : '',
					certificate_number: vehicle.sts_number ? vehicle.sts_number.replace(/_/g, '') : '',
					max_weight: vehicle.max_weight ? parseFloat(vehicle.max_weight) : '0',
					free_weight: vehicle.load_weight ? parseFloat(vehicle.load_weight) : '0',
					files: [
						vehicle.certificate_photo_id_0 ? vehicle.certificate_photo_id_0 : '',
						vehicle.certificate_photo_id_1 ? vehicle.certificate_photo_id_1 : ''
					]
				},
				drivers: vehicle.driver ? [
					{
						phone_number: packPhones(vehicle.driver.phones),
						passport: {
							full_name: vehicle.driver.name ? vehicle.driver.name : '',
							birth_date: vehicle.driver.birthdate ? convertDate(vehicle.driver.birthdate) : '',
							series: vehicle.driver.passport_number ?
								vehicle.driver.passport_number.replace(/ /g, '').slice(0, 4).replace(/_/g, '') : '',
							number: vehicle.driver.passport_number ?
								vehicle.driver.passport_number.replace(/ /g, '').slice(4).replace(/_/g, '') : '',
							issued_by: vehicle.driver.passport_issued_by ? vehicle.driver.passport_issued_by : '',
							department_code: '',
							issue_date: vehicle.driver.passport_issue_date ?
								convertDate(vehicle.driver.passport_issue_date) : '',
							adress: '',
							real_adress: vehicle.driver.address ? vehicle.driver.address.value : '',
							files: [
								vehicle.driver.passport_photo_id_0 ? vehicle.driver.passport_photo_id_0 : '',
								vehicle.driver.passport_photo_id_1 ? vehicle.driver.passport_photo_id_1 : '',
								vehicle.driver.passport_photo_id_2 ? vehicle.driver.passport_photo_id_2 : ''
							]
						},
						driver_certificate: {
							full_name: vehicle.driver.name ? vehicle.driver.name : '',
							serial: vehicle.driver.license_number ?
								vehicle.driver.license_number.replace(/ /g, '').slice(0, 4).replace(/_/g, '') : '',
							number: vehicle.driver.license_number ?
								vehicle.driver.license_number.replace(/ /g, '').slice(4).replace(/_/g, '') : '',
							issue_date: vehicle.driver.license_issue_date ?
								convertDate(vehicle.driver.license_issue_date) : '',
							validity: vehicle.driver.license_validity ? convertDate(vehicle.driver.license_validity) : '',
							issued_by: vehicle.driver.license_issued_by ? vehicle.driver.license_issued_by : '',
							country: vehicle.driver.license_country ? vehicle.driver.license_country : '',
							category: vehicle.driver.license_categories ? vehicle.driver.license_categories : [],
							files: [
								vehicle.driver.license_photo_id_0 ? vehicle.driver.license_photo_id_0 : '',
								vehicle.driver.license_photo_id_1 ? vehicle.driver.license_photo_id_1 : ''
							]
						}
					}
				] : []
			};
			cars.push(car);
		});

		state.drivers.forEach((dr) => {
			let driver = {
				phone_number: dr.phones ? packPhones(dr.phones) : [],
				passport: {
					full_name: dr.name ? dr.name : '',
					email: dr.email ? dr.email : '',
					birth_date: dr.birthdate ? convertDate(dr.birthdate) : '',
					series: dr.passport_number ? dr.passport_number.replace(/ /g, '').slice(0, 4).replace(/_/g, '') : '',
					number: dr.passport_number ? dr.passport_number.replace(/ /g, '').slice(4).replace(/_/g, '') : '',
					issued_by: dr.passport_issued_by ? dr.passport_issued_by : '',
					department_code: '',
					issue_date: dr.passport_issue_date ? convertDate(dr.passport_issue_date) : '',
					adress: '',
					real_adress: dr.address ? dr.address.value : '',
					files: [
						dr.passport_photo_id_0 ? dr.passport_photo_id_0 : '',
						dr.passport_photo_id_1 ? dr.passport_photo_id_1 : '',
						dr.passport_photo_id_2 ? dr.passport_photo_id_2 : ''
					]
				},
				driver_certificate: {
					full_name: dr.name ? dr.name : '',
					serial: dr.license_number ? dr.license_number.replace(/ /g, '').slice(0, 4).replace(/_/g, '') : '',
					number: dr.license_number ? dr.license_number.replace(/ /g, '').slice(4).replace(/_/g, '') : '',
					issue_date: dr.license_issue_date ? convertDate(dr.license_issue_date) : '',
					validity: dr.license_validity ? convertDate(dr.license_validity) : '',
					issued_by: dr.license_issued_by ? dr.license_issued_by : '',
					country: dr.license_country ? dr.license_country : '',
					category: dr.license_categories ? dr.license_categories : [],
					files: [
						dr.license_photo_id_0 ? dr.license_photo_id_0 : '',
						dr.license_photo_id_1 ? dr.license_photo_id_1 : ''
					]
				}
			}
			drivers.push(driver);
		});

		let referrers = [];
		state.referrers.forEach((referrer) => {
			referrers.push({
				full_name: referrer.name ? referrer.name : '',
				phone_number: referrer.phone ? trimPhone(referrer.phone) : '',
				position: referrer.job ? referrer.job : ''
			});
		});

		let is_draft_total = is_draft !== undefined ? is_draft : state.draft || false;

		let form = {
			id: state.id ,
			draft: is_draft_total,
			TIN: state.tin ? state.tin : '',
			type: state.tin_type === TIN_SAM ? 'SELF_EMPLOYED' : 'INDIVIDUAL',
			multiple_car_owner: state.owner_type === MULTIPLE_CARS_OWNER,
			banking: {
				account_number: state.bank && state.bank.account ? state.bank.account.replace(/_/g, '') : '',
				BIK: state.bank && state.bank.bic ? state.bank.bic.replace(/_/g, '') : '',
				correction_number: state.bank ? state.bank.corr : '',
				bank: {
					BIK: state.bank && state.bank.bic ? state.bank.bic.replace(/_/g, '') : '',
					name: state.bank ? state.bank.name : '',
					correction_number: state.bank ? state.bank.corr : '',
					TIN: state.bank ? state.bank.inn : '',
					KPP: state.bank ? state.bank.kpp : '',
					OGRN: '',
					adress: state.bank && state.bank.address ? state.bank.address.value : ''
				}
			},
			person: {
				full_name: state.owner_type === MULTIPLE_CARS_OWNER ?
					(owner ? owner.name : '') : (state.drivers[0] ? state.drivers[0].name : ''),
				phone_number: state.owner_type === MULTIPLE_CARS_OWNER ?
					(owner ? packPhones(owner.phones) : packPhones([phone])) :
					(state.drivers[0] ? packPhones(state.drivers[0].phones) : packPhones([phone])),
				email: state.owner_type === MULTIPLE_CARS_OWNER ?
					(owner ? owner.email : '') : (state.drivers[0] ? state.drivers[0].email : '')
			},
			passport: {
				full_name: state.owner_type === MULTIPLE_CARS_OWNER ?
					(owner ? owner.name : '') : (state.drivers[0] ? state.drivers[0].name : ''),
				birth_date: state.owner_type === MULTIPLE_CARS_OWNER ?
					(owner ? convertDate(owner.birthdate) : '') :
					(state.drivers[0] ? convertDate(state.drivers[0].birthdate) : ''),
				series: state.owner_type === MULTIPLE_CARS_OWNER ?
					(owner && owner.passport_number ?
					owner.passport_number.replace(/ /g, '').slice(0, 4).replace(/_/g, '') : '') :
					(state.drivers[0] && state.drivers[0].passport_number ?
					state.drivers[0].passport_number.replace(/ /g, '').slice(0, 4).replace(/_/g, '') : ''),
				number: state.owner_type === MULTIPLE_CARS_OWNER ?
					(owner && owner.passport_number ?
					owner.passport_number.replace(/ /g, '').slice(4).replace(/_/g, '') : '') :
					(state.drivers[0] && state.drivers[0].passport_number ?
					state.drivers[0].passport_number.replace(/ /g, '').slice(4).replace(/_/g, '') : ''),
				issued_by: state.owner_type === MULTIPLE_CARS_OWNER ?
					(owner ? owner.passport_issued_by : '') :
					(state.drivers[0] ? state.drivers[0].passport_issued_by : ''),
				department_code: '',
				issue_date: state.owner_type === MULTIPLE_CARS_OWNER ?
					(owner ? convertDate(owner.passport_issue_date) : '') :
					(state.drivers[0] ? convertDate(state.drivers[0].passport_issue_date) : ''),
				adress: '',
				real_adress: state.owner_type === MULTIPLE_CARS_OWNER ?
					(owner && owner.address ? owner.address.value : '') :
					(state.drivers[0] && state.drivers[0].address ? state.drivers[0].address.value : ''),
				files: state.owner_type === MULTIPLE_CARS_OWNER ?
					[
						owner && owner.passport_photo_id_0 ? owner.passport_photo_id_0 : '',
						owner && owner.passport_photo_id_1 ? owner.passport_photo_id_1 : '',
						owner && owner.passport_photo_id_2 ? owner.passport_photo_id_2 : ''
					] :
					[
						state.drivers[0] && state.drivers[0].passport_photo_id_0 ? state.drivers[0].passport_photo_id_0 : '',
						state.drivers[0] && state.drivers[0].passport_photo_id_1 ? state.drivers[0].passport_photo_id_1 : '',
						state.drivers[0] && state.drivers[0].passport_photo_id_2 ? state.drivers[0].passport_photo_id_2 : ''
					]
			},
			cars,
			drivers,
			recommendations: referrers,
			accept_agreement: true,
			date: is_draft_total ? getDate(): null
		};
		// if (state.id !== '') form.id = state.id;

		setChanged(false);
		setNeedsSave(false);
		response = await driversAPI.postData([form]);
		setSendingData(false);
		if ((response.status === 200) && response.data && response.data.length > 0) {
			if (response.data[0].status === 'error') {
				console.log(response.data[0].errormessage);
				if (!state.draft) {
					setMessage(response.data[0].errormessage);
					setTimeout(setMessage, 5000, '');
				}
			} else {
				if (state.is_edit_user && !state.draft) {
					return ;
				}
				if (state.draft) {
					if (!state.id || state.id === '') {
						cookiesAPI.deleteCookie('id');
						cookiesAPI.setCookie('id', response.data[0].id, 1);
						setId(response.data[0].id);
					}
				} else {
					cookiesAPI.deleteCookie('id');
					history.push('/finish');
				}
			}
		} else {
			console.log('Error');
			if (!state.draft) {
				setMessage('Неизвестная ошибка');
				setTimeout(setMessage, 5000, '');
			}
		}
	}

	if (state.is_loading_data) return (
		<div className='loading_form'>
			<div className='message'><span className='version'>v 1.6.6</span> Подождите идет загрузка данных...</div>
			<div className='overlay'/>
		</div>
	)
	return (

		<form className="register_form">
			{message !== '' &&
				<Message message={message} />
			}
			<div className="register_heading">
				<h1 className="register_title">Регистрация партнера</h1>
			</div>
			<div className="partner_header">
				<section className="partner_type_container">
					<div className={'partner_type_button' +
						(state.owner_type === MULTIPLE_CARS_OWNER ? ' partner_type_button-selected' : '')}
						onClick={() => {
							if (state.show_bank_form || state.show_driver_form ||
								state.show_vehicle_form || state.show_referrer_form) return;
							if (state.tin_type !== TIN_IP) {
								if (tinSAM.current) tinSAM.current.value = '';
								setTin(TIN_IP, '');
							}
							setOwnerType(MULTIPLE_CARS_OWNER);
							setChanged(true);
						}}>
						<div className="partner_type_button_text">Владелец нескольких авто</div>
					</div>
					<div className={'partner_type_button' +
						(state.owner_type === SINGLE_CAR_OWNER ? ' partner_type_button-selected' : '')}
						onClick={() => {
							if (state.show_bank_form || state.show_driver_form ||
								state.show_vehicle_form || state.show_referrer_form) return;
							if (state.owner_type === MULTIPLE_CARS_OWNER) {
								if (tinIP.current) tinIP.current.setInputValue('');
								setTin('', '');
							}
							setOwnerType(SINGLE_CAR_OWNER);
							setChanged(true);
						}}>
						<div className="partner_type_button_text">Водитель с личным авто</div>
					</div>
				</section>
				{state.owner_type !== '' &&
					<section className="partner_tin_container">
						{!state.later &&
							<div className="partner_tin">
								{state.owner_type === MULTIPLE_CARS_OWNER &&
									<label htmlFor="partner_tin" className="partner_tin_label">ИНН</label>
								}
								{state.owner_type === SINGLE_CAR_OWNER &&
									<div className="partner_radio">
										{(state.tin_type !== TIN_SAM || state.tin === '') &&
											<span onClick={() => {
												if (state.show_bank_form || state.show_driver_form ||
													state.show_vehicle_form || state.show_referrer_form) return;
												if (state.tin_type !== TIN_IP && tinSAM.current) {
													tinSAM.current.value = '';
													setTin(TIN_IP, '');
													setTinVerifyStatus('');
													setChanged(true);
												}
											}}>
												<Radio checked={state.tin_type === TIN_IP} />
												<span className="partner_radio_label">ИП</span>
											</span>
										}
										<span onClick={() => {
											if (state.show_bank_form || state.show_driver_form ||
												state.show_vehicle_form || state.show_referrer_form) return;
											if (state.tin_type !== TIN_SAM && tinIP.current) {
												tinIP.current.setInputValue('');
												setTin(TIN_SAM, '');
												setTinVerifyStatus('');
												setChanged(true);
											}
										}}>
											<Radio checked={state.tin_type === TIN_SAM} />
											<span className="partner_radio_label">Самозанятый</span>
										</span>
									</div>
								}
								<PartySuggestions
									ref={tinIP}
									token={token}
									count={5}
									onChange={(suggestion) => {
										if (suggestion.data.inn.length === 12 &&
											suggestion.data.state.status === 'ACTIVE') {
											setTin(TIN_IP, suggestion.data.inn);
											tinIP.current.setInputValue(suggestion.data.inn);
											verifyTin();
											setChanged(true);
										}
									}}
									inputProps={{id: 'partner_tin', className: 'partner_tin_input' + (state.tin !== '' ?
										' partner_tin_input-complete' : '') + (state.owner_type === SINGLE_CAR_OWNER &&
										state.tin_type !== TIN_IP ? ' hidden' : ''), placeholder: 'введите ИНН', type: 'tel',
										onKeyDown: e => {
											if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
												(/[0-9]/.test(e.key) && (e.target.value.length === 12))) {
												e.preventDefault();
											}
										},
										onKeyUp: e => {
											if (e.target.value.length !== 12) {
												setTin(TIN_IP, '');
												setTinVerifyStatus('');
											}
										},
										readOnly: state.show_bank_form || state.show_driver_form ||
											state.show_vehicle_form || state.show_referrer_form
									}}
								/>
								<InputMask mask="999999999999" ref={tinSAM}
									readOnly={state.show_bank_form || state.show_driver_form ||
										state.show_vehicle_form || state.show_referrer_form}
									onChange={(e) => {
									if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
										setTin(TIN_SAM, e.target.value);
										verifyTin();
										setChanged(true);
									} else {
										setTin(TIN_SAM, '');
										setTinVerifyStatus('');
									}
								}}>
									{(inputProps) => <input {...inputProps} className={'partner_tin_input' +
										(state.tin !== '' ? ' partner_tin_input-complete' : '') +
										(state.tin_type !== TIN_SAM ? ' hidden' : '')}
										placeholder="введите ИНН" type="tel" />}
								</InputMask>
							</div>
						}
						{(state.owner_type === MULTIPLE_CARS_OWNER || state.tin_type === TIN_IP ||
							(state.tin_type === TIN_SAM && state.tin === '')) &&
							<table className="partner_tin_later" onClick={() => {
								if (state.show_bank_form || state.show_driver_form ||
									state.show_vehicle_form || state.show_referrer_form) return;
								if (!state.later) {
									setTin(state.tin_type, '');
									setTinVerifyStatus('');
								}
								setLater(!state.later);
							}}>
								<tbody>
									<tr>
										<td className="partner_tin_later_checkbox">
											<Checkbox checked={state.later} />
										</td>
										<td className="partner_tin_later_text">
											Пока нет возможности предоставить данные для оплаты, предоставлю позже
										</td>
									</tr>
								</tbody>
							</table>
						}
					</section>
				}
			</div>
			<div className="partner_body">
				{state.tin_verify_status === 'verifying' &&
					<Loader type="Puff" color="#ffb700" height="6rem" width="6rem"
					  style={{'margin': '20% auto 0 auto'}} />
				}
				{(state.tin_verify_status === 'OK' || state.later) && state.tin_type !== '' &&
					<>
						{state.tin_type === TIN_IP && !state.later &&
							<div className="partner_tab_container">
								<div className={'partner_tab' + (state.bank && state.bank.complete ?
									' partner_tab-complete' : '')}
									onClick={() => {
										showDriverForm(false, false);
										showVehicleForm(false);
										showReferrerForm(false);
										loadBankData();
										showBankForm(true);
									}}
								>
									<div className="partner_tab_title">Реквизиты</div>
									{state.bank && state.bank.complete &&
										<>
											<div className="partner_tab_text">{state.bank?.name || ''}</div>
											<div className="partner_tab_text">
												Расчетный счет: {state.bank?.account || ''}
											</div>
											<div className="partner_tab_text">
												Юр. адрес банка: {state.bank?.address?.value || ''}
											</div>
										</>
									}
								</div>
								{state.show_bank_form && <BankContainer /> }
							</div>
						}
						{state.owner_type === MULTIPLE_CARS_OWNER &&
							<div className="partner_tab_container">
								<div className={'partner_tab' + (state.tin_type === TIN_IP && !state.later ?
									' partner_tab-right' : '') + (state.owner ? ' partner_tab-complete' : '')}
									onClick={() => {
										showBankForm(false);
										showVehicleForm(false);
										showReferrerForm(false);
										loadOwnerData();
										showDriverForm(true, true);
									}}
								>
									<div className={'partner_tab_title' + (state.owner ? ' desktop_only' : '')}>
										Владелец
									</div>
									{state.owner &&
										<>
											<div className="partner_tab_text partner_tab_text_title">
												{state.owner.name}
											</div>
											<div className="partner_tab_text">{state.owner.phones[0]}</div>
											<div className="partner_tab_text">{state.owner.email}</div>
										</>
									}
								</div>
								{state.show_driver_form && state.is_owner && <DriverContainer /> }
							</div>
						}
						<div className="partner_tab_container partner_tab_short">
							{state.vehicles.length > 0 &&
								state.vehicles.map((vehicle, index) => (
									<>
										{(state.owner_type === MULTIPLE_CARS_OWNER || index === 0) &&
											<div key={'vehicle_' + index} className="partner_item">
												<div className={'partner_tab partner_tab-complete' +
													(state.tin_type === TIN_IP && !state.later ?
													(state.owner_type === SINGLE_CAR_OWNER ? ' partner_tab-right' : '') :
													(state.owner_type === SINGLE_CAR_OWNER ? '' : ' partner_tab-right'))}
													onClick={(e) => {
														e.preventDefault();
														e.stopPropagation();
														if (cancelClick) {
															setCancelClick(false);
														} else {
															showBankForm(false);
															showDriverForm(false, false);
															showReferrerForm(false);
															loadVehicleData(index, true);
															setVehicleIndex(index);
															showVehicleForm(true);
														}
													}}
												>
													<div className="partner_tab_title desktop_only">
														{state.owner_type === MULTIPLE_CARS_OWNER &&
															state.vehicles.length === 0 ? 'Новое ТС' : 'Данные ТС'}
													</div>
													<div className="partner_tab_text partner_tab_text_title">
														{state.vehicles[index].license_plate
															.replace('_', '')
															.replace(/ /g, '')
														}
													</div>
													<div className={'partner_tab_text' +
														(state.owner_type === MULTIPLE_CARS_OWNER &&
														!state.vehicles[index].driver && error ? ' error' : '')}>
														Водитель: {state.vehicles[index].driver ?
															state.vehicles[index].driver.name :
															<span className="partner_tab_text partner_tab_text_link"
																onMouseDown={(e) => {
																	e.preventDefault();
																	e.stopPropagation();
																}}
																onClick={(e) => {
																	e.preventDefault();
																	e.stopPropagation();
																	if (state.show_bank_form || state.show_driver_form ||
																		state.show_vehicle_form || state.show_referrer_form) return;
																	setCancelClick(true);
																	setVehicleIndex(index);
																	showDriverSelect(true);
																	setError(false);
																}}
															>добавить водителя
															</span>
														}
														{driverSelect && vehicleIndex === index &&
															<DriverSelectContainer onSelect={(driver) => {
																if (driver) {
																	if (driver.name !== '') {
																		state.vehicles[index].driver = driver;
																		setChanged(true);
																	} else {
																		loadDriverData(state.drivers.length, false);
																		setDriverIndex(state.drivers.length);
																		showDriverForm(true, false);
																	}
																}
																showDriverSelect(false);
															}} />
														}
													</div>
													{state.owner_type === MULTIPLE_CARS_OWNER &&
														<>
															<div className="partner_tab_controls">
																<div className="partner_tab_add" onClick={(e) => {
																	e.stopPropagation();
																	if (state.show_bank_form || state.show_driver_form ||
																		state.show_vehicle_form || state.show_referrer_form) return;
																	removeVehicle(index);
																	setChanged(true);
																}}>&ndash;</div>
																{index === state.vehicles.length - 1 &&
																	<div className="partner_tab_add" onClick={(e) => {
																		e.stopPropagation();
																		showBankForm(false);
																		showDriverForm(false, false);
																		showReferrerForm(false);
																		loadVehicleData(state.vehicles.length, false);
																		setVehicleIndex(state.vehicles.length);
																		showVehicleForm(true);
																		setChanged(true);
																	}}>+</div>
																}
															</div>
															<div className="partner_tab_remove mobile_only" onClick={(e) => {
																e.stopPropagation();
																removeVehicle(index);
																setChanged(true);
															}}>&ndash;</div>
														</>
													}
												</div>
												{state.show_vehicle_form && (vehicleIndex === index ||
													vehicleIndex === state.vehicles.length) &&
													<VehicleContainer index={vehicleIndex}
														singleCar={state.owner_type === SINGLE_CAR_OWNER}
													/>
												}
											</div>
										}
									</>
								))
							}
							{(state.owner_type === MULTIPLE_CARS_OWNER || state.vehicles.length === 0) &&
								<div className={state.vehicles.length > 0 ? 'mobile_only' : ''}
									style={{marginTop: state.vehicles.length > 0 ? '1.67rem' : '0'}}>
									<div className={'partner_tab' + (state.tin_type === TIN_IP && !state.later ?
										(state.owner_type === SINGLE_CAR_OWNER ? ' partner_tab-right' : '') :
										(state.owner_type === SINGLE_CAR_OWNER ? '' : ' partner_tab-right'))}
										onClick={() => {
											showBankForm(false);
											showDriverForm(false,false);
											showReferrerForm(false);
											loadVehicleData(state.vehicles.length, false);
											setVehicleIndex(state.vehicles.length);
											showVehicleForm(true);
											setChanged(true);
										}}
									>
										<div className="partner_tab_title">
											{state.owner_type === MULTIPLE_CARS_OWNER ? 'Новое ТС' : 'Данные ТС'}
										</div>
									</div>
									{state.show_vehicle_form &&
										<VehicleContainer index={vehicleIndex}
											singleCar={state.owner_type === SINGLE_CAR_OWNER}
										/>
									}
								</div>
							}
						</div>
						<div className="partner_tab_container partner_tab_short">
							{state.drivers.length > 0 &&
								state.drivers.map((driver, index) => (
									<>
										{(state.owner_type === MULTIPLE_CARS_OWNER || index === 0) &&
											<div key={'driver_' + index} className="partner_item">
												<div className={'partner_tab partner_tab-complete' +
													(state.tin_type === TIN_IP && !state.later ?
													(state.owner_type === SINGLE_CAR_OWNER ? '' : ' partner_tab-right') :
													(state.owner_type === SINGLE_CAR_OWNER ? ' partner_tab-right' : ''))}
													onClick={() => {
														showBankForm(false);
														showVehicleForm(false);
														showReferrerForm(false);
														loadDriverData(index, true);
														setDriverIndex(index);
														showDriverForm(true, false);
													}}
												>
													<div className="partner_tab_title desktop_only">Водитель</div>
													<div className="partner_tab_text partner_tab_text_title">
														{state.drivers[index].name}
													</div>
													<div className="partner_tab_text">
														{state.drivers[index].phones[0]}
													</div>
													<div className="partner_tab_text">
														{state.drivers[index].email}
													</div>
													{state.owner_type === MULTIPLE_CARS_OWNER &&
														<>
															<div className="partner_tab_controls">
																<div className="partner_tab_add" onClick={(e) => {
																	e.stopPropagation();
																	if (state.show_bank_form || state.show_driver_form ||
																		state.show_vehicle_form || state.show_referrer_form) return;
																	removeDriver(index);
																	setChanged(true);
																}}>&ndash;</div>
																{index === state.drivers.length - 1 &&
																	<div className="partner_tab_add" onClick={(e) => {
																		e.stopPropagation();
																		showBankForm(false);
																		showVehicleForm(false);
																		showReferrerForm(false);
																		loadDriverData(state.drivers.length, false);
																		setDriverIndex(state.drivers.length);
																		showDriverForm(true, false);
																		setChanged(true);
																	}}>+</div>
																}
															</div>
															<div className="partner_tab_remove mobile_only" onClick={(e) => {
																e.stopPropagation();
																removeDriver(index);
																setChanged(true);
															}}>&ndash;</div>
														</>
													}
												</div>
												{state.show_driver_form && !state.is_owner && (driverIndex === index ||
													driverIndex === state.drivers.length) &&
													<DriverContainer index={driverIndex} />
												}
											</div>
										}
									</>
								))
							}
							{(state.owner_type === MULTIPLE_CARS_OWNER || state.drivers.length === 0) &&
								<div className={state.drivers.length > 0 ? 'mobile_only' : ''}
									style={{marginTop: state.drivers.length > 0 ? '1.67rem' : '0'}}>
									<div className={'partner_tab' + (state.tin_type === TIN_IP && !state.later ?
										(state.owner_type === SINGLE_CAR_OWNER ? '' : ' partner_tab-right') :
										(state.owner_type === SINGLE_CAR_OWNER ? ' partner_tab-right' : ''))}
										onClick={() => {
											showBankForm(false);
											showVehicleForm(false);
											showReferrerForm(false);
											loadDriverData(state.drivers.length, false);
											setDriverIndex(state.drivers.length);
											showDriverForm(true, false);
											setChanged(true);
										}}
									>
										<div className="partner_tab_title">Водитель</div>
									</div>
									{state.show_driver_form && !state.is_owner &&
										<DriverContainer index={driverIndex} /> }
								</div>
							}
						</div>
						<div className="partner_tab_container">
							<div className={'partner_tab' + (state.referrers.length > 0 ?
								' partner_tab-complete' : '') +
								(state.tin_type === TIN_IP && !state.later ?
								(state.owner_type === SINGLE_CAR_OWNER ? ' partner_tab-right' : '') :
								(state.owner_type === SINGLE_CAR_OWNER ? '' : ' partner_tab-right'))}
								onClick={() => {
									showBankForm(false);
									showVehicleForm(false);
									showDriverForm(false, false);
									loadReferrerData();
									showReferrerForm(true);
								}}
							>
								<div className="partner_tab_title">Вас рекомендуют</div>
								{state.referrers.length > 0 &&
									<>
										<div className="partner_tab_text partner_tab_text-title">
											{state.referrers[0].name}
										</div>
										<div className="partner_tab_text">{state.referrers[0].phone}</div>
										<div className="partner_tab_controls">
											<div className="partner_tab_add" onClick={(e) => {
												e.stopPropagation();
												setReferrerData(null, null, null);
											}}>&ndash;</div>
											<div className="partner_tab_add">+</div>
										</div>
									</>
								}
							</div>
							{state.show_referrer_form && <ReferrerContainer />}
						</div>
					</>
				}
			</div>
			<div className="partner_footer">
				<div className='partner_footer_container'>
				{(state.tin_verify_status === 'OK' || state.later) &&
					<div className={'partner_save' + ((state.tin_type === TIN_IP && !state.later &&
						!state.bank) || (state.owner_type === MULTIPLE_CARS_OWNER && !state.owner) ||
						state.vehicles.length === 0 || (state.drivers.length === 0 &&
						((state.owner_type === MULTIPLE_CARS_OWNER && (!state.owner ||
						!state.owner.is_driver)) || state.owner_type === SINGLE_CAR_OWNER)) ?
						' partner_save-inactive' : '')}
						onClick={() => {
							if (state.show_bank_form || state.show_driver_form ||
								state.show_vehicle_form || state.show_referrer_form) return;
							if (sendingData) return;
							if (state.tin_type === TIN_IP && !state.later &&
								(!state.bank || !state.bank.complete)) {
								setMessage('Не заполнены банковские реквизиты');
								setTimeout(setMessage, 5000, '');
								return;
							}
							if (state.owner_type === MULTIPLE_CARS_OWNER &&
								(!state.owner || !state.owner.complete)) {
								setMessage('Не заполнены данные владельца');
								setTimeout(setMessage, 5000, '');
								return;
							}
							if (state.vehicles.length === 0) {
								setMessage(state.owner_type === MULTIPLE_CARS_OWNER ?
									'Должно быть указано хотя бы одно транспортное средство' :
									'Не указано транспортное средство');
								setTimeout(setMessage, 5000, '');
								return;
							}
							let complete = true;
							state.vehicles.forEach((vehicle) => {
								if (!vehicle.complete) complete = false;
							});
							if (!complete) {
								setMessage('Не заполнены данные транспортного средства');
								setTimeout(setMessage, 5000, '');
								return;
							}
							if (state.owner_type === MULTIPLE_CARS_OWNER) {
								let driverError = false;
								state.vehicles.forEach((vehicle) => {
									if (!vehicle.driver) driverError = true;
								});
								if (driverError) {
									setMessage('Не указан водитель транспортного средства');
									setError(true);
									setTimeout(setMessage, 5000, '');
									return;
								}
							}
							if (state.drivers.length === 0 && !state.owner.is_driver) {
								setMessage(state.owner_type === MULTIPLE_CARS_OWNER ?
									'Должен быть указан хотя бы один водитель' :
									'Не указан водитель');
								setTimeout(setMessage, 5000, '');
								return;
							}
							if (state.owner_type === MULTIPLE_CARS_OWNER) {
								complete = true;
								state.drivers.forEach((driver) => {
									if (!driver.complete) complete = false;
								});
								if (!complete) {
									setMessage('Не заполнены данные водителя');
									setTimeout(setMessage, 5000, '');
									return;
								}
							}
							dispatch(setDraft(false));
							setSendingData(true);
							sendData(state, false);
						}}>
						{sendingData ?
							<Loader type="Puff" color="#ffffff" height="2rem" width="2rem" />
						:
								<div className="partner_save_text">Сохранить</div>
						}

					</div>

				}
				{state.is_edit_user && <div className='partner_save partner_exit' onClick={()=>{
					cookiesAPI.deleteCookie('id');
					history.push('/phone');
				}}>  Выйти </div>}
				</div>
			</div>
		</form>
	);
}

export default Partner;
