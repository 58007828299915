import React from "react";
import Partner from "./Partner";
import { connect } from "react-redux";
import {
	setOwnerType,
	setTin,
	setLater,
	verifyTinThunk,
	setTinVerifyStatus,
	showBankForm,
	showDriverForm,
	removeDriver,
	showVehicleForm,
	removeVehicle,
	setReferrerData,
	showReferrerForm,
	setChanged,
	setNeedsSave,
	setId,
	loadData,
	setDraft
} from "../../redux/reducers/partnerReducer";
import { loadBankData } from "../../redux/reducers/bankReducer";
import { loadOwnerData } from "../../redux/reducers/ownerReducer";
import { loadDriverData } from "../../redux/reducers/driverReducer";
import { loadVehicleData } from "../../redux/reducers/vehicleReducer";
import { loadReferrerData } from "../../redux/reducers/referrerReducer";

class PartnerContainer extends React.Component {
	render() {
		return (
			<>
				<Partner
					state={this.props.state}
					phone={this.props.phone}
					setOwnerType={this.props.setOwnerType}
					setTin={this.props.setTin}
					setLater={this.props.setLater}
					verifyTin={this.props.verifyTinThunk}
					setTinVerifyStatus={this.props.setTinVerifyStatus}
					showBankForm={this.props.showBankForm}
					showDriverForm={this.props.showDriverForm}
					loadBankData={this.props.loadBankData}
					loadOwnerData={this.props.loadOwnerData}
					loadDriverData={this.props.loadDriverData}
					removeDriver={this.props.removeDriver}
					showVehicleForm={this.props.showVehicleForm}
					loadVehicleData={this.props.loadVehicleData}
					removeVehicle={this.props.removeVehicle}
					setReferrerData={this.props.setReferrerData}
					showReferrerForm={this.props.showReferrerForm}
					loadReferrerData={this.props.loadReferrerData}
					setChanged={this.props.setChanged}
					setNeedsSave={this.props.setNeedsSave}
					setId={this.props.setId}
					loadData={this.props.loadData}
					setDraft={this.props.setDraft}
				/>
			</>
		);
	};
}

let mapStateToProps = (state) => ({
	state: state.partnerReducer,
	phone: state.phoneReducer.phone
});

export default connect(mapStateToProps, {
	setOwnerType,
	setTin,
	setLater,
	verifyTinThunk,
	setTinVerifyStatus,
	showBankForm,
	showDriverForm,
	loadBankData,
	loadOwnerData,
	loadDriverData,
	removeDriver,
	showVehicleForm,
	loadVehicleData,
	removeVehicle,
	setReferrerData,
	showReferrerForm,
	loadReferrerData,
	setChanged,
	setNeedsSave,
	setId,
	loadData,
	setDraft
})(PartnerContainer);
