import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"
import {useDispatch, useSelector} from "react-redux";
import InputMask from "react-input-mask";
import Loader from "react-loader-spinner";
import { Checkbox, Message } from "../Elements";
import ReCAPTCHA from "react-google-recaptcha";
import { recaptchaKey } from "../../deployment";
import {auth, cookiesAPI, phoneAPI} from "../../api/register-api";
import {setCode, setCodeEntered, setCodeVerifyStatus} from "../../redux/reducers/phoneReducer";
import {setIsEditUser} from "../../redux/reducers/partnerReducer";
import SelectProfileWindow from "../SelectProfileWindow/SelectProfileWindow";

const Phone = ({
	state,
	setPhoneEntered,
	setPhone,
	setAgree,
	requestCode,
	setCodeEntered,
	setCode,

	setCodeVerifyStatus,
	setCodeRequestStatus,
	setRecaptcha,
	setId,
	loadData
}) => {
	const history = useHistory();
	const dispatch = useDispatch();
	const [message, setMessage] = useState('');
	const [error, setError] = useState(false);
	const [debugMode, setDebugMode] = useState(false);

	const { partner} = useSelector((state) => ({ partner: state.partnerReducer }));

	const exit = () => {
		setMessage('');
		setCode('');
		setCodeEntered(false);
		setPhone('');
		setPhoneEntered(false);
		setCodeVerifyStatus('');
		setCodeRequestStatus('');
		history.push('/phone');
	}

	const verifyCode = async (code) =>{

		let phone = state.phone.replace('+', '');

		let response = await  auth(phone, code);
		if (!response || !response.data) {
			dispatch(setCodeVerifyStatus(''));
			dispatch(setCodeEntered(false));
			dispatch(setCode(''));
			return false;
		}


		let id;
		if (response.data.status !== 'success') {
			setCodeVerifyStatus('error');
			dispatch(setCodeEntered(false));
			dispatch(setCode(''));
			if (response.data.error_code === 1) {
				setMessage('Неверный код. Попробуйте еще раз или подождите 1 минуту.');
				setTimeout(setMessage, 5000, '');
			}
			history.push(response.data.error_code === 1 ? '/phone' : '/');
			return  false;
		}

		id = response.data.driver_id;
		if (id !== '') {
			dispatch(setId(id));
			loadData('', id).then(() => {
				dispatch(setIsEditUser(true));
				history.push('/');
			});
			cookiesAPI.deleteCookie('id');
			cookiesAPI.setCookie('id', id, 1);
		} else {
			// history.push(response.data.error_code === 1 ? '/phone' : '/');
			// setCodeVerifyStatus('error');
			// dispatch(setCodeEntered(false));
			// dispatch(setCode(''));

			// history.push(response.data.error_code === 1 ? '/phone' : '/');
		}


		// 	if (response.data.valid === true) {
		// 		dispatch(setCodeVerifyStatus('OK'));
		// 	} else {
		// 		dispatch(setCodeVerifyStatus('error'));
		// 		dispatch(setCodeEntered(false));
		// 		dispatch(setCode(''));
		// 	}
		// } else {
		//
		// }
	}

	useEffect(() => {
		let id = cookiesAPI.getCookie('id');
		if (id && id !== '') {
			dispatch(setId(id));
			loadData(id).then(() => {
				dispatch(setIsEditUser(true));
				history.push('/');
			});
			//cookiesAPI.deleteCookie('id');//TODO for testing
		} else {
			exit();
		}
	}, [dispatch, history, loadData, setId]);

	useEffect(() => {
		// switch (state.code_verify_status) {
		// 	case 'OK':
		// 		if (state.agree) {
		// 			auth(state.phone, state.code)
		// 				.then((response) => {
		// 					if (response && response.data) {
		// 						let id;
		// 						switch (response.data.status) {
		// 							case 'success':
		// 								/*setMessage('Данный водитель уже зарегистрирован');
		// 								cookiesAPI.deleteCookie('id');
		// 								setTimeout(exit, 5000);*/
		// 								id = response.data.driver_id;
		// 								if (id !== '') {
		// 									dispatch(setId(id));
		// 									loadData('', id).then(() => {
		// 										history.push('/');
		// 									});
		// 									cookiesAPI.deleteCookie('id');
		// 									cookiesAPI.setCookie('id', id, 1);
		// 								} else {
		// 									history.push('/');
		// 								}
		// 								break;
		// 							case 'error':
		// 								history.push(response.data.error_code === 1 ? '/phone' : '/');
		// 								break;
		// 							default:
		// 								id = response.data.id;
		// 								if (id !== '') {
		// 									dispatch(setId(id));
		// 									loadData(id).then(() => {
		// 										history.push('/');
		// 									});
		// 									cookiesAPI.deleteCookie('id');
		// 									cookiesAPI.setCookie('id', id, 1);
		// 								} else {
		// 									history.push('/');
		// 								}
		// 						}
		// 					}
		// 				});
		// 		}
		// 		return;
		// 	case 'error':
		// 		setMessage('Неверный код. Попробуйте еще раз или подождите 1 минуту.');
		// 		setCodeVerifyStatus('');
		// 		break;
		// 	default:
		// 		return;
		// }
		setTimeout(setMessage, 5000, '');
	}, [dispatch, history, loadData, setId, state.agree, state.code,
		state.code_verify_status, state.phone, setCodeVerifyStatus]);

	useEffect(() => {
		switch (state.code_request_status) {
			case 'exists':
				setMessage('Данный номер уже зарегистрирован в системе');
				setCodeRequestStatus('');
				break;
			case 'error':
				setMessage('Ошибка. Попробуйте еще раз.');
				setCodeRequestStatus('');
				break;
			default:
				return;
		}
		setTimeout(setMessage, 5000, '');
	}, [state.code_request_status, setCodeRequestStatus]);

	useEffect(() => {
		if (window.location.host.indexOf('localhost') >= 0)
			setDebugMode(true);
	}, []);


	if (partner.is_select_profile_visible) return <SelectProfileWindow/>;

	if (partner.is_loading_data) return (
		<div className='loading_form'>
			<div className='message'><span className='version'>v 1.6.6</span> Подождите идет загрузка данных...</div>
			<div className='overlay'/>
		</div>
	)

	return (
		<form className="register_form">
			{message !== '' &&
				<Message message={message} />
			}
			<div className="register_heading">
				<h1 className="register_title">Регистрация</h1>
			</div>
			<div className="register_body">
				<section className={'phone_section' + (state.code_request_status === 'OK' ?
					' phone_section-hidden' : '')}>
					<div className="phone_subtitle">Введите номер<br />для регистрации</div>
					<div className="phone_description">
						Для регистрации в системе нужно зарегистрировать Ваш номер.<br />
						Вам придет СМС с паролем.
					</div>
					<div className={'phone_input_container' + (state.phone_entered ?
						' phone_input_container-complete' : (error ? ' error' : '')) +
						(state.code_requested || state.code_verify_status !== '' ?
						' phone_input_container-disabled' : '')}>
						<InputMask mask="+79999999999" value={state.phone}
							disabled={state.code_requested} onChange={(e) => {
							setPhone(e.target.value);
							setPhoneEntered(e.target.value !== '' &&
								e.target.value.indexOf('_') === -1);
							setCodeRequestStatus('');
							setCodeVerifyStatus('');
							setCode('');
						}}>
							{(inputProps) => <input {...inputProps} className="phone_input"
								placeholder="+79012345678" type="tel" />}
						</InputMask>
					</div>
					<table className={'phone_agree_section' + (!state.agree && error ?
						' error' : '')} onClick={() => {
						if (!state.code_requested && state.code_verify_status === '')
							setAgree(!state.agree);
					}}>
						<tbody>
							<tr>
								<td className="phone_agree_checkbox">
									<Checkbox checked={state.agree} />
								</td>
								<td className="phone_agree_text">
									Я принимаю <a onClick={e => e.stopPropagation()} target="_blank" href="terms-of-use">Условия публичной оферты</a> и <a onClick={e => e.stopPropagation()} target="_blank" href="privacy-policy">Политику конфиденциальности</a>
								</td>
							</tr>
						</tbody>
					</table>
					{state.phone_entered && state.agree && !state.recaptcha_entered && !debugMode &&
						<ReCAPTCHA
							className="captcha"
							sitekey={recaptchaKey}
							onChange={setRecaptcha}
						/>
					}
					{state.code_request_status !== 'OK' &&
						<div className={'phone_button' + (state.phone_entered && state.agree &&
							state.recaptcha_entered ?
							' phone_button-active' : '')} onClick={() => {
							if (!state.phone_entered || !state.agree || (!debugMode && !state.recaptcha_entered)) {
								setError(true);
								return;
							}
							setError(false);
							if (!state.code_requested) {
								// if (state.phone === '+70000000000') {//TODO for tests only
								// 	setCodeVerifyStatus('OK');
								// } else {
									requestCode();
								//}
							}
						}}>
							{state.code_requested &&
								<Loader className="loader" type="Puff" color="#fff" height="3rem" width="3rem" />
							}
							<div className="phone_button_text" style={{'visibility':
								state.code_requested ? 'hidden' : 'visible'}}>Зарегистрироваться</div>
						</div>
					}
				</section>
				{state.code_request_status === 'OK' &&
					<section className="code_section">
						<div className="phone_subtitle">Введите полученный код</div>
						<div className={'phone_input_container_short' + (state.code_entered ?
							' phone_input_container_short-complete' : '')}>
							{!state.code_entered ?
								<InputMask mask="9999" value={state.code} onChange={(e) => {
									setCode(e.target.value);
									if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
										setCodeEntered(true);
										verifyCode(e.target.value);
									} else {
										setCodeEntered(false);
									}
								}}>
									{(inputProps) => <input {...inputProps} className="code_input"
										placeholder="XXXX" type="tel" />}
								</InputMask> :
								<Loader className="loader" type="Puff" color="#ffb700" height="3rem" width="3rem" />
							}
						</div>
					</section>
				}
			</div>
		</form>
	);
}

export default Phone;
