const SET_REFERRER_0 = 'SET_REFERRER_0';
const SET_REFERRER_1 = 'SET_REFERRER_1';
const SET_REFERRER_2 = 'SET_REFERRER_2';
const REMOVE_REFERRER_0 = 'REMOVE_REFERRER_0';
const REMOVE_REFERRER_1 = 'REMOVE_REFERRER_1';
const REMOVE_REFERRER_2 = 'REMOVE_REFERRER_2';
const REMOVE_REFERRERS = 'REMOVE_REFERRERS';
const SET_REFERRERS_COMPLETE = 'SET_REFERRERS_COMPLETE';

let initialState = {
	referrer0: null,
	referrer1: null,
	referrer2: null,
	complete: false
};

const referrerReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_REFERRER_0:
			return {
				...state,
				referrer0: {name: action.name, phone: action.phone, job: action.job}
			}
		case SET_REFERRER_1:
			return {
				...state,
				referrer1: {name: action.name, phone: action.phone, job: action.job}
			}
		case SET_REFERRER_2:
			return {
				...state,
				referrer2: {name: action.name, phone: action.phone, job: action.job}
			}
		case REMOVE_REFERRER_0:
			return {
				...state,
				referrer0: state.referrer1,
				referrer1: state.referrer2,
				referrer2: null
			}
		case REMOVE_REFERRER_1:
			return {
				...state,
				referrer1: state.referrer2,
				referrer2: null
			}
		case REMOVE_REFERRER_2:
			return {
				...state,
				referrer2: null
			}
		case REMOVE_REFERRERS:
			return {
				...state,
				referrer0: null,
				referrer1: null,
				referrer2: null
			}
		case SET_REFERRERS_COMPLETE:
			return {
				...state,
				complete: action.value
			}
		default:
			return state;
	}
};

export const setReferrer = (index, name, phone, job) => (
	{type: index === 0 ? SET_REFERRER_0 : (index === 1 ? SET_REFERRER_1 : SET_REFERRER_2),
	name, phone, job}
);
export const removeReferrer = (index) => (
	{type: index === 0 ? REMOVE_REFERRER_0 : (index === 1 ? REMOVE_REFERRER_1 : REMOVE_REFERRER_2)}
);
const removeReferrers = () => ({type: REMOVE_REFERRERS});
export const setReferrersComplete = (value) => ({type: SET_REFERRERS_COMPLETE, value});

export const loadReferrerData = () => (dispatch, getState) => {
	let referrers = getState().partnerReducer.referrers;
	if (referrers.length > 0) {
		removeReferrers();
		referrers.forEach((referrer, index) => {
			dispatch(setReferrer(index, referrer.name, referrer.phone, referrer.job));
		});
	}
	dispatch(setReferrersComplete(false));
}

export default referrerReducer;
