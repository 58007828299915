const Finish = () => {

	return (
		<div className="finish_container">
			<div className="finish_heading">
				<div className="finish_title">Регистрация</div>
			</div>
			<div className="finish_body">
				<div className="finish_message">Вы успешно зарегистрированы!</div>
				<div className="finish_button" style={{display: 'none'}}
					onClick={() => console.log('Registration successful')}
				>
					<div className="finish_button_text">Готово</div>
				</div>
			</div>
		</div>
	);
}

export default Finish;
