import { sendPhoto } from "../../api/register-api";

const SET_CAR_TYPE = 'CAR_TYPE';
const SET_BODY_OPTION = 'SET_BODY_OPTION';
const SET_CHARACTERISTICS = 'SET_CHARACTERISTICS';
const SET_PASS = 'SET_PASS';
const SET_HYDROBOARD = 'SET_HYDROBOARD';
const SET_RAMP = 'SET_RAMP';
const SET_LENGTH = 'SET_LENGTH';
const SET_WIDTH = 'SET_WIDTH';
const SET_HEIGHT = 'SET_HEIGHT';
const SET_GARAGE = 'SET_GARAGE';
const SET_MEDICAL = 'SET_MEDICAL';
const SET_LOADER = 'SET_LOADER';
const SET_VEHICLE_PHOTO = 'SET_VEHICLE_PHOTO';
const SET_LICENSE_PLATE = 'SET_LICENSE_PLATE';
const SET_VIN = 'SET_VIN';
const SET_BRAND = 'SET_BRAND';
const SET_MODEL = 'SET_MODEL';
const SET_TYPE = 'SET_TYPE';
const SET_YEAR = 'SET_YEAR';
const SET_ECOLOGY = 'SET_ECOLOGY';
const SET_STS_SERIES = 'SET_STS_SERIES';
const SET_STS_NUMBER = 'SET_STS_NUMBER';
const SET_MAX_WEIGHT = 'SET_MAX_WEIGHT';
const SET_LOAD_WEIGHT = 'SET_LOAD_WEIGHT';
const SET_CERTIFICATE_PHOTO = 'SET_CERTIFICATE_PHOTO';
const SET_DRIVER = 'SET_DRIVER';
const CLEAR_VEHICLE_STATE = 'CLEAR_VEHICLE_STATE';
const SET_VEHICLE_CLEAR = 'SET_VEHICLE_CLEAR';
const SET_VEHICLE_COMPLETE = 'SET_VEHICLE_COMPLETE';

const initialState = {
	car_type: '',
	body_option: '',
	characteristics: [],
	pass: '',
	hydroboard: '',
	ramp: '',
	length: '',
	width: '',
	height: '',
	garage: null,
	medical: false,
	loader: false,
	vehicle_photo_id_0: '',
	vehicle_photo_id_1: '',
	vehicle_photo_id_2: '',
	vehicle_photo_0: null,
	vehicle_photo_1: null,
	vehicle_photo_2: null,
	license_plate: '',
	vin: '',
	brand: '',
	model: '',
	type: '',
	year: '',
	ecology: '',
	sts_series: '',
	sts_number: '',
	max_weight: '',
	load_weight: '',
	certificate_photo_id_0: '',
	certificate_photo_id_1: '',
	certificate_photo_0: null,
	certificate_photo_1: null,
	driver: null,
	driver_later: false,
	should_clear: false,
	complete: false
};

const vehicleReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_CAR_TYPE:
			return {
				...state,
				car_type: action.value
			}
		case SET_BODY_OPTION:
			return {
				...state,
				body_option: action.value
			}
		case SET_CHARACTERISTICS:
			return {
				...state,
				characteristics: action.value
			}
		case SET_PASS:
			return {
				...state,
				pass: action.value
			}
		case SET_HYDROBOARD:
			return {
				...state,
				hydroboard: action.value
			}
		case SET_RAMP:
			return {
				...state,
				ramp: action.value
			}
		case SET_LENGTH:
			return {
				...state,
				length: action.value
			}
		case SET_WIDTH:
			return {
				...state,
				width: action.value
			}
		case SET_HEIGHT:
			return {
				...state,
				height: action.value
			}
		case SET_GARAGE:
			return {
				...state,
				garage: action.value
			}
		case SET_MEDICAL:
			return {
				...state,
				medical: action.value
			}
		case SET_LOADER:
			return {
				...state,
				loader: action.value
			}
		case SET_VEHICLE_PHOTO:
			return {
				...state,
				vehicle_photo_0: action.index === 0 ? action.photo : state.vehicle_photo_0,
				vehicle_photo_1: action.index === 1 ? action.photo : state.vehicle_photo_1,
				vehicle_photo_2: action.index === 2 ? action.photo : state.vehicle_photo_2,
				vehicle_photo_id_0: action.index === 0 ? action.id : state.vehicle_photo_id_0,
				vehicle_photo_id_1: action.index === 1 ? action.id : state.vehicle_photo_id_1,
				vehicle_photo_id_2: action.index === 2 ? action.id : state.vehicle_photo_id_2
			}
		case SET_LICENSE_PLATE:
			return {
				...state,
				license_plate: action.value
			}
		case SET_VIN:
			return {
				...state,
				vin: action.value
			}
		case SET_BRAND:
			return {
				...state,
				brand: action.value
			}
		case SET_MODEL:
			return {
				...state,
				model: action.value
			}
		case SET_TYPE:
			return {
				...state,
				type: action.value
			}
		case SET_YEAR:
			return {
				...state,
				year: action.value
			}
		case SET_ECOLOGY:
			return {
				...state,
				ecology: action.value
			}
		case SET_STS_SERIES:
			return {
				...state,
				sts_series: action.value
			}
		case SET_STS_NUMBER:
			return {
				...state,
				sts_number: action.value
			}
		case SET_MAX_WEIGHT:
			return {
				...state,
				max_weight: action.value
			}
		case SET_LOAD_WEIGHT:
			return {
				...state,
				load_weight: action.value
			}
		case SET_CERTIFICATE_PHOTO:
			return {
				...state,
				certificate_photo_0: action.index === 0 ? action.photo : state.certificate_photo_0,
				certificate_photo_1: action.index === 1 ? action.photo : state.certificate_photo_1,
				certificate_photo_id_0: action.index === 0 ? action.id : state.certificate_photo_id_0,
				certificate_photo_id_1: action.index === 1 ? action.id : state.certificate_photo_id_1
			}
		case SET_DRIVER:
			return {
				...state,
				driver: action.driver,
				driver_later: action.later
			}
		case CLEAR_VEHICLE_STATE:
			return initialState;
		case SET_VEHICLE_CLEAR:
			return {
				...state,
				should_clear: action.clear
			}
		case SET_VEHICLE_COMPLETE:
			return {
				...state,
				complete: action.value
			}
		default:
			return state;
	}
};

export const setCarType = (value) => ({type: SET_CAR_TYPE, value});
export const setBodyOption = (value) => ({type: SET_BODY_OPTION, value});
export const setBodyOptionChs = (value) => ({type: SET_CHARACTERISTICS, value});
export const setCarPass = (value) => ({type: SET_PASS, value});
export const setHydroboard = (value) => ({type: SET_HYDROBOARD, value});
export const setRamp = (value) => ({type: SET_RAMP, value});
export const setCarLength = (value) => ({type: SET_LENGTH, value});
export const setCarWidth = (value) => ({type: SET_WIDTH, value});
export const setCarHeight = (value) => ({type: SET_HEIGHT, value});
export const setGarage = (value) => ({type: SET_GARAGE, value});
export const setMedical = (value) => ({type: SET_MEDICAL, value});
export const setLoader = (value) => ({type: SET_LOADER, value});
export const setVehiclePhoto = (index, photo, id) => ({type: SET_VEHICLE_PHOTO, index, photo, id});
export const setLicensePlate = (value) => ({type: SET_LICENSE_PLATE, value});
export const setVin = (value) => ({type: SET_VIN, value});
export const setCarBrand = (value) => ({type: SET_BRAND, value});
export const setCarModel = (value) => ({type: SET_MODEL, value});
export const setVehicleType = (value) => ({type: SET_TYPE, value});
export const setBuildYear = (value) => ({type: SET_YEAR, value});
export const setEcology = (value) => ({type: SET_ECOLOGY, value});
export const setStsSeries = (value) => ({type: SET_STS_SERIES, value});
export const setStsNumber = (value) => ({type: SET_STS_NUMBER, value});
export const setMaxWeight = (value) => ({type: SET_MAX_WEIGHT, value});
export const setLoadWeight = (value) => ({type: SET_LOAD_WEIGHT, value});
export const setCertificatePhoto = (index, photo, id) => ({type: SET_CERTIFICATE_PHOTO, index, photo, id});
export const setDriver = (driver, later) => ({type: SET_DRIVER, driver, later});
export const clearVehicleData = () => ({type: CLEAR_VEHICLE_STATE});
const setVehicleClear = (clear) => ({type: SET_VEHICLE_CLEAR, clear});
export const setVehicleComplete = (value) => ({type: SET_VEHICLE_COMPLETE, value});

export const setVehiclePhotoThunk = (index, photo) => async (dispatch) => {
	const id = await sendPhoto(photo);
	dispatch(setVehiclePhoto(index, photo, id));
}

export const setCertificatePhotoThunk = (index, photo) => async (dispatch) => {
	const id = await sendPhoto(photo);
	dispatch(setCertificatePhoto(index, photo, id));
}

export const validateVehicle = (vehicle) => {
	return vehicle.car_type !== '' &&
		vehicle.body_option !== '' &&
		vehicle.length !== '' &&
		vehicle.width !== '' &&
		vehicle.height !== '' &&
		vehicle.license_plate !== '' &&
		(vehicle.license_plate.indexOf('_') < 0 || vehicle.license_plate.indexOf('_') === 8) &&
		vehicle.vin !== '' && vehicle.vin.indexOf('_') < 0 &&
		vehicle.brand.length >= 3 &&
		vehicle.year !== '' && parseInt(vehicle.year) >= 2000 &&
		parseInt(vehicle.year) <= new Date().getFullYear() &&
		vehicle.sts_series !== '' && vehicle.sts_series.indexOf('_') < 0 &&
		vehicle.sts_number !== '' && vehicle.sts_number.indexOf('_') < 0 &&
		vehicle.max_weight !== '' && parseInt(vehicle.max_weight) > 0 &&
		vehicle.load_weight !== '' && parseInt(vehicle.load_weight) > 0 &&
		vehicle.certificate_photo_0 && vehicle.certificate_photo_1;
}

export const loadVehicleData = (index, clear) => (dispatch, getState) => {
	dispatch(setVehicleClear(clear));
	let vehicles = getState().partnerReducer.vehicles;
	if (vehicles.length === 0) return;
	if (index < vehicles.length) {
		let vehicle = vehicles[index];
		dispatch(setCarType(vehicle.car_type));
		dispatch(setBodyOption(vehicle.body_option));
		dispatch(setBodyOptionChs(vehicle.characteristics));
		dispatch(setCarPass(vehicle.pass));
		//dispatch(setHydroboard(vehicle.hydroboard));
		dispatch(setRamp(vehicle.ramp));
		dispatch(setCarLength(vehicle.length));
		dispatch(setCarWidth(vehicle.width));
		dispatch(setCarHeight(vehicle.height));
		//dispatch(setGarage(vehicle.garage));
		dispatch(setMedical(vehicle.medical));
		dispatch(setLoader(vehicle.loader));
		dispatch(setVehiclePhoto(0, vehicle.vehicle_photo_0, vehicle.vehicle_photo_id_0));
		dispatch(setVehiclePhoto(1, vehicle.vehicle_photo_1, vehicle.vehicle_photo_id_1));
		dispatch(setVehiclePhoto(2, vehicle.vehicle_photo_2, vehicle.vehicle_photo_id_2));
		dispatch(setLicensePlate(vehicle.license_plate));
		dispatch(setVin(vehicle.vin));
		dispatch(setCarBrand(vehicle.brand));
		dispatch(setCarModel(vehicle.model));
		//dispatch(setVehicleType(vehicle.type));
		dispatch(setBuildYear(vehicle.year));
		//dispatch(setEcology(vehicle.ecology));
		dispatch(setStsSeries(vehicle.sts_series));
		dispatch(setStsNumber(vehicle.sts_number));
		dispatch(setMaxWeight(vehicle.max_weight));
		dispatch(setLoadWeight(vehicle.load_weight));
		dispatch(setCertificatePhoto(0, vehicle.certificate_photo_0, vehicle.certificate_photo_id_0));
		dispatch(setCertificatePhoto(1, vehicle.certificate_photo_1, vehicle.certificate_photo_id_1));
		dispatch(setDriver(vehicle.driver, vehicle.driver_later));
		dispatch(setVehicleComplete(validateVehicle(vehicle)));
	} else {
		dispatch(setVehicleComplete(false));
	}
}

export default vehicleReducer;
