import { phoneAPI } from "../../api/register-api";

const SET_PHONE_ENTERED = 'SET_PHONE_ENTERED';
const SET_PHONE = 'SET_PHONE';
const SET_AGREE = 'SET_AGREE';
const SET_CODE_REQUESTED = 'SET_CODE_REQUESTED';
const SET_CODE_REQUEST_STATUS = 'SET_CODE_REQUEST_STATUS';
const SET_CODE_ENTERED = 'SET_CODE_ENTERED';
const SET_CODE = 'SET_CODE';
const SET_CODE_VERIFY_STATUS = 'SET_CODE_VERIFY_STATUS';
const SET_RECAPTCHA_ENTERED = 'SET_RECAPTCHA_ENTERED';

let initialState = {
	phone_entered: false,
	phone: '',
	agree: false,
	code_requested: false,
	code_request_status: '',
	code_entered: false,
	code: '',
	code_verify_status: '',
	recaptcha_entered: false,
};

const phoneReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PHONE_ENTERED:
			return {
				...state,
				phone_entered: action.entered
			}
		case SET_PHONE:
			return {
				...state,
				phone: action.phone
			}
		case SET_AGREE:
			return {
				...state,
				agree: action.agree
			}
		case SET_CODE_REQUESTED:
			return {
				...state,
				code_requested: action.requested
			}
		case SET_CODE_REQUEST_STATUS:
			return {
				...state,
				code_request_status: action.status
			}
		case SET_CODE_ENTERED:
			return {
				...state,
				code_entered: action.entered
			}
		case SET_CODE:
			return {
				...state,
				code: action.code
			}
		case SET_CODE_VERIFY_STATUS:
			return {
				...state,
				code_verify_status: action.status
			}
		case SET_RECAPTCHA_ENTERED:
			return {
				...state,
				recaptcha_entered: action.value
			}

		default:
			return state;
	}
};

export const setPhoneEntered = (entered) => ({type: SET_PHONE_ENTERED, entered});
export const setPhone = (phone) => ({type: SET_PHONE, phone});
export const setAgree = (agree) => ({type: SET_AGREE, agree});
export const setCodeRequested = (requested) => ({type: SET_CODE_REQUESTED, requested});
export const setCodeRequestStatus = (status) => ({type: SET_CODE_REQUEST_STATUS, status});
export const setCodeEntered = (entered) => ({type: SET_CODE_ENTERED, entered});
export const setCode = (code) => ({type: SET_CODE, code});
export const setCodeVerifyStatus = (status) => ({type: SET_CODE_VERIFY_STATUS, status});
export const setRecaptchaEntered = (value) => ({type: SET_RECAPTCHA_ENTERED, value});

const resetAll = (dispatch) => {
	dispatch(setCodeRequestStatus(''));
	dispatch(setCode(''));
	dispatch(setCodeEntered(false));
	dispatch(setCodeVerifyStatus(''));
	dispatch(setRecaptchaEntered(false));
}

export const requestCodeThunk = () => async (dispatch, getState) => {
	let state = getState().phoneReducer;
	let phone = state.phone.replace('+', '');
	if (!state.code_requested) {
		dispatch(setCodeRequested(true));
		dispatch(setCodeRequestStatus(''));
		let response = await phoneAPI.sendSms(phone);
		if (response.data && (response.data.status === 'success')) {
			dispatch(setCodeRequestStatus('OK'));
			setTimeout(resetAll, 60000, dispatch);
		} else if (response.data && (response.data.status === 'exists')) {
			dispatch(setCodeRequestStatus('exists'));
		} else {
			dispatch(setCodeRequestStatus('error'));
		}
		dispatch(setCodeRequested(false));
	}
};

export const verifyCodeThunk = (code) => async (dispatch, getState) => {
	let state = getState().phoneReducer;
	let phone = state.phone.replace('+', '');
	// if (code === "6291") {
	// 	dispatch(setCodeVerifyStatus('OK'));
	// 	return ;
	// }
	let response = await phoneAPI.checkSms(phone, code);
	if (response.data) {
		if (response.data.valid === true) {
			dispatch(setCodeVerifyStatus('OK'));
		} else {
			dispatch(setCodeVerifyStatus('error'));
			dispatch(setCodeEntered(false));
			dispatch(setCode(''));
		}
	} else {
		dispatch(setCodeVerifyStatus(''));
		dispatch(setCodeEntered(false));
		dispatch(setCode(''));
	}
};

export const setRecaptchaThunk = (value) => async (dispatch) => {
	if (value !== null) {
		dispatch(setRecaptchaEntered(true));
	} else {
		dispatch(setRecaptchaEntered(false));
	}
};

export default phoneReducer;
