import React from "react";
import DriverSelect from "./DriverSelect";
import { connect } from "react-redux";

class DriverSelectContainer extends React.Component {
	render() {
		return (
			<>
				<DriverSelect
					state={this.props.state}
					onSelect={this.props.onSelect}
				/>
			</>
		);
	};
}

let mapStateToProps = (state) => ({
	state: state.partnerReducer,
});

export default connect(mapStateToProps, {})(DriverSelectContainer);
