const Radio = ({
	checked
}) => (
	<div className="radio">
		<input type="checkbox" checked={checked} onChange={() => {}} />
		<div className="radio_button"></div>
	</div>
);

export default Radio;
