import React from "react";
import Referrer from "./Referrer";
import { connect } from "react-redux";
import {
	setReferrer,
	removeReferrer,
	setReferrersComplete
} from "../../redux/reducers/referrerReducer";
import {
	showReferrerForm,
	storeReferrerData,
	setChanged,
	setNeedsSave
} from "../../redux/reducers/partnerReducer";

class ReferrerContainer extends React.Component {
	render() {
		return (
			<>
				{this.props.referrerForm &&
					<Referrer
						state={this.props.state}
						setReferrer={this.props.setReferrer}
						removeReferrer={this.props.removeReferrer}
						setReferrersComplete={this.props.setReferrersComplete}
						showReferrerForm={this.props.showReferrerForm}
						storeReferrerData={this.props.storeReferrerData}
						setChanged={this.props.setChanged}
						setNeedsSave={this.props.setNeedsSave}
					/>
				}
			</>
		);
	};
}

let mapStateToProps = (state) => ({
	state: state.referrerReducer,
	referrerForm: state.partnerReducer.show_referrer_form
});

export default connect(mapStateToProps, {
	setReferrer,
	removeReferrer,
	setReferrersComplete,
	showReferrerForm,
	storeReferrerData,
	setChanged,
	setNeedsSave
})(ReferrerContainer);
