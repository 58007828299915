import React from "react";

const DriverSelect = ({
	state,
	onSelect
}) => {

	return (
		<div className="driver_select_container">
			<div className="driver_select_heading">
				<div className="driver_select_title desktop_only">Регистрация</div>
				<div className="driver_select_title mobile_only">Мои водители</div>
			</div>
			<div className="driver_select_body">
				<div className="driver_select_subtitle">Мои водители</div>
				<div className="driver_select_tabs">
					{state.owner && state.owner.is_driver &&
						<div className="driver_select_tab driver_select_tab-complete"
							onClick={() => onSelect(state.owner)}>
							<div className="driver_select_tab_title desktop_only">Данные водителя</div>
							<div className="driver_select_tab_text driver_select_tab_text_title">
								{state.owner.name}
							</div>
							<div className="driver_select_tab_text">{state.owner.phones[0]}</div>
							<div className="driver_select_tab_text">{state.owner.email}</div>
						</div>
					}
					{state.drivers.length > 0 && state.drivers.map((driver, index) => (
						<div key={'driver_select_' + index}
							className="driver_select_tab driver_select_tab-complete"
								onClick={() => onSelect(driver)}>
							<div className="driver_select_tab_title desktop_only">Данные водителя</div>
							<div className="driver_select_tab_text driver_select_tab_text_title">
								{state.drivers[index].name}
							</div>
							<div className="driver_select_tab_text">{state.drivers[index].phones[0]}</div>
							<div className="driver_select_tab_text">{state.drivers[index].email}</div>
						</div>
					))}
					<div className="driver_select_tab mobile_only" onClick={() => onSelect({name: ''})}>
						<div className="driver_select_tab_title">Водитель</div>
					</div>
				</div>
				<div className="driver_select_add desktop_only" onClick={() => onSelect({name: ''})}>
					добавить еще водителя
				</div>
			</div>
			<div className="driver_select_back" onClick={() => onSelect(null)}>Назад</div>
		</div>
	);
}

export default DriverSelect;
