import React from "react";
import Driver from "./Driver";
import { connect } from "react-redux";
import {
	setOwnerName,
	addOwnerPhone,
	removeOwnerPhone,
	setOwnerEmail,
	setOwnerBirthdate,
	setOwnerPassportNumber,
	setOwnerPassportIssuedBy,
	setOwnerPassportIssueDate,
	setOwnerPassportDepartment,
	setOwnerPassportRegistration,
	setOwnerAddress,
	setOwnerPassportPhotoThunk,
	setIsDriver,
	setOwnerLicenseNumber,
	setOwnerLicenseIssueDate,
	setOwnerLicenseValidity,
	setOwnerLicenseIssuedBy,
	setOwnerLicenseCountry,
	setOwnerLicenseCategories,
	setOwnerLicensePhotoThunk,
	setOwnerComplete
} from "../../redux/reducers/ownerReducer";
import {
	setDriverName,
	addDriverPhone,
	removeDriverPhone,
	setDriverEmail,
	setDriverBirthdate,
	setDriverPassportNumber,
	setDriverPassportIssuedBy,
	setDriverPassportIssueDate,
	setDriverPassportDepartment,
	setDriverPassportRegistration,
	setDriverAddress,
	setDriverPassportPhotoThunk,
	setDriverLicenseNumber,
	setDriverLicenseIssueDate,
	setDriverLicenseValidity,
	setDriverLicenseIssuedBy,
	setDriverLicenseCountry,
	setDriverLicenseCategories,
	setDriverLicensePhotoThunk,
	clearDriverData,
	setDriverComplete
} from "../../redux/reducers/driverReducer";
import { setDriver } from "../../redux/reducers/vehicleReducer";
import {
	showDriverForm,
	storeOwnerData,
	storeDriverData,
	setVehicleData,
	setChanged,
	setNeedsSave
} from "../../redux/reducers/partnerReducer";

class DriverContainer extends React.Component {
	render() {
		return (
			<>
				{this.props.driverForm &&
					<Driver
						driverIndex={this.props.index}
						state={this.props.state}
						partner={this.props.partner}
						isOwner={this.props.isOwner}
						setOwnerName={this.props.setOwnerName}
						addOwnerPhone={this.props.addOwnerPhone}
						removeOwnerPhone={this.props.removeOwnerPhone}
						setOwnerEmail={this.props.setOwnerEmail}
						setOwnerBirthdate={this.props.setOwnerBirthdate}
						setOwnerPassportNumber={this.props.setOwnerPassportNumber}
						setOwnerPassportIssuedBy={this.props.setOwnerPassportIssuedBy}
						setOwnerPassportIssueDate={this.props.setOwnerPassportIssueDate}
						setOwnerPassportDepartment={this.props.setOwnerPassportDepartment}
						setOwnerPassportRegistration={this.props.setOwnerPassportRegistration}
						setOwnerAddress={this.props.setOwnerAddress}
						setOwnerPassportPhoto={this.props.setOwnerPassportPhotoThunk}
						setIsDriver={this.props.setIsDriver}
						setOwnerLicenseNumber={this.props.setOwnerLicenseNumber}
						setOwnerLicenseIssueDate={this.props.setOwnerLicenseIssueDate}
						setOwnerLicenseValidity={this.props.setOwnerLicenseValidity}
						setOwnerLicenseIssuedBy={this.props.setOwnerLicenseIssuedBy}
						setOwnerLicenseCountry={this.props.setOwnerLicenseCountry}
						setOwnerLicenseCategories={this.props.setOwnerLicenseCategories}
						setOwnerLicensePhoto={this.props.setOwnerLicensePhotoThunk}
						setOwnerComplete={this.props.setOwnerComplete}
						setDriverName={this.props.setDriverName}
						addDriverPhone={this.props.addDriverPhone}
						removeDriverPhone={this.props.removeDriverPhone}
						setDriverEmail={this.props.setDriverEmail}
						setDriverBirthdate={this.props.setDriverBirthdate}
						setDriverPassportNumber={this.props.setDriverPassportNumber}
						setDriverPassportIssuedBy={this.props.setDriverPassportIssuedBy}
						setDriverPassportIssueDate={this.props.setDriverPassportIssueDate}
						setDriverPassportDepartment={this.props.setDriverPassportDepartment}
						setDriverPassportRegistration={this.props.setDriverPassportRegistration}
						setDriverAddress={this.props.setDriverAddress}
						setDriverPassportPhoto={this.props.setDriverPassportPhotoThunk}
						setDriverLicenseNumber={this.props.setDriverLicenseNumber}
						setDriverLicenseIssueDate={this.props.setDriverLicenseIssueDate}
						setDriverLicenseValidity={this.props.setDriverLicenseValidity}
						setDriverLicenseIssuedBy={this.props.setDriverLicenseIssuedBy}
						setDriverLicenseCountry={this.props.setDriverLicenseCountry}
						setDriverLicenseCategories={this.props.setDriverLicenseCategories}
						setDriverLicensePhoto={this.props.setDriverLicensePhotoThunk}
						clearDriverData={this.props.clearDriverData}
						setDriverComplete={this.props.setDriverComplete}
						setDriver={this.props.setDriver}
						showDriverForm={this.props.showDriverForm}
						storeOwnerData={this.props.storeOwnerData}
						storeDriverData={this.props.storeDriverData}
						setVehicleData={this.props.setVehicleData}
						setChanged={this.props.setChanged}
						setNeedsSave={this.props.setNeedsSave}
					/>
				}
			</>
		);
	};
}

let mapStateToProps = (state) => ({
	state: state.partnerReducer.is_owner ? state.ownerReducer : state.driverReducer,
	partner: state.partnerReducer,
	isOwner: state.partnerReducer.is_owner,
	driverForm: state.partnerReducer.show_driver_form
});

export default connect(mapStateToProps, {
	setOwnerName,
	addOwnerPhone,
	removeOwnerPhone,
	setOwnerEmail,
	setOwnerBirthdate,
	setOwnerPassportNumber,
	setOwnerPassportIssuedBy,
	setOwnerPassportIssueDate,
	setOwnerPassportDepartment,
	setOwnerPassportRegistration,
	setOwnerAddress,
	setOwnerPassportPhotoThunk,
	setIsDriver,
	setOwnerLicenseNumber,
	setOwnerLicenseIssueDate,
	setOwnerLicenseValidity,
	setOwnerLicenseIssuedBy,
	setOwnerLicenseCountry,
	setOwnerLicenseCategories,
	setOwnerLicensePhotoThunk,
	setOwnerComplete,
	setDriverName,
	addDriverPhone,
	removeDriverPhone,
	setDriverEmail,
	setDriverBirthdate,
	setDriverPassportNumber,
	setDriverPassportIssuedBy,
	setDriverPassportIssueDate,
	setDriverPassportDepartment,
	setDriverPassportRegistration,
	setDriverAddress,
	setDriverPassportPhotoThunk,
	setDriverLicenseNumber,
	setDriverLicenseIssueDate,
	setDriverLicenseValidity,
	setDriverLicenseIssuedBy,
	setDriverLicenseCountry,
	setDriverLicenseCategories,
	setDriverLicensePhotoThunk,
	clearDriverData,
	setDriverComplete,
	setDriver,
	showDriverForm,
	storeOwnerData,
	storeDriverData,
	setVehicleData,
	setChanged,
	setNeedsSave
})(DriverContainer);
