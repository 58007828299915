import React, { useRef, useState, useEffect } from "react";

import {useDispatch, useSelector} from "react-redux";
import './SelectProfileWindow.css'
import {setVisibleSelectProfileWindow} from "../../redux/reducers/partnerReducer";


const SelectProfileWindow = () => {
	const dispatch = useDispatch();
	const { partner} = useSelector((state) => ({ partner: state.partnerReducer }));
	const [selected, setSelected] = useState(-1);

	const onSelect = () =>{
		dispatch(setVisibleSelectProfileWindow(undefined));
		document.dispatchEvent( new Event(selected === 0 ? 'SelectProfileActive' : 'SelectProfileDraft'));
	}

	let data  = partner.is_select_profile_visible;
	return (
		<div className='select_profile_form'>
			<div className='select_profile_window'>
				<div className='title'>Внимание обнаружено несколько анкет</div>
				{data.length >0 && <div className={'field'+ (selected === 0 ? ' active' : '') } onClick={()=>setSelected(0)}>{data[0].person.full_name} (активная) <span>последние изменения от {data[0].date.replace('T', ' ')}</span></div>}
				{data.length >1 && <div className={'field'+ (selected === 1 ? ' active' : '') } onClick={()=>setSelected(1)} >{data[1].person.full_name} (черновик) <span>последние изменения от {data[1].date.replace('T', ' ')}</span></div>}
				<div className='button_list'>
					<div className={'button ' + (selected > -1 ? ' active' : '')} onClick={onSelect}>Выбрать</div>
				</div>
			</div>
			<div className='overlay'/>
		</div>
	);
}

export default SelectProfileWindow;
