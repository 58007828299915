import React from "react";
import Vehicle from "./Vehicle";
import { connect } from "react-redux";
import {
	setCarType,
	setBodyOption,
	setBodyOptionChs,
	setCarPass,
	setHydroboard,
	setRamp,
	setCarLength,
	setCarWidth,
	setCarHeight,
	setGarage,
	setMedical,
	setLoader,
	setVehiclePhotoThunk,
	setLicensePlate,
	setVin,
	setCarBrand,
	setCarModel,
	setVehicleType,
	setBuildYear,
	setEcology,
	setStsSeries,
	setStsNumber,
	setMaxWeight,
	setLoadWeight,
	setCertificatePhotoThunk,
	setDriver,
	clearVehicleData,
	setVehicleComplete
} from "../../redux/reducers/vehicleReducer";
import {
	showVehicleForm,
	storeVehicleData,
	setChanged,
	setNeedsSave
} from "../../redux/reducers/partnerReducer";

class VehicleContainer extends React.Component {
	render() {
		return (
			<>
				{this.props.vehicleForm &&
					<Vehicle
						vehicleIndex={this.props.index}
						state={this.props.state}
						owner={this.props.owner}
						drivers={this.props.drivers}
						singleCar={this.props.singleCar}
						setCarType={this.props.setCarType}
						setBodyOption={this.props.setBodyOption}
						setBodyOptionChs={this.props.setBodyOptionChs}
						setCarPass={this.props.setCarPass}
						setHydroboard={this.props.setHydroboard}
						setRamp={this.props.setRamp}
						setCarLength={this.props.setCarLength}
						setCarWidth={this.props.setCarWidth}
						setCarHeight={this.props.setCarHeight}
						setGarage={this.props.setGarage}
						setMedical={this.props.setMedical}
						setLoader={this.props.setLoader}
						setVehiclePhoto={this.props.setVehiclePhotoThunk}
						setLicensePlate={this.props.setLicensePlate}
						setVin={this.props.setVin}
						setCarBrand={this.props.setCarBrand}
						setCarModel={this.props.setCarModel}
						setVehicleType={this.props.setVehicleType}
						setBuildYear={this.props.setBuildYear}
						setEcology={this.props.setEcology}
						setStsSeries={this.props.setStsSeries}
						setStsNumber={this.props.setStsNumber}
						setMaxWeight={this.props.setMaxWeight}
						setLoadWeight={this.props.setLoadWeight}
						setCertificatePhoto={this.props.setCertificatePhotoThunk}
						setDriver={this.props.setDriver}
						clearVehicleData={this.props.clearVehicleData}
						setVehicleComplete={this.props.setVehicleComplete}
						showVehicleForm={this.props.showVehicleForm}
						storeVehicleData={this.props.storeVehicleData}
						setChanged={this.props.setChanged}
						setNeedsSave={this.props.setNeedsSave}
					/>
				}
			</>
		);
	};
}

let mapStateToProps = (state) => ({
	state: state.vehicleReducer,
	owner: state.partnerReducer.owner,
	drivers: state.partnerReducer.drivers,
	vehicleForm: state.partnerReducer.show_vehicle_form
});

export default connect(mapStateToProps, {
	setCarType,
	setBodyOption,
	setBodyOptionChs,
	setCarPass,
	setHydroboard,
	setRamp,
	setCarLength,
	setCarWidth,
	setCarHeight,
	setGarage,
	setMedical,
	setLoader,
	setVehiclePhotoThunk,
	setLicensePlate,
	setVin,
	setCarBrand,
	setCarModel,
	setVehicleType,
	setBuildYear,
	setEcology,
	setStsSeries,
	setStsNumber,
	setMaxWeight,
	setLoadWeight,
	setCertificatePhotoThunk,
	setDriver,
	clearVehicleData,
	setVehicleComplete,
	showVehicleForm,
	storeVehicleData,
	setChanged,
	setNeedsSave
})(VehicleContainer);
