import React from "react";
import Bank from "./Bank";
import { connect } from "react-redux";
import {
	setBankAccount,
	setBankBic,
	setBankName,
	setBankCorr,
	setBankInn,
	setBankKpp,
	setBankAddress,
	setBankComplete
} from "../../redux/reducers/bankReducer";
import {
	showBankForm,
	storeBankData,
	setChanged,
	setNeedsSave
} from "../../redux/reducers/partnerReducer";

class BankContainer extends React.Component {
	render() {
		return (
			<>
				{this.props.bankForm &&
					<Bank
						state={this.props.state}
						setBankAccount={this.props.setBankAccount}
						setBankBic={this.props.setBankBic}
						setBankName={this.props.setBankName}
						setBankCorr={this.props.setBankCorr}
						setBankInn={this.props.setBankInn}
						setBankKpp={this.props.setBankKpp}
						setBankAddress={this.props.setBankAddress}
						setBankComplete={this.props.setBankComplete}
						showBankForm={this.props.showBankForm}
						storeBankData={this.props.storeBankData}
						setChanged={this.props.setChanged}
						setNeedsSave={this.props.setNeedsSave}
					/>
				}
			</>
		);
	};
}

let mapStateToProps = (state) => ({
	state: state.bankReducer,
	bankForm: state.partnerReducer.show_bank_form
});

export default connect(mapStateToProps, {
	setBankAccount,
	setBankBic,
	setBankName,
	setBankCorr,
	setBankInn,
	setBankKpp,
	setBankAddress,
	setBankComplete,
	showBankForm,
	storeBankData,
	setChanged,
	setNeedsSave
})(BankContainer);
