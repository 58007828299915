import React from "react";
import "./sass/common.sass";
import "./sass/Register/register.sass";
import { BrowserRouter, Route, Switch, withRouter } from "react-router-dom";
import { connect, Provider } from "react-redux";
import store from "./redux/store";
import {
	PhoneContainer,
	PartnerContainer,
	Finish
} from "./components";

class App extends React.Component {


	componentDidMount() {
		window.state = store.getState();
		let query = window.location.search;
		if (query !== '' && query.slice(0, 4) === '?id=') query = query.slice(4);
		if (query.length > 0) return;
		if (this.props.state.code_verify_status !== 'OK' || !this.props.state.agree)
			this.props.history.push('/phone');
	}
	
	render() {
		return (
			 <Switch>
				<Route exact path='/phone' component={PhoneContainer} />
				<Route exact path='/' component={PartnerContainer} />
				<Route exact path='/finish' component={Finish} />
			</Switch>
		);
	}
}

const mapStateToProps = (state) => ({
	state: state.phoneReducer
});

let AppContainer = connect(mapStateToProps, {})(withRouter(App));

const base = window.location.pathname.replace('/phone', '');

const RegisterApp = (props) => {
	return <Provider store={store}>
		<BrowserRouter basename={base}>
			<AppContainer state={props.state}/>
		</BrowserRouter>
	</Provider>
}

export default RegisterApp;
