import * as axios from "axios";

export const token = "4907ed3e0ba286c611e621c3db1588fe3ce7f53c";

const instance = axios.create({
	baseURL: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs',
	headers: {
		Authorization: "Token " + token,
		ContentType: "application/json",
		Accept: "application/json"
	}
});

export const addressAPI = {
	getAddressByCoords(lat, lon) {
		let requestData = { lat, lon };
		return instance.post('/geolocate/address', requestData)
			.then(response => response)
			.catch(error => error);
	},

	getCountries(query) {
		let requestData = {query, count: 5};
		return instance.post('/suggest/country', requestData)
			.then(response => response)
			.catch(error => error);
	},

	getCities(query, country) {
		let requestData = {
			query,
			count: 5,
			from_bound: { value: "city" },
			to_bound: { value: "settlement" },
			locations: [{ country }],
			restrict_value: true
		};
		return instance.post('/suggest/address', requestData)
			.then(response => response)
			.catch(error => error);
	},

	getStreets(query, city_code) {
		let requestData = {
			query,
			count: 5,
			from_bound: { value: "street" },
			to_bound: { value: "street" },
			locations: [{ city_fias_id: city_code }],
			restrict_value: true
		};
		return instance.post('/suggest/address', requestData)
			.then(response => response)
			.catch(error => error);
	},

	getHouses(query, street_code) {
		let requestData = {
			query,
			count: 5,
			from_bound: { value: "house" },
			to_bound: { value: "house" },
			locations: [{ street_fias_id: street_code }],
			restrict_value: true
		};
		return instance.post('/suggest/address', requestData)
			.then(response => response)
			.catch(error => error);
	},

	getSuggestions(query) {
		let requestData = {
			query,
			count: 5,
			locations: [{ country: "*" }],
			from_bound: { value: "country" },
			to_bound: { value: "street" }
		};
		return instance.post('/suggest/address', requestData)
			.then(response => response)
			.catch(error => error);
	},

	splitAddress(query) {
		let requestData = {
			query,
			count: 1,
			locations: [{ country: "*" }]
		};
		return instance.post('/suggest/address', requestData)
			.then(response => response)
			.catch(error => error);
	}
};

export const getAddress = async (value) => {
	let response = await addressAPI.splitAddress(value);
	if (response && response.data && response.data.suggestions && response.data.suggestions.length > 0) {
		return response.data.suggestions[0];
	} else {
		return null;
	}
}

export const partiesAPI = {
	getParties(query) {
		let requestData = {
			query,
			count: 5
		}
		return instance.post('/findById/party', requestData)
			.then(response => response)
			.catch(error => error);
	}
};
