import React, { useRef, useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { BankSuggestions } from "react-dadata";
import { token } from "../../api/dadata-api";

const editingChars = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab', 'Home', 'End'];

const Bank = ({
	state,
	setBankAccount,
	setBankBic,
	setBankName,
	setBankCorr,
	setBankInn,
	setBankKpp,
	setBankAddress,
	setBankComplete,
	showBankForm,
	storeBankData,
	setChanged,
	setNeedsSave
}) => {
	const accountRef = useRef(null);
	const bicRef = useRef(null);
	const [error, setError] = useState(false);

	useEffect(() => {
		const onBackButtonEvent = (e) => {
			e.preventDefault();
			showBankForm(false);
			window.history.pushState(null, null, window.location.pathname);
		}
		window.history.pushState(null, null, window.location.pathname);
		window.addEventListener('popstate', onBackButtonEvent);
		return () => {
			window.removeEventListener('popstate', onBackButtonEvent);  
		};
	}, [showBankForm]);

	return (
		<div className="active_tab">
			{/*<div className="active_tab_modal"></div>*/}
			<div className="active_tab_heading">
				<div className="active_tab_title">Реквизиты ИП</div>
			</div>
			<div className="active_tab_body">
				<div className="bank_container">
					<div className="bank_container_item">
						<label htmlFor="bank_bic" className="bank_input_label">БИК банка</label>
						<BankSuggestions
							ref={bicRef}
							token={token}
							count={5}
							defaultQuery={state.bic}
							onChange={(suggestion) => {
								if (suggestion.data && suggestion.data.state &&
									suggestion.data.state.status === 'ACTIVE') {
									if (bicRef.current) bicRef.current.setInputValue(suggestion.data.bic);
									setBankBic(suggestion.data.bic)
									setBankName(suggestion.value);
									setBankCorr(suggestion.data.correspondent_account);
									setBankInn(suggestion.data.inn || '');
									setBankKpp(suggestion.data.kpp || '');
									setBankAddress(suggestion.data.address);
									storeBankData();
									setChanged(true);
								} else {
									if (bicRef.current) bicRef.current.setInputValue('');
								}
								setBankComplete(false);
							}}
							inputProps={{id: 'bank_bic', className: 'bank_input' +
								(state.bic.length === 9 ? ' bank_input-complete' : (error ? ' error' : '')),
								placeholder: 'XXXXXXXXX', type: 'tel', onKeyDown: (e) => {
									if ((!/[0-9]/.test(e.key) && !editingChars.includes(e.key)) ||
										(/[0-9]/.test(e.key) && e.target.value.length === 9)) {
										e.preventDefault();
									}
								},
								onChange: (e) => {
									setBankBic('');
									setBankName('');
									setBankCorr('');
									setBankInn('');
									setBankKpp('');
									setBankAddress(null);
									setBankComplete(false);
								}
							}}
						/>
					</div>
					<div className="bank_container_item">
						<label htmlFor="bank_name" className="bank_input_label">Наименование банка</label>
						<input id="bank_name" value={state.name}
							className={'bank_input' + (state.name !== '' ? ' bank_input-complete' : '')}
							placeholder="Название" type="text" readOnly
						/>
					</div>
					<div className="bank_container_item">
						<label htmlFor="bank_corr" className="bank_input_label">Корр. счет банка</label>
						<input id="bank_corr" value={state.corr}
							className={'bank_input' + (state.corr !== '' ? ' bank_input-complete' : '')}
							placeholder="XXXXXXXXXXXXXXXXXXXX" type="text" readOnly
						/>
					</div>
				</div>
				<div className="bank_container">
					<div className="bank_container_item">
						<label htmlFor="bank_inn" className="bank_input_label">ИНН банка</label>
						<input id="bank_inn" value={state.inn}
							className={'bank_input' + (state.inn !== '' ? ' bank_input-complete' : '')}
							placeholder="XXXXXXXXXX" type="text" readOnly
						/>
					</div>
					<div className="bank_container_item">
						<label htmlFor="bank_kpp" className="bank_input_label">КПП банка</label>
						<input id="bank_kpp" value={state.kpp}
							className={'bank_input' + (state.kpp !== '' ? ' bank_input-complete' : '')}
							placeholder="XXXXXXXXX" type="text" readOnly
						/>
					</div>
					<div className="bank_container_item">
						<label htmlFor="bank_address" className="bank_input_label">Юридический адрес банка</label>
						<input id="bank_address" value={state.address ? state.address.value : ''}
							className={'bank_input' + (state.address ? ' bank_input-complete' : '')}
							placeholder="Адрес" type="text" readOnly
						/>
					</div>
				</div>
				<div className="bank_container">
					<div className="bank_container_item">
						<label htmlFor="bank_account" className="bank_input_label">Расчетный счет в банке</label>
						<InputMask mask="99999999999999999999" value={state.account}
							onChange={(e) => {
								setBankAccount(e.target.value);
								if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
									storeBankData();
									setChanged(true);
								}
								setBankComplete(false);
							}}
						>
							{(inputProps) => <input {...inputProps} id="bank_account"
								ref={accountRef} className={'bank_input' + (state.account !== '' &&
								state.account.indexOf('_') === -1 ? ' bank_input-complete' :
								(error ? ' error' : ''))} placeholder="XXXXXXXXXXXXXXXXXXXX" type="tel" />
							}
						</InputMask>
					</div>
				</div>
				<div className={'active_tab_button' + (state.account === '' ||
					state.account.indexOf('_') !== -1 || state.bic.length !== 9 ?
					' active_tab_button-inactive' : '')}
					onClick={() => {
						setChanged(true);
						setNeedsSave(true);
						if (state.bic.length !== 9) {
							setError(true);
							bicRef.current.focus();
							return;
						}
						if (state.account === '' || state.account.indexOf('_') !== -1) {
							setError(true);
							accountRef.current.focus();
							return;
						}
						setBankComplete(true);
						storeBankData();
						showBankForm(false);
						setChanged(true);
					}}>
					<div className="active_tab_button_text">Сохранить</div>
				</div>
				<div className="active_tab_back" onClick={() => showBankForm(false)}>Назад</div>
			</div>
		</div>
	);
}

export default Bank;
