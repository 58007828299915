import React, { useRef, useState, useEffect } from "react";
import InputMask from "react-input-mask";
import { Checkbox, Select } from "../Elements";
import { vehicleAPI } from "../../api/register-api";
import image from "./blueprint.jpg";

const editingChars = ['ArrowLeft', 'ArrowRight', 'Backspace', 'Delete', 'Tab', 'Home', 'End'];

const useForceUpdate = () => {
	const [value, setValue] = useState(0);
	return () => setValue(value => value > 65534 ? 0 : value + 1);
}

const Vehicle = ({
	vehicleIndex,
	state,
	owner,
	drivers,
	singleCar,
	setCarType,
	setBodyOption,
	setBodyOptionChs,
	setCarPass,
	setHydroboard,
	setRamp,
	setCarLength,
	setCarWidth,
	setCarHeight,
	setGarage,
	setMedical,
	setLoader,
	setVehiclePhoto,
	setLicensePlate,
	setVin,
	setCarBrand,
	setCarModel,
	setVehicleType,
	setBuildYear,
	setEcology,
	setStsSeries,
	setStsNumber,
	setMaxWeight,
	setLoadWeight,
	setCertificatePhoto,
	setDriver,
	clearVehicleData,
	setVehicleComplete,
	showVehicleForm,
	storeVehicleData,
	setChanged,
	setNeedsSave
}) => {
	const carTypeRef = useRef(null);
	const bodyOptionRef = useRef(null);
	const lengthRef = useRef(null);
	const widthRef = useRef(null);
	const heightRef = useRef(null);
	//const garageRef = useRef(null);
	const licensePlateRef = useRef(null);
	const vinRef = useRef(null);
	const brandRef = useRef(null);
	//const typeRef = useRef(null);
	const buildRef = useRef(null);
	//const ecologyRef = useRef(null);
	const stsSeriesRef = useRef(null);
	const stsNumberRef = useRef(null);
	const maxWeightRef = useRef(null);
	const loadWeightRef = useRef(null);
	const driverRef = useRef(null);
	const rampInputRef = useRef(null);
	const carInsideRef = useRef(null);
	const carFrontRef = useRef(null);
	const carSideRef = useRef(null);
	const stsFrontRef = useRef(null);
	const stsRearRef = useRef(null);
	const helpRef = useRef(null);

	const [carTypes, setCarTypes] = useState([]);
	const [carTypesLoading, setCarTypesLoading] = useState(false);
	const [bodyOptions, setBodyOptions] = useState([]);
	const [bodyOptionsLoading, setBodyOptionsLoading] = useState(false);
	const [values, setValues] = useState([]);
	//const [hydroboards, setHydroboards] = useState([]);
	const [ramps, setRamps] = useState([]);
	const [characteristicsLoading, setCharacteristicsLoading] = useState(false);
	const [passes, setPasses] = useState([]);
	const [passesLoading, setPassesLoading] = useState(false);
	const [error, setError] = useState(false);
	const [rampClose, setRampClose] = useState(false);
	const [carPassClose, setCarPassClose] = useState(false);
	const [driverClose, setDriverClose] = useState(false);
	const [help, openHelp] = useState(false);
	const [needsUpdate, setNeedsUpdate] = useState(false);
	const forceUpdate = useForceUpdate();

	useEffect(() => {
		const onBackButtonEvent = (e) => {
			e.preventDefault();
			showVehicleForm(false);
			window.history.pushState(null, null, window.location.pathname);
		}
		window.history.pushState(null, null, window.location.pathname);
		window.addEventListener('popstate', onBackButtonEvent);
		return () => {
			window.removeEventListener('popstate', onBackButtonEvent);
		};
	}, [showVehicleForm]);

	useEffect(() => {
		function handleClickOutside(e) {
			if (helpRef.current && !helpRef.current.contains(e.target)) {
				openHelp(false);
			}
		}
		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	});

	useEffect(() => {
		/*if (garageRef.current)
			garageRef.current.setInputValue(state.garage ? state.garage.address : '');*/
		setCarTypesLoading(true);
		vehicleAPI.getCarTypes()
			.then((carTypesResponse) => {
				if (carTypesResponse && carTypesResponse.data) {
					setCarTypes(carTypesResponse.data);
					setCarTypesLoading(false);
					if (state.car_type !== '') {
						setBodyOptionsLoading(true);
						vehicleAPI.getBodyOptions(state.car_type)
							.then((bodyOptionsResponse) => {
								if (bodyOptionsResponse && bodyOptionsResponse.data) {
									setBodyOptions(bodyOptionsResponse.data);
									setBodyOptionsLoading(false);
									if (state.body_option !== '') {
										setCharacteristicsLoading(true);
										//setHydroboards([]);
										setRamps([]);
										vehicleAPI.getBodyOptionChs(state.body_option,
											carTypesResponse.data.find((carType) => (
												carType.id === state.car_type)).body_type_id,
											state.car_type)
											.then((characteristicsResponse) => {
												if (characteristicsResponse && characteristicsResponse.data) {
													if (!state.characteristics)
														setBodyOptionChs(characteristicsResponse.data);
													let values = [];
													characteristicsResponse.data.forEach((ch) => {
														if (ch.type === 'ref') {
															vehicleAPI.getBodyOptionChValues(ch.id, state.car_type)
																.then((valuesResponse) => {
																	if (valuesResponse && valuesResponse.data) {
																		values.push({'id': ch.id, 'values': valuesResponse.data});
																		/*if (ch.name === 'Гидроборт') {
																			setHydroboards(valuesResponse.data);
																		}*/
																		if (ch.name === 'Тип пандуса') {
																			setRamps(valuesResponse.data);
																		}
																	}
																	setNeedsUpdate(true);
																});
														}

													});
													setValues(values);
												}
												setCharacteristicsLoading(false);
											});
									}
								}
							});
					}
				}
			});

		// т.к. нужно выбирать максимальное кольцо то приходится сортировать вручную
		setPasses([
			{
				"name": "Пропуск ЦКАД",
				"type": "Boolean",
				"id": "7a03cb82-5ee8-4095-acc0-fb352c48cf44"
			},
			{
				"name": "Пропуск МКАД",
				"type": "Boolean",
				"id": "60bdcf2e-ae63-11e2-90ae-001a64b04e42"
			},
			{
				"name": "Пропуск ТТК",
				"type": "Boolean",
				"id": "22dba865-4bd4-11df-ab17-005056000008"
			},
			{
				"name": "Пропуск СК",
				"type": "Boolean",
				"id": "22dba867-4bd4-11df-ab17-005056000008"
			}
		])
		// setPassesLoading(true);
		// vehicleAPI.getCarPasses()
		// 	.then((res) => {
		// 		if (res && res.data) {
		// 			setPasses(res.data);
		// 		}
		// 		setPassesLoading(false);
		// 	});
	}, []);

	useEffect(() => {
		if (needsUpdate) {
			forceUpdate();
			setNeedsUpdate(false);
		}
	}, [needsUpdate, forceUpdate]);
	const valueIsTrue = (val) => val === true || val === 'true'  || (val+'').toLowerCase().trim() === 'true';

	return (
		<div className="active_tab">
			{/*<div className="active_tab_modal"></div>*/}
			<div className="active_tab_heading">
				<div className="active_tab_title">Данные ТС</div>
			</div>
			<div className="active_tab_body">
				<div className="vehicle_container">
					<div className="vehicle_subtitle desktop_only">Технические характеристики</div>
					<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_car_type" className="vehicle_input_label">Грузоподъемность</label>
						<Select
							id="vehicle_car_type"
							ref={carTypeRef}
							className={'vehicle_select' + (state.car_type ? ' vehicle_select-complete' :
							(error ? ' error' : ''))}
							placeholder="Выбрать"
							loading={carTypesLoading}
							options={carTypes}
							fieldName="weight_to"
							value={carTypes ? carTypes.find((type) => type.id === state.car_type) : null}
							onSelect={(option) => {
								setCarType(option.id);
								storeVehicleData(vehicleIndex);
								setChanged(true);
								setVehicleComplete(false);
								if (state.car_type !== option.id) {
									setBodyOption('');
									//setHydroboard('');
									setRamp('');
									setBodyOptionChs([]);
									//setValues([]);
									//setHydroboards([]);
									setRamps([]);
									setBodyOptionsLoading(true);
									vehicleAPI.getBodyOptions(option.id)
										.then((response) => {
											if (response && response.data) {
												setBodyOptions(response.data);
											}
											setBodyOptionsLoading(false);
										});
								}
							}}
							display={(text) => 'до ' + text + ' кг'}
						/>
					</div>
					<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_body_option" className="vehicle_input_label">Тип кузова</label>
						<Select
							id="vehicle_body_option"
							ref={bodyOptionRef}
							className={'vehicle_select' + (state.body_option ? ' vehicle_select-complete' :
							(error ? ' error' : ''))}
							placeholder="Выбрать"
							loading={bodyOptionsLoading}
							options={bodyOptions}
							fieldName="name"
							value={bodyOptions && state.body_option !== '' ?
								bodyOptions.find((option) => option.id === state.body_option) : ''
							}
							onSelect={(option) => {
								setBodyOption(option.id);
								storeVehicleData(vehicleIndex);
								setChanged(true);
								if (state.body_option !== option.id) {
									//setHydroboard('');
									setRamp('');
									setBodyOptionChs([]);
									setValues([]);
									//setHydroboards([]);
									setRamps([]);
									setCharacteristicsLoading(true);
									vehicleAPI.getBodyOptionChs(option.id, option.body_type_id, state.car_type)
										.then((chResponse) => {
											if (chResponse && chResponse.data) {
												let data = [];
												chResponse.data.forEach((ch) => {
													if (ch.type === 'ref') {
														vehicleAPI.getBodyOptionChValues(ch.id, state.car_type)
															.then((vResponse) => {
																if (vResponse && vResponse.data) {
																	data.push({'id': ch.id, 'values': vResponse.data});
																	/*if (ch.name === 'Гидроборт') {
																		setHydroboards(vResponse.data);
																	}*/
																	if (ch.name === 'Тип пандуса') {
																		setRamps(vResponse.data);
																	}
																}
																setNeedsUpdate(true);
															});
													}
												});
												setValues(data);
												setBodyOptionChs(chResponse.data);
											}
											setCharacteristicsLoading(false);
										});
								}
							}}
						/>
					</div>
					<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_car_pass" className="vehicle_input_label">Пропуск</label>
						<Select
							id="vehicle_car_pass"
							className={'vehicle_select' + (state.pass ? ' vehicle_select-complete' : '')}
							placeholder="Выбрать"
							loading={passesLoading}
							options={passes ? passes.concat([{id: '', name: ''}]) : []}
							value={passes && state.characteristics && passes.find((pass) => state.characteristics.some(x=> x.id === pass.id && valueIsTrue(x.value) )) ?
								passes.find((pass) => state.characteristics.some(x=> x.id === pass.id && valueIsTrue(x.value) )).name : state.pass === 'none' ? 'Нет пропуска' : ''}
							close={carPassClose}
							onClose={() => setCarPassClose(false)}
							onSelect={(pass) => {
								console.log('pass.id', pass.id);
								setCarPass(pass.id);
								storeVehicleData(vehicleIndex);
								setChanged(true);
							}}
							dropdownRenderer={() => (
								<>
									{passes && passes.map((pass, i) => (
										<div key={'pass_' + i} className="select_dropdown_item" tabIndex="1"
											onMouseDown={() => {
												console.log('pass.id', pass.id, state.characteristics);
												// setCarPass(pass.id);
												// storeVehicleData(vehicleIndex);
												// setChanged(true);
												// setCarPassClose(true);

													let chs = state.characteristics;
													chs = [...chs.filter( x=> !passes.some(z=> z.id === x.id ))];
													//chs.find((c) => (c.id === ch.id)).value = value.id;
													chs.push({name: pass.name , id: pass.id, value: true});
													setBodyOptionChs(chs);
													storeVehicleData(vehicleIndex);
													setChanged(true);
													setCarPass(pass.id);
													setCarPassClose(true);
											}}
										>
											<div className="select_dropdown_item_text">{pass.name}</div>
										</div>
									)).concat([
										<div key="pass_none" className="select_dropdown_item" tabIndex="1"
											onMouseDown={() => {
												// setCarPass('none');
												// storeVehicleData(vehicleIndex);
												// setChanged(true);
												// setCarPassClose(true);

												let chs = state.characteristics;
												chs = chs.filter( x=> !passes.some(z=> z.id === x.id ));
												//chs.find((c) => (c.id === ch.id)).value = value.id;
												setBodyOptionChs(chs);
												storeVehicleData(vehicleIndex);
												setChanged(true);
												setCarPass('none');
												setCarPassClose(true);
											}}
										>
											<div className="select_dropdown_item_text">Нет пропуска</div>
										</div>
									])}
								</>
							)}
						/>
					</div>
					{!characteristicsLoading && state.characteristics &&
						state.characteristics.filter((ch) => ((ch.type === 'ref' || ch.type === 'number') &&
							ch.name !== 'Тип пандуса'))
						.map((ch, i) => (
							<div key={'characteristic_' + i} className="vehicle_container_item vehicle_container_item-short">
								<label htmlFor={'vehicle_' + ch.id} className="vehicle_input_label">{ch.name}</label>
								{ch.type === 'ref' &&
									<Select
										id={'vehicle_' + ch.id}
										className={'vehicle_select' + (ch.value && ch.value !== undefined ?
											' vehicle_select-complete' : '')}
										placeholder="Выбрать"
										options={values && values.length > 0 ? values.find((entry) => (
											entry.id === ch.id))?.values : []}
										fieldName="name"
										value={values && values.length > 0 ? values.find((entry) => (
											entry.id === ch.id))?.values?.find((value) => (value.id === ch.value)) : null}
										onSelect={(value) => {
											let chs = state.characteristics;
											chs.find((c) => (c.id === ch.id)).value = value.id;
											setBodyOptionChs(chs);
											storeVehicleData(vehicleIndex);
											setChanged(true);
										}}
									/>
								}
								{ch.type === 'number' &&
									<input
										id={'vehicle_' + ch.id}
										className={'vehicle_input' + (ch.value && ch.value !== undefined ?
											' vehicle_input-complete' : '')}
										type="number"
										placeholder="Выбрать"
										value={state.characteristics.find((c) => (
											c.id === ch.id)) ? state.characteristics.find((c) => (
											c.id === ch.id)).value : ''}
										onChange={(e) => {
											let chs = state.characteristics;
											let index = chs.findIndex((c) => c.id === ch.id);
											if (index >= 0) chs[index].value = e.target.value;
											setBodyOptionChs(chs);
											storeVehicleData(vehicleIndex);
											setChanged(true);
										}}
									/>
								}

								{/*ch.type === 'Boolean' &&
									<Checkbox
										  checked={state.characteristics.find((c) => (c.id === ch.id)) ? state.characteristics.find((c) => (c.id === ch.id)).value : false}
										  onChange={(e) => {
											  let chs = state.characteristics;
											  let index = chs.findIndex((c) => c.id === ch.id);
											  if (index >= 0) chs[index].value = e.target.value;
											  setBodyOptionChs(chs);
											  storeVehicleData(vehicleIndex);
											  setChanged(true);
										  }}
									/>
								*/}
							</div>
						))
					}
					{/*<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_body_option_chs" className="vehicle_input_label">
							Характеристики
						</label>
						<Select
							id="vehicle_body_option_chs"
							className="vehicle_select"
							placeholder="Выбрать"
							loading={characteristicsLoading}
							options={state.characteristics ? state.characteristics.filter((ch) => (
								ch.name !== 'Медкнижка' && ch.name !== 'Водитель грузчик' &&
								ch.name !== 'Гидроборт' && ch.name !== 'Тип пандуса')) : []}
							fieldName="name"
							leaveOpen={true}
							dropdownRenderer={() => state.characteristics && state.characteristics.length > 0 ?
								state.characteristics.map((ch, i) => (
									ch.type === 'Boolean' && ch.name !== 'Медкнижка' && ch.name !== 'Водитель грузчик' ?
										<div key={'option_' + i} tabIndex="1"
											className="select_dropdown_item select_dropdown_item-lined"
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													let chs = state.characteristics;
													let index = chs.findIndex((c) => c.id === ch.id);
													if (index >= 0) chs[index].value = chs[index].value === undefined ?
														true : !chs[index].value;
													setBodyOptionChs(chs);
												}
											}}
										>
											<table className="vehicle_select_table" onMouseDown={() => {
												let chs = state.characteristics;
												let index = chs.findIndex((c) => c.id === ch.id);
												if (index >= 0) chs[index].value = chs[index].value === undefined ?
													true : !chs[index].value;
												setBodyOptionChs(chs);
											}}>
												<tbody>
													<tr>
														<td style={{width: '1.67rem'}}>
															<Checkbox checked={state.characteristics.find((c) => (
																c.id === ch.id)) ? state.characteristics.find((c) => (
															c.id === ch.id)).value : false} />
														</td>
														<td className="select_dropdown_item_label">{ch.name}</td>
													</tr>
												</tbody>
											</table>
											</div>
									: ch.type === 'number' ?
										<div key={'option_' + i} tabIndex="1"
											className="select_dropdown_item select_dropdown_item-lined">
											<label htmlFor={'option_' + i} className="select_dropdown_item_text">
												{ch.name}:
											</label>
											<input id={'option_' + i} className="select_dropdown_item_input" type="number"
												placeholder="Выбрать" value={state.characteristics.find((c) => (
												c.id === ch.id)) ? state.characteristics.find((c) => (
												c.id === ch.id)).value : ''} onChange={(e) => {
													let chs = state.characteristics;
													let index = chs.findIndex((c) => c.id === ch.id);
													if (index >= 0) chs[index].value = e.target.value;
													setBodyOptionChs(chs);
												}}
											/>
										</div>
									: ch.type === 'ref' && ch.name !== 'Гидроборт' && ch.name !== 'Тип пандуса' ?
										<div key={'option_' + i} tabIndex="1"
											className="select_dropdown_item select_dropdown_item-lined">
											<label className="select_dropdown_item_text">{ch.name}:</label>
											<table className="vehicle_select_table">
												<tbody>
													{values && values.length > 0 ? values.find((entry) => (
														entry.id === ch.id))?.values.map((v, index) => (
															<tr key={'rev_' + i + '_' + index} onMouseDown={() => {
																let chs = state.characteristics;
																chs[i].value = chs[i].value === v.id ? undefined : v.id;
																setBodyOptionChs(chs);
															}}>
																<td style={{width: '1.67rem'}}>
																	<Checkbox checked={state.characteristics.length > 0 ?
																		state.characteristics[i].value === v.id : false} />
																</td>
																		<td className="select_dropdown_item_label">{v.name}</td>
										 					</tr>
										 				)) : null
									 			}
												</tbody>
											</table>
										</div> : null
								)) : null
							}
							contentRenderer={() => !state.characteristics || state.characteristics.length === 0 ? '' :
								state.characteristics.filter((ch) => ch.value !== undefined && ch.value !== null &&
									ch.value !== false && ch.name !== 'Медкнижка' && ch.name !== 'Водитель грузчик' &&
									ch.name !== 'Гидроборт' && ch.name !== 'Тип пандуса'
								).map((ch) => (
									ch.type === 'Boolean' ?
										ch.name
									: ch.type === 'number' ?
										ch.name + ': ' + ch.value
									: ch.type === 'ref' ?
										ch.name + ': ' + (values && values.length > 0 &&
											values.find((entry) => entry.id === ch.id)?.values
											.find((value) => value.id === ch.value).name)
									: ''
								)).join(', ')
							}
						/>
					</div>*/}
					{/*<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_hydroboard" className="vehicle_input_label">Гидроборт</label>
						<Select
							id="vehicle_hydroboard"
							className={'vehicle_select' + (state.hydroboard ? ' vehicle_select-complete' : '')}
							placeholder="Выбрать"
							loading={characteristicsLoading}
							options={hydroboards}
							fieldName="name"
							value={hydroboards ? hydroboards.find((h) => h.id === state.hydroboard) : null}
							onSelect={(option) => setHydroboard(option.id)}
							display={(text) => text + ' кг'}
						/>
					</div>*/}
					{state.body_option &&
						<div className="vehicle_container_item vehicle_container_item-short">
							<div style={{width: '95%', display: 'flex', justifyContent: 'space-between'}}>
								<label htmlFor="vehicle_ramp" className="vehicle_input_label">Под пандус</label>
								<span className="vehicle_help" onClick={() => openHelp(true)}>
									<div className="vehicle_help_symbol">?</div>
									{help &&
										<div ref={helpRef} className="vehicle_help_frame">
											<img src={image} alt="Вид ТС" />
											<div className="vehicle_help_text">
											</div>
										</div>
									}
								</span>
							</div>
							<Select
								id="vehicle_ramp"
								className={'vehicle_select' + (state.ramp ? ' vehicle_select-complete' : '')}
								placeholder="Выбрать"
								loading={characteristicsLoading}
								options={ramps ? ramps.concat([{id: '', name: ''}]) : []}
								value={ramps ? (ramps.find((ramp) => ramp.id === state.ramp) ?
									ramps.find((ramp) => ramp.id === state.ramp).name : state.ramp) : ''}
								display={(text) => text + ' см'}
								close={rampClose}
								onClose={() => setRampClose(false)}
								onSelect={(ramp) => {
									setRamp(ramp.id);
									storeVehicleData(vehicleIndex);
									setChanged(true);
								}}
								dropdownRenderer={() => (
									<>
										{state.car_type && state.body_option && ramps && ramps.map((ramp, i) => (
											<div key={'ramp_' + i} className="select_dropdown_item" tabIndex="1"
												onMouseDown={() => {
													setRamp(ramp.id);
													storeVehicleData(vehicleIndex);
													setChanged(true);
													setRampClose(true);
												}}
											>
												<div className="select_dropdown_item_text">{ramp.name} см</div>
											</div>
										)).concat([
											<div key="ramp_custom" className="select_dropdown_item" tabIndex="1"
												onFocus={() => {
													if (rampInputRef.current) rampInputRef.current.focus();
												}}>
												<input className="select_dropdown_item_input" type="number"
													placeholder="Иное" style={{marginTop: '0.42rem', width: '70%'}}
													ref={rampInputRef} value={ramps &&
													ramps.find((ramp) => ramp.id === state.ramp) ? '' : state.ramp}
													onChange={(e) => {
														setRamp(e.target.value);
														storeVehicleData(vehicleIndex);
														setChanged(true);
													}}
													onKeyDown={(e) => {
														if (e.key === 'Enter') {
															e.preventDefault();
															e.stopPropagation();
															setRampClose(true);
														}
														if (e.key === 'ArrowDown') e.preventDefault();
													}} />
													<span className="select_dropdown_item_label">см</span>
											</div>
										])}
									</>
								)}
							/>
						</div>
					}
				</div>
				<div className="vehicle_container">
					<div className="vehicle_container_item vehicle_container_item-short
						vehicle_subcontainer_item">
						<label htmlFor="vehicle_length" className="vehicle_input_label">Длина кузова</label>
						<input id="vehicle_length" ref={lengthRef}
							value={state.length ? state.length.replace('.', ',') : ''}
							className={'vehicle_input vehicle_input-short' +
								(state.length && state.length !== '' & parseFloat(state.length.replace(',', '.')) > 0 ?
								' vehicle_input-complete' : (error ? ' error' : ''))}
							placeholder="в метрах" type="text" onKeyPress={(e) => {
								if ((e.target.value.length === 5 && !editingChars.includes(e.key)) ||
									!(/[0-9]/.test(e.key) || ['.', ','].includes(e.key))) {
									e.preventDefault();
									return;
								}
								if (['.', ','].includes(e.key)) {
									e.preventDefault();
									if (state.length.indexOf(',') === -1)
										document.execCommand('insertText', false, ',');
								}
							}}
							onChange={(e) => {
								if (parseFloat(e.target.value.replace(',', '.')) >= 0 || e.target.value === '') {
									setCarLength(e.target.value);
									storeVehicleData(vehicleIndex);
									setChanged(true);
								}
								setVehicleComplete(false);
							}}
						/>
						<div className="vehicle_info">Пример: 3,2</div>
					</div>
					<div className="vehicle_container_item vehicle_container_item-short
						vehicle_subcontainer_item">
						<label htmlFor="vehicle_width" className="vehicle_input_label">Ширина кузова</label>
						<input id="vehicle_width" ref={widthRef}
							value={state.width ? state.width.replace('.', ',') : ''}
							className={'vehicle_input vehicle_input-short' +
								(state.width && state.width !== '' && parseFloat(state.width.replace(',', '.')) > 0 ?
								' vehicle_input-complete' : (error ? ' error' : ''))}
							placeholder="в метрах" type="text" onKeyPress={(e) => {
								if ((e.target.value.length === 5 && !editingChars.includes(e.key)) ||
									!(/[0-9]/.test(e.key) || ['.', ','].includes(e.key))) {
									e.preventDefault();
									return;
								}
								if (['.', ','].includes(e.key)) {
									e.preventDefault();
									if (state.width.indexOf(',') === -1)
										document.execCommand('insertText', false, ',');
								}
							}}
							onChange={(e) => {
								if (parseFloat(e.target.value.replace(',', '.')) >= 0 || e.target.value === '') {
									setCarWidth(e.target.value);
									storeVehicleData(vehicleIndex);
									setChanged(true);
								}
								setVehicleComplete(false);
							}}
						/>
						<div className="vehicle_info">Пример: 1,9</div>
					</div>
					<div className="vehicle_container_item vehicle_container_item-short
						vehicle_subcontainer_item">
						<label htmlFor="vehicle_height" className="vehicle_input_label">Высота кузова</label>
						<input id="vehicle_height" ref={heightRef}
							value={state.height ? state.height.replace('.', ',') : ''}
							className={'vehicle_input vehicle_input-short' +
								(state.height && state.height !== '' && parseFloat(state.height.replace(',', '.')) > 0 ?
								' vehicle_input-complete' : (error ? ' error' : ''))}
							placeholder="в метрах" type="text" onKeyPress={(e) => {
								if ((e.target.value.length === 5 && !editingChars.includes(e.key)) ||
									!(/[0-9]/.test(e.key) || ['.', ','].includes(e.key))) {
									e.preventDefault();
									return;
								}
								if (['.', ','].includes(e.key)) {
									e.preventDefault();
									if (state.height.indexOf(',') === -1)
										document.execCommand('insertText', false, ',');
								}
							}}
							onChange={(e) => {
								if (parseFloat(e.target.value.replace(',', '.')) >= 0 || e.target.value === '') {
									setCarHeight(e.target.value);
									storeVehicleData(vehicleIndex);
									setChanged(true);
								}
								setVehicleComplete(false);
							}}
						/>
						<div className="vehicle_info">Пример: 1,8</div>
					</div>
					{/*<div className="vehicle_container_item vehicle_container_item-short
						vehicle_subcontainer_item">
						<label htmlFor="vehicle_garage" className="vehicle_input_label">Место гаража</label>
						<AddressSuggestions
							ref={garageRef}
							token={token}
							count={5}
							defaultQuery={state.garage ? state.garage.address : ''}
							onChange={(suggestion) => {
								if (suggestion && suggestion.data) {
									setGarage({
										address: suggestion.value,
										latitude: suggestion.data.geo_lat,
										longitude: suggestion.data.geo_lon
									});
								}
							}}
							inputProps={{id: 'vehicle_garage', className: 'vehicle_input vehicle_input-auto' +
								(state.garage ?	' vehicle_input-complete' : (error ? ' error' : '')),
								placeholder: 'Выбрать', onChange: (e) => setGarage(null)
							}}
						/>
						{navigator.geolocation &&
							<div className="vehicle_geo" onClick={() => {
								navigator.geolocation.getCurrentPosition(async (pos) => {
									let response = await addressAPI.getAddressByCoords(pos.coords.latitude,
										pos.coords.longitude);
									if (response && response.data && response.data.suggestions.length > 0) {
										setGarage({
											address: response.data.suggestions[0].value,
											latitude: response.data.suggestions[0].data.geo_lat,
											longitude: response.data.suggestions[0].data.geo_lon
										});
									}
								});
							}}>
								Моё местоположение
							</div>
						}
					</div>*/}
					<div className="vehicle_container_item">
						<table className="vehicle_checkbox_container">
							<tbody>
								{!characteristicsLoading && state.characteristics &&
									state.characteristics.filter((ch) => (ch.type === 'Boolean' &&
									ch.name !== 'Медкнижка' && ch.name !== 'Водитель грузчик'))
										.map((ch, i) => (
											<tr key={'checkbox_' + i} onClick={() => {
												let chs = state.characteristics;
												let index = chs.findIndex((c) => c.id === ch.id);
												if (index >= 0) chs[index].value = chs[index].value === undefined ?
													true : !chs[index].value;
												setBodyOptionChs(chs);
												storeVehicleData(vehicleIndex);
												setChanged(true);
											}}>
												<td className="vehicle_checkbox">
													<Checkbox checked={state.characteristics.find((c) => (
														c.id === ch.id)) ? state.characteristics.find((c) => (
														c.id === ch.id)).value : false}
													/>
												</td>
												<td className="vehicle_checkbox_label">{ch.name}</td>
											</tr>
										))
								}
								<tr onClick={() => {
									setMedical(!state.medical);
									storeVehicleData(vehicleIndex);
									setChanged(true);
								}}>
									<td className="vehicle_checkbox">
										<Checkbox checked={state.medical} />
									</td>
									<td className="vehicle_checkbox_label">Медкнижка</td>
								</tr>
								<tr onClick={() => {
									setLoader(!state.loader);
									storeVehicleData(vehicleIndex);
									setChanged(true);
								}}>
									<td className="vehicle_checkbox">
										<Checkbox checked={state.loader} />
									</td>
									<td className="vehicle_checkbox_label">Водитель-грузчик (оплачивается)</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div className="vehicle_container"
					style={{flexDirection: 'column', alignItems: 'flex-start'}}>
					<div className="vehicle_subtitle">Прикрепить фото ТС</div>
					<div className="vehicle_photo_container">
						<div className="vehicle_photo">
							<label className="vehicle_photo_label">Кузов внутри</label>
							<label htmlFor="vehicle_photo_0" ref={carInsideRef} tabIndex="1"
								className={'vehicle_photo_button' + (state.vehicle_photo_0 ?
								' vehicle_photo_button-complete' : '')}>
								<div className="vehicle_photo_button_text">
									{state.vehicle_photo_0 ? state.vehicle_photo_0.name : 'Фото 1'}
								</div>
							</label>
							<input type="file" id="vehicle_photo_0" accept="image/*"
								onChange={async (e) => {
									if (e.target.files.length !== 0) {
										await setVehiclePhoto(0, e.target.files[0]);
										storeVehicleData(vehicleIndex);
										setChanged(true);
									}
								}}
							/>
						</div>
						<div className="vehicle_photo">
							<label className="vehicle_photo_label">ТС спереди</label>
							<label htmlFor="vehicle_photo_1" ref={carFrontRef} tabIndex="1"
								className={'vehicle_photo_button' + (state.vehicle_photo_1 ?
									' vehicle_photo_button-complete' : '')}>
								<div className="vehicle_photo_button_text">
									{state.vehicle_photo_1 ? state.vehicle_photo_1.name : 'Фото 2'}
								</div>
							</label>
							<input type="file" id="vehicle_photo_1" accept="image/*"
								onChange={async (e) => {
									if (e.target.files.length !== 0) {
										await setVehiclePhoto(1, e.target.files[0]);
										storeVehicleData(vehicleIndex);
										setChanged(true);
									}
								}}
							/>
						</div>
						<div className="vehicle_photo">
							<label className="vehicle_photo_label">ТС сбоку</label>
							<label htmlFor="vehicle_photo_2" ref={carSideRef} tabIndex="1"
								className={'vehicle_photo_button' + (state.vehicle_photo_2 ?
								' vehicle_photo_button-complete' : '')}>
								<div className="vehicle_photo_button_text">
									{state.vehicle_photo_2 ? state.vehicle_photo_2.name : 'Фото 3'}
								</div>
							</label>
							<input type="file" id="vehicle_photo_2" accept="image/*"
								onChange={async (e) => {
									if (e.target.files.length !== 0) {
										await setVehiclePhoto(2, e.target.files[0]);
										storeVehicleData(vehicleIndex);
										setChanged(true);
									}
								}}
							/>
						</div>
					</div>
				</div>
				<div className="vehicle_container">
					<div className="vehicle_subtitle desktop_only">Документы ТС</div>
					<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_license_plate" className="vehicle_input_label">
							Гос. номер
						</label>
						<InputMask mask="x999xx999" formatChars={{'x': '[А-Яа-я]', '9': '[0-9]'}}
							value={state.license_plate} onChange={(e) => {
							setLicensePlate(e.target.value);
							if (e.target.value !== '' && (e.target.value.indexOf('_') === -1 ||
								e.target.value.indexOf('_') === 8)) {
								storeVehicleData(vehicleIndex);
								setChanged(true);
							}
							setVehicleComplete(false);
						}}>
							{(inputProps) => <input {...inputProps} id="vehicle_license_plate"
								className={'vehicle_input' + (state.license_plate !== '' &&
								(state.license_plate.indexOf('_') === -1 ||
								state.license_plate.indexOf('_') === 8) ? ' vehicle_input-complete' :
								(error ? ' error' : ''))} ref={licensePlateRef}
								placeholder="A999AA99" type="text" onKeyPress={(e) => {
									if (!/[0-9аАвВсСеЕнНкКмМоОрРтТхХуУ]/.test(e.key)) {
										e.preventDefault();
										return;
									}
									if (/[а-я]/.test(e.key)) {
										e.preventDefault();
										document.execCommand('insertText', false, e.key.toUpperCase());
									}
								}}/>
							}
						</InputMask>
						<div className="vehicle_info">Только цифры и русские буквы</div>
					</div>
					<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_vin" className="vehicle_input_label">VIN номер</label>
						<InputMask mask="*****************" value={state.vin} onChange={(e) => {
							setVin(e.target.value);
							if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
								storeVehicleData(vehicleIndex);
								setChanged(true);
							}
							setVehicleComplete(false);
						}}>
							{(inputProps) => <input {...inputProps} id="vehicle_vin"
								className={'vehicle_input' + (state.vin !== '' && state.vin.indexOf('_') === -1 ?
								' vehicle_input-complete' : (error ? ' error' : ''))} ref={vinRef}
								placeholder="введите VIN номер" type="text"
								onKeyPress={(e) => {
									if (!/[0-9aAbBcCdDeEfFgGhHjJkKlLmMnNoOpPrRsStTuUvVwWxXyYzZ]/.test(e.key)) {
										e.preventDefault();
										return;
									}
									if (/[a-z]/.test(e.key)) {
										e.preventDefault();
										document.execCommand('insertText', false, e.key.toUpperCase());
									}
								}} />
							}
						</InputMask>
					</div>
					<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_brand" className="vehicle_input_label">Марка ТС</label>
						<input id="vehicle_brand" value={state.brand} ref={brandRef}
							className={'vehicle_input' + (state.brand.length >= 3 ?
							' vehicle_input-complete' : (error ? ' error' : ''))}
							placeholder="как в СТС" type="text" onChange={(e) => {
								setCarBrand(e.target.value);
								if (e.target.value.length >= 3) {
									storeVehicleData(vehicleIndex);
									setChanged(true);
								}
								setVehicleComplete(false);
							}}
						/>
					</div>
					<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_model" className="vehicle_input_label">Модель ТС</label>
						<input id="vehicle_model" value={state.model}
							className={'vehicle_input' + (state.model !== '' ? ' vehicle_input-complete' : '')}
							placeholder="как в СТС" type="text" onChange={(e) => {
								setCarModel(e.target.value);
								if (e.target.value !== '') {
									storeVehicleData(vehicleIndex);
									setChanged(true);
								}
								setVehicleComplete(false);
							}}
						/>
					</div>
					{/*<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_type" className="vehicle_input_label">Тип ТС</label>
						<input id="vehicle_type" value={state.type} ref={typeRef}
							className={'vehicle_input' + (state.type.length >= 5 ?
							' vehicle_input-complete' : (error ? ' error' : ''))}
							placeholder="как в СТС" type="text" onChange={(e) => setVehicleType(e.target.value)}
						/>
					</div>*/}
					<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_year" className="vehicle_input_label">Год выпуска</label>
						<input id="vehicle_year" value={state.year} ref={buildRef}
							className={'vehicle_input' + (parseInt(state.year) >= 2000 &&
							parseInt(state.year) <= new Date().getFullYear() ?
							' vehicle_input-complete' : (error ? ' error' : ''))}
							placeholder="ГГГГ" type="tel" onKeyPress={(e) => {
								if ((e.target.value.length === 4 && !editingChars.includes(e.key)) ||
									!/[0-9]/.test(e.key)) {
									e.preventDefault();
								}
							}} onChange={(e) => {
								setBuildYear(e.target.value);
								if (parseInt(e.target.value) >= 2000 &&
									parseInt(e.target.value) <= new Date().getFullYear()) {
									storeVehicleData(vehicleIndex);
									setChanged(true);
								}
								setVehicleComplete(false);
							}}
						/>
					</div>
					{/*<div className="vehicle_container_item">
						<label htmlFor="vehicle_ecology" className="vehicle_input_label">
							Экологический класс
						</label>
						<Select
							id="vehicle_ecology"
							ref={ecologyRef}
							className={'vehicle_select' + (state.ecology ? ' vehicle_select-complete' :
								(error ? ' error' : ''))}
							placeholder="Выбрать"
							options={['1', '2', '3', '4', '5']}
							value={state.ecology}
							onSelect={(option) => setEcology(option)}
						/>
					</div>*/}
					<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_sts_series" className="vehicle_input_label">Серия СТС</label>
						<InputMask mask="99 **" value={state.sts_series} onChange={(e) => {
							setStsSeries(e.target.value);
							if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
								storeVehicleData(vehicleIndex);
								setChanged(true);
							}
							setVehicleComplete(false);
						}}>
							{(inputProps) => <input {...inputProps} id="vehicle_sts_series"
								className={'vehicle_input' + (state.sts_series !== '' &&
								state.sts_series.indexOf('_') === -1 ? ' vehicle_input-complete' :
								(error ? ' error' : ''))} ref={stsSeriesRef}
								placeholder="введите серию СТС" type="text" onKeyPress={(e) => {
									if (!/[0-9aAbBcCeEhHkKmMoOpPtTxXyY]/.test(e.key)) {
										e.preventDefault();
										return;
									}
									if (/[a-z]/.test(e.key)) {
										e.preventDefault();
										document.execCommand('insertText', false, e.key.toUpperCase());
									}
								}} />
							}
						</InputMask>
					</div>
					<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_sts_number" className="vehicle_input_label">Номер СТС</label>
						<InputMask mask="999999" value={state.sts_number} onChange={(e) => {
							setStsNumber(e.target.value);
							if (e.target.value !== '' && e.target.value.indexOf('_') === -1) {
								storeVehicleData(vehicleIndex);
								setChanged(true);
							}
							setVehicleComplete(false);
						}}>
							{(inputProps) => <input {...inputProps} id="vehicle_sts_number"
								className={'vehicle_input' + (state.sts_number !== '' &&
								state.sts_number.indexOf('_') === -1 ? ' vehicle_input-complete' :
								(error ? ' error' : ''))} ref={stsNumberRef}
								placeholder="введите номер СТС" type="tel" />
							}
						</InputMask>
					</div>
					<div className="vehicle_container_item vehicle_container_item-short">
						<label htmlFor="vehicle_max_weight" className="vehicle_input_label">
							Разрешенная макс. масса
						</label>
						<input id="vehicle_max_weight" value={state.max_weight} ref={maxWeightRef}
							className={'vehicle_input' + (parseInt(state.max_weight) > 0 ?
							' vehicle_input-complete' : (error ? ' error' : ''))}
							placeholder="как в СТС" type="tel" onKeyPress={(e) => {
								if ((e.target.value.length === 5 && !editingChars.includes(e.key)) ||
									!/[0-9]/.test(e.key)) {
									e.preventDefault();
								}
							}} onChange={(e) => {
								setMaxWeight(e.target.value);
								if (parseInt(e.target.value) > 0) {
									storeVehicleData(vehicleIndex);
									setChanged(true);
								}
								setVehicleComplete(false);
							}}
						/>
					</div>
					<div className="vehicle_container_item vehicle_container_item-short"
						style={{alignSelf: 'flex-end'}}>
						<label htmlFor="vehicle_load_weight" className="vehicle_input_label">
							Снаряженная масса
						</label>
						<input id="vehicle_load_weight" value={state.load_weight} ref={loadWeightRef}
							className={'vehicle_input' + (parseInt(state.load_weight) > 0 ?
							' vehicle_input-complete' : (error ? ' error' : ''))}
							placeholder="как в СТС" type="tel" onKeyPress={(e) => {
								if ((e.target.value.length === 5 && !editingChars.includes(e.key)) ||
									!/[0-9]/.test(e.key)) {
									e.preventDefault();
								}
							}} onChange={(e) => {
								setLoadWeight(e.target.value);
								if (parseInt(e.target.value) > 0) {
									storeVehicleData(vehicleIndex);
									setChanged(true);
								}
								setVehicleComplete(false);
							}}
						/>
					</div>
				</div>
				<div className="vehicle_container"
					style={{flexDirection: 'column', alignItems: 'flex-start'}}>
					<div className="vehicle_subtitle">Прикрепить фото СТС</div>
					<div className="vehicle_photo_container">
						<div className="vehicle_photo">
							<label className="vehicle_photo_label">Фото 1</label>
							<label htmlFor="certificate_photo_0" ref={stsFrontRef} tabIndex="1"
								className={'vehicle_photo_button' + (state.certificate_photo_0 ?
								' vehicle_photo_button-complete' : (error ? ' error' : ''))}>
								<div className="vehicle_photo_button_text">
									{state.certificate_photo_0 ? state.certificate_photo_0.name : 'Фото 1'}
								</div>
							</label>
							<input type="file" id="certificate_photo_0" accept="image/*"
								onChange={async (e) => {
									if (e.target.files.length !== 0) {
										await setCertificatePhoto(0, e.target.files[0]);
										storeVehicleData(vehicleIndex);
										setChanged(true);
									}
								}}
							/>
						</div>
						<div className="vehicle_photo">
							<label className="vehicle_photo_label">Фото 2</label>
							<label htmlFor="certificate_photo_1" ref={stsRearRef} tabIndex="1"
								className={'vehicle_photo_button' + (state.certificate_photo_1 ?
								' vehicle_photo_button-complete' : (error ? ' error' : ''))}>
								<div className="vehicle_photo_button_text">
									{state.certificate_photo_1 ? state.certificate_photo_1.name : 'Фото 2'}
								</div>
							</label>
							<input type="file" id="certificate_photo_1" accept="image/*"
								onChange={async (e) => {
									if (e.target.files.length !== 0) {
										await setCertificatePhoto(1, e.target.files[0]);
										storeVehicleData(vehicleIndex);
										setChanged(true);
									}
								}}
							/>
						</div>
					</div>
				</div>
				<div className={'vehicle_container' + (singleCar ? ' desktop_only' : '')}>
					<div className="vehicle_container_item">
						<label htmlFor="vehicle_driver" className="vehicle_input_label vehicle_input_label-bold">
							Выбрать водителя
						</label>
						{singleCar ?
							<div className="vehicle_select_replacement">
								*водитель будет выбран по умолчанию
							</div>
						:
							<Select
								id="vehicle_driver"
								ref={driverRef}
								className={'vehicle_select' + (state.driver || state.driver_later ?
									' vehicle_select-complete' : (!state.driver_later && error ? ' error' : ''))}
								placeholder="Выбрать"
								options={(owner && owner.is_driver ? [owner] : [])
									.concat(drivers).concat([{name: ''}])}
								value={state.driver ? state.driver.name :
									(state.driver_later ? 'Выбрать потом' : '')}
								close={driverClose}
								onClose={() => setDriverClose(false)}
								onSelect={(driver) => {
									if (driver.name !== '') {
										setDriver(driver, false);
									} else {
										setDriver(null, true);
									}
									storeVehicleData(vehicleIndex);
									setChanged(true);
								}}
								dropdownRenderer={() => (
									<>
										{owner && owner.is_driver &&
											<div key="vehicle_driver_owner" className="select_dropdown_item" tabIndex="1"
												onMouseDown={() => {
													setDriver(owner, false);
													storeVehicleData(vehicleIndex);
													setChanged(true);
													setDriverClose(true);
												}}
											>
												<div className="select_dropdown_item_text">{owner.name}</div>
											</div>
										}
										{drivers ? drivers.map((driver, i) => (
											<div key={'vehicle_driver_' + i} className="select_dropdown_item" tabIndex="1"
												onMouseDown={() => {
													setDriver(driver, false);
													storeVehicleData(vehicleIndex);
													setChanged(true);
													setDriverClose(true);
												}}
											>
												<div className="select_dropdown_item_text">{driver.name}</div>
											</div>
										)) : null}
										<div key="vehicle_driver_later" className="select_dropdown_item" tabIndex="1"
											onMouseDown={() => {
												setDriver(null, true);
												storeVehicleData(vehicleIndex);
												setChanged(true);
												setDriverClose(true);
											}}
										>
											<div className="select_dropdown_item_text">Выбрать потом</div>
										</div>
									</>
								)}
							/>
						}
					</div>
				</div>
				<div className={'active_tab_button' + (state.car_body === '' || state.body_option === '' ||
					state.length === '' || state.width === '' || state.height === '' ||
					state.license_plate === '' || (state.license_plate.indexOf('_') >= 0 &&
					state.license_plate.indexOf('_') < 8) || state.vin === '' ||
					state.vin.indexOf('_') !== -1 || state.brand.length < 3 ||
					state.year === '' || parseInt(state.year) < 2000 ||
					parseInt(state.year) > new Date().getFullYear() ||
					state.sts_series === '' || state.sts_series.indexOf('_') !== -1 ||
					state.sts_number === '' || state.sts_number.indexOf('_') !== -1 ||
					state.max_weight === '' || parseInt(state.max_weight) <= 0 ||
					state.load_weight === '' || parseInt(state.load_weight) <= 0 ||
					!state.certificate_photo_0 || !state.certificate_photo_1 ||
					(!singleCar && !state.driver && !state.driver_later) ?
					' active_tab_button-inactive' : '')}
					onClick={() => {
						setChanged(true);
						setNeedsSave(true);
						if (state.car_type === '') {
							setError(true);
							carTypeRef.current.focus();
							return;
						}
						if (state.body_option === '') {
							setError(true);
							bodyOptionRef.current.focus();
							return;
						}
						if (state.length === '') {
							setError(true);
							lengthRef.current.focus();
							return;
						}
						if (state.width === '') {
							setError(true);
							widthRef.current.focus();
							return;
						}
						if (state.height === '') {
							setError(true);
							heightRef.current.focus();
							return;
						}
						/*if (!state.garage) {
							setError(true);
							garageRef.current.focus();
							return;
						}*/
						if (state.license_plate === '' || (state.license_plate.indexOf('_') >= 0 &&
							state.license_plate.indexOf('_') < 8)) {
							setError(true);
							licensePlateRef.current.focus();
							return;
						}
						if (state.vin === '' || state.vin.indexOf('_') !== -1) {
							setError(true);
							vinRef.current.focus();
							return;
						}
						if (state.brand.length < 3) {
							setError(true);
							brandRef.current.focus();
							return;
						}
						/*if (state.type.length < 5) {
							setError(true);
							typeRef.current.focus();
							return;
						}*/
						if (state.year === '' || parseInt(state.year) < 2000 ||
							parseInt(state.year) > new Date().getFullYear()) {
							setError(true);
							buildRef.current.focus();
							return;
						}
						/*if (!state.ecology) {
							setError(true);
							ecologyRef.current.focus();
							return;
						}*/
						if (state.sts_series === '' || state.sts_series.indexOf('_') !== -1) {
							setError(true);
							stsSeriesRef.current.focus();
							return;
						}
						if (state.sts_number === '' || state.sts_number.indexOf('_') !== -1) {
							setError(true);
							stsNumberRef.current.focus();
							return;
						}
						if (state.max_weight === '' || parseInt(state.max_weight) <= 0) {
							setError(true);
							maxWeightRef.current.focus();
							return;
						}
						if (state.load_weight === '' || parseInt(state.load_weight) <= 0) {
							setError(true);
							loadWeightRef.current.focus();
							return;
						}
						if (!state.certificate_photo_0) {
							setError(true);
							stsFrontRef.current.focus();
							return;
						}
						if (!state.certificate_photo_1) {
							setError(true);
							stsRearRef.current.focus();
							return;
						}
						if (!singleCar && !state.driver && !state.driver_later) {
							setError(true);
							driverRef.current.focus();
							return;
						}
						if (singleCar) setDriver(drivers.length > 0 ? drivers[0] : null,
							drivers.length === 0 || !drivers[0]);
						setVehicleComplete(true);
						storeVehicleData(vehicleIndex);
						clearVehicleData();
						showVehicleForm(false);
						setChanged(true);
					}}>
					<div className="active_tab_button_text">Сохранить</div>
				</div>
				<div className="active_tab_back" onClick={() => {
					if (state.should_clear) clearVehicleData();
					showVehicleForm(false);
				}}>Назад</div>
			</div>
		</div>
	);
}

export default Vehicle;
