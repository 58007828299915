export const validateName = (name) => {
	if (name === '') return false;
	let words = name.trim().split(' ');
	if (words.length < 3) return false;
	for (let i = 0; i < words.length; i++) {
		if (words[i] === '') return false;
		if (i < 3 && !/[А-Я-]/.test(words[i][0])) return false;
		if (i >= 3 && !/[а-яА-Я]/.test(words[i][0])) return false;
		if (/[^а-я-]/.test(words[i].slice(1))) return false;
	}
	return true;
};

export const validateNumber = (number, digits) => {
	let cleanNumber = number.replace(/_/g, '');
	return (cleanNumber.length === digits);
};

export const validatePhone = (phone) => {
	let cleanPhone = trimPhone(phone);	
	return (cleanPhone.length === 11);
};

export const validateOrg = (org) => {
	return (org && org.length >= 5) && 
		!(/[^-а-яА-Я0-9№.,/ ()"]/.test(org));
};

export const validateDate = (date) => {
	if (!date) return false;
	return (date.getFullYear() >= 1900 && date.getFullYear() <= new Date().getFullYear());
}

export const validateFutureDate = (date) => {
	if (!date) return false;
	return (date.getFullYear() >= new Date().getFullYear());
}

export const trimPhone = (phone) => {
	let cleanPhone = phone.replace(/_/g, '')
		.replace('(', '')
		.replace(')', '')
		.replace('+', '')
		.replace(/ /g, '')
		.replace(/-/g, '');
	return cleanPhone;
};

export const validateEmail = (email) => {
	if (!email || email === '') return true;
	let parts = email.split('@');
	if (parts.length !== 2) return false;
	if (parts[0].length === 0) return false;
	if (parts[1].length === 0 || parts[1].indexOf('.') <= 0 ||
		parts[1].indexOf('.') === parts[1].length - 1) return false;
	return true;
};

const padToTwoChars = (string) => {
	let str = '' + string;
	return str.length === 1 ? '0' + str : str;
}

export const convertDate = (date) => {
	if (!date) return '';
	return date.getFullYear() + '.' + padToTwoChars(date.getMonth() + 1) + '.' +
		padToTwoChars(date.getDate());
}

export const getBoolean = (value) => {
	switch (value) {
		case true: return true;
		case 'true': return true;
		case false: return false;
		case 'false': return false;
		case undefined: return false;
		default: return false;
	}
}

export const createNumber = (series, number) => {
	let result = '';
	if (series) result += series;
	if (number) result += number;
	while (result.length > 0 && result.length < 10) result += '_';
	return result;
}

export const genKey = () => {
	const hexChars = '0123456789abcdef'.split('');
	let result = '';
	for (let i = 0; i < 16; i++) {
		result = result + hexChars[Math.floor(Math.random() * hexChars.length)];
	}
	return result;
}
