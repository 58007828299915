const SET_BANK_ACCOUNT = 'SET_BANK_ACCOUNT';
const SET_BANK_BIC = 'SET_BANK_BIC';
const SET_BANK_NAME = 'SET_BANK_NAME';
const SET_BANK_CORR = 'SET_BANK_CORR';
const SET_BANK_INN = 'SET_BANK_INN';
const SET_BANK_KPP = 'SET_BANK_KPP';
const SET_BANK_ADDRESS = 'SET_BANK_ADDRESS';
const SET_BANK_COMPLETE = 'SET_BANK_COMPLETE';

let initialState = {
	account: '',
	bic: '',
	name: '',
	corr: '',
	inn: '',
	kpp: '',
	address: null,
	complete: false
};

const bankReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_BANK_ACCOUNT:
			return {
				...state,
				account: action.account
			}
		case SET_BANK_BIC:
			return {
				...state,
				bic: action.bic
			}
		case SET_BANK_NAME:
			return {
				...state,
				name: action.name
			}
		case SET_BANK_CORR:
			return {
				...state,
				corr: action.corr
			}
		case SET_BANK_INN:
			return {
				...state,
				inn: action.inn
			}
		case SET_BANK_KPP:
			return {
				...state,
				kpp: action.kpp
			}
		case SET_BANK_ADDRESS:
			return {
				...state,
				address: action.address
			}
		case SET_BANK_COMPLETE:
			return {
				...state,
				complete: action.value
			}
		default:
			return state;
	}
};

export const setBankAccount = (account) => ({type: SET_BANK_ACCOUNT, account});
export const setBankBic = (bic) => ({type: SET_BANK_BIC, bic});
export const setBankName = (name) => ({type: SET_BANK_NAME, name});
export const setBankCorr = (corr) => ({type: SET_BANK_CORR, corr});
export const setBankInn = (inn) => ({type: SET_BANK_INN, inn});
export const setBankKpp = (kpp) => ({type: SET_BANK_KPP, kpp});
export const setBankAddress = (address) => ({type: SET_BANK_ADDRESS, address});
export const setBankComplete = (value) => ({type: SET_BANK_COMPLETE, value});

export const validateBank = (bank) => {
	return bank.bic.length === 9 &&
		bank.account !== '' && bank.account.indexOf('_') < 0;
}

export const loadBankData = () => (dispatch, getState) => {
	let bank = getState().partnerReducer.bank;
	if (bank) {
		dispatch(setBankAccount(bank.account));
		dispatch(setBankBic(bank.bic));
		dispatch(setBankName(bank.name));
		dispatch(setBankCorr(bank.corr));
		dispatch(setBankInn(bank.inn));
		dispatch(setBankKpp(bank.kpp));
		dispatch(setBankAddress(bank.address));
		dispatch(setBankComplete(validateBank(bank)));
	}
}

export default bankReducer;
