import { SINGLE_CAR_OWNER } from "./partnerReducer";
import {
	validateName,
	validateDate,
	validateFutureDate,
	validatePhone,
	validateOrg
} from "../../components/validate";
import { sendPhoto } from "../../api/register-api";

const SET_DRIVER_NAME = 'SET_DRIVER_NAME';
const ADD_DRIVER_PHONE = 'ADD_DRIVER_PHONE';
const REMOVE_DRIVER_PHONE = 'REMOVE_DRIVER_PHONE';
const DELETE_DRIVER_PHONES = 'DELETE_DRIVER_PHONES';
const SET_DRIVER_EMAIL = 'SET_DRIVER_EMAIL';
const SET_DRIVER_BIRTHDATE = 'SET_DRIVER_BIRTHDATE';
const SET_DRIVER_PASSPORT_NUMBER = 'SET_DRIVER_PASSPORT_NUMBER';
const SET_DRIVER_PASSPORT_ISSUED_BY = 'SET_DRIVER_PASSPORT_ISSUED_BY';
const SET_DRIVER_PASSPORT_ISSUE_DATE = 'SET_DRIVER_PASSPORT_ISSUE_DATE';
const SET_DRIVER_PASSPORT_DEPARTMENT = 'SET_DRIVER_PASSPORT_DEPARTMENT';
const SET_DRIVER_PASSPORT_REGISTRATION = 'SET_DRIVER_PASSPORT_REGISTRATION';
const SET_DRIVER_ADDRESS = 'SET_DRIVER_ADDRESS';
const SET_DRIVER_PASSPORT_PHOTO = 'SET_DRIVER_PASSPORT_PHOTO';
const SET_DRIVER_LICENSE_NUMBER = 'SET_DRIVER_LICENSE_NUMBER';
const SET_DRIVER_LICENSE_ISSUE_DATE = 'SET_DRIVER_LICENSE_ISSUE_DATE';
const SET_DRIVER_LICENSE_VALIDITY = 'SET_DRIVER_LICENSE_VALIDITY';
const SET_DRIVER_LICENSE_ISSUED_BY = 'SET_DRIVER_LICENSE_ISSUED_BY';
const SET_DRIVER_LICENSE_COUNTRY = 'SET_DRIVER_LICENSE_COUNTRY';
const SET_DRIVER_LICENSE_CATEGORIES = 'SET_DRIVER_LICENSE_CATEGORIES';
const SET_DRIVER_LICENSE_PHOTO = 'SET_DRIVER_LICENSE_PHOTO';
const CLEAR_DRIVER_STATE = 'CLEAR_DRIVER_STATE';
const SET_DRIVER_CLEAR = 'SET_DRIVER_CLEAR';
const SET_DRIVER_COMPLETE = 'SET_DRIVER_COMPLETE';

const initialState = {
	name: '',
	phones: [],
	email: '',
	birthdate: null,
	passport_number: '',
	passport_issued_by: '',
	passport_issue_date: null,
	passport_department: '',
	passport_registration: null,
	address: null,
	same_address: false,
	passport_photo_id_0: '',
	passport_photo_id_1: '',
	passport_photo_id_2: '',
	passport_photo_0: null,
	passport_photo_1: null,
	passport_photo_2: null,
	is_driver: true,
	license_number: '',
	license_issue_date: null,
	license_validity: null,
	license_issued_by: '',
	license_country: '',
	license_categories: [],
	license_photo_id_0: '',
	license_photo_id_1: '',
	license_photo_0: null,
	license_photo_1: null,
	should_clear: false,
	complete: false
};

const driverReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_DRIVER_NAME:
			return {
				...state,
				name: action.name
			}
		case ADD_DRIVER_PHONE:
			let phonesToAdd = [...state.phones];
			if (phonesToAdd.length < action.index) {
				phonesToAdd.push(action.phone);
			} else {
				phonesToAdd[action.index] = action.phone;
			}
			return {
				...state,
				phones: phonesToAdd
			}
		case REMOVE_DRIVER_PHONE:
			let phonesToRemove = [...state.phones];
			phonesToRemove.splice(action.index, 1);
			return {
				...state,
				phones: phonesToRemove
			}
		case DELETE_DRIVER_PHONES:
			return {
				...state,
				phones: []
			}
		case SET_DRIVER_EMAIL:
			return {
				...state,
				email: action.email
			}
		case SET_DRIVER_BIRTHDATE:
			return {
				...state,
				birthdate: action.date
			}
		case SET_DRIVER_PASSPORT_NUMBER:
			return {
				...state,
				passport_number: action.number
			}
		case SET_DRIVER_PASSPORT_ISSUED_BY:
			return {
				...state,
				passport_issued_by: action.org
			}
		case SET_DRIVER_PASSPORT_ISSUE_DATE:
			return {
				...state,
				passport_issue_date: action.date
			}
		case SET_DRIVER_PASSPORT_DEPARTMENT:
			return {
				...state,
				passport_department: action.department
			}
		case SET_DRIVER_PASSPORT_REGISTRATION:
			return {
				...state,
				passport_registration: action.address,
				same_address: action.same_address,
				address: action.same_address ? action.address :
					action.same_address === state.same_address ? state.address : null
			}
		case SET_DRIVER_ADDRESS:
			return {
				...state,
				address: action.address
			}
		case SET_DRIVER_PASSPORT_PHOTO:
			return {
				...state,
				passport_photo_0: action.index === 0 ? action.photo : state.passport_photo_0,
				passport_photo_1: action.index === 1 ? action.photo : state.passport_photo_1,
				passport_photo_2: action.index === 2 ? action.photo : state.passport_photo_2,
				passport_photo_id_0: action.index === 0 ? action.id : state.passport_photo_id_0,
				passport_photo_id_1: action.index === 1 ? action.id : state.passport_photo_id_1,
				passport_photo_id_2: action.index === 2 ? action.id : state.passport_photo_id_2
			}
		case SET_DRIVER_LICENSE_NUMBER:
			return {
				...state,
				license_number: action.number
			}
		case SET_DRIVER_LICENSE_ISSUE_DATE:
			let validity = state.license_validity;
			if (action.date && validateDate(action.date)) {
				validity = new Date(action.date);
				validity.setFullYear(action.date.getFullYear() + 10);
			}
			return {
				...state,
				license_issue_date: action.date,
				license_validity: validity
			}
		case SET_DRIVER_LICENSE_VALIDITY:
			return {
				...state,
				license_validity: action.date
			}
		case SET_DRIVER_LICENSE_ISSUED_BY:
			return {
				...state,
				license_issued_by: action.org
			}
		case SET_DRIVER_LICENSE_COUNTRY:
			return {
				...state,
				license_country: action.country
			}
		case SET_DRIVER_LICENSE_CATEGORIES:
			return {
				...state,
				license_categories: action.categories
			}
		case SET_DRIVER_LICENSE_PHOTO:
			return {
				...state,
				license_photo_0: action.index === 0 ? action.photo : state.license_photo_0,
				license_photo_1: action.index === 1 ? action.photo : state.license_photo_1,
				license_photo_id_0: action.index === 0 ? action.id : state.license_photo_id_0,
				license_photo_id_1: action.index === 1 ? action.id : state.license_photo_id_1
			}
		case CLEAR_DRIVER_STATE:
			return initialState;
		case SET_DRIVER_CLEAR:
			return {
				...state,
				should_clear: action.clear
			}
		case SET_DRIVER_COMPLETE:
			return {
				...state,
				complete: action.value
			}
		default:
			return state;
	}
};

export const setDriverName = (name) => ({type: SET_DRIVER_NAME, name});
export const addDriverPhone = (index, phone) => ({type: ADD_DRIVER_PHONE, index, phone});
export const removeDriverPhone = (index) => ({type: REMOVE_DRIVER_PHONE, index});
const deleteDriverPhones = () => ({type: DELETE_DRIVER_PHONES});
export const setDriverEmail = (email) => ({type: SET_DRIVER_EMAIL, email});
export const setDriverBirthdate = (date) => ({type: SET_DRIVER_BIRTHDATE, date});
export const setDriverPassportNumber = (number) => ({type: SET_DRIVER_PASSPORT_NUMBER, number});
export const setDriverPassportIssuedBy = (org) => ({type: SET_DRIVER_PASSPORT_ISSUED_BY, org});
export const setDriverPassportIssueDate = (date) => ({type: SET_DRIVER_PASSPORT_ISSUE_DATE, date});
export const setDriverPassportDepartment = (department) => (
	{type: SET_DRIVER_PASSPORT_DEPARTMENT, department}
);
export const setDriverPassportRegistration = (address, same_address) => (
	{type: SET_DRIVER_PASSPORT_REGISTRATION, address, same_address}
);
export const setDriverAddress = (address) => ({type: SET_DRIVER_ADDRESS, address});
export const setDriverPassportPhoto = (index, photo, id) => ({type: SET_DRIVER_PASSPORT_PHOTO, index, photo, id});
export const setDriverLicenseNumber = (number) => ({type: SET_DRIVER_LICENSE_NUMBER, number});
export const setDriverLicenseIssueDate = (date) => ({type: SET_DRIVER_LICENSE_ISSUE_DATE, date});
export const setDriverLicenseValidity = (date) => ({type: SET_DRIVER_LICENSE_VALIDITY, date});
export const setDriverLicenseIssuedBy = (org) => ({type: SET_DRIVER_LICENSE_ISSUED_BY, org});
export const setDriverLicenseCountry = (country) => ({type: SET_DRIVER_LICENSE_COUNTRY, country});
export const setDriverLicenseCategories = (categories) => (
	{type: SET_DRIVER_LICENSE_CATEGORIES, categories}
);
export const setDriverLicensePhoto = (index, photo, id) => ({type: SET_DRIVER_LICENSE_PHOTO, index, photo, id});
export const clearDriverData = () => ({type: CLEAR_DRIVER_STATE});
const setDriverClear = (clear) => ({type: SET_DRIVER_CLEAR, clear});
export const setDriverComplete = (value) => ({type: SET_DRIVER_COMPLETE, value});

export const setDriverPassportPhotoThunk = (index, photo) => async (dispatch) => {
	const id = await sendPhoto(photo);
	dispatch(setDriverPassportPhoto(index, photo, id));
}

export const setDriverLicensePhotoThunk = (index, photo) => async (dispatch) => {
	const id = await sendPhoto(photo);
	dispatch(setDriverLicensePhoto(index, photo, id));
}

export const validateDriver = (driver) => {
	return validateName(driver.name) &&
		driver.phones.length > 0 && validatePhone(driver.phones[0]) &&
		(driver.phones.length <= 1 || validatePhone(driver.phones[1])) &&
		(driver.phones.length <= 2 || validatePhone(driver.phones[2])) &&
		validateDate(driver.birthdate) &&
		driver.passport_number !== '' && driver.passport_number.indexOf('_') < 0 &&
		validateOrg(driver.passport_issued_by) &&
		validateDate(driver.passport_issue_date) &&
		driver.address && driver.address.data.house &&
		driver.passport_photo_0 && driver.passport_photo_1 && driver.passport_photo_2 &&
		(!driver.is_driver ||
		(driver.license_number !== '' && driver.license_number.indexOf('_') < 0 &&
		validateDate(driver.license_issue_date) &&
		validateFutureDate(driver.license_validity) &&
		validateOrg(driver.license_issued_by) &&
		driver.license_country.length >= 3 &&
		driver.license_categories.length > 0 &&
		driver.license_photo_0 && driver.license_photo_1));
}

export const loadDriverData = (index, clear) => (dispatch, getState) => {
	let isSingleDriver = getState().partnerReducer.owner_type === SINGLE_CAR_OWNER;
	let phone = getState().phoneReducer.phone;
	dispatch(setDriverClear(clear));
	let drivers = getState().partnerReducer.drivers;
	if (drivers.length === 0) {
		if (isSingleDriver)
			dispatch(addDriverPhone(0, phone));
		return;
	}
	if (index < drivers.length) {
		let driver = drivers[index];
		dispatch(setDriverName(driver.name));
		dispatch(deleteDriverPhones());
		driver.phones.forEach((phone, index) => dispatch(addDriverPhone(index, phone)));
		dispatch(setDriverEmail(driver.email));
		dispatch(setDriverBirthdate(driver.birthdate));
		dispatch(setDriverPassportNumber(driver.passport_number));
		dispatch(setDriverPassportIssuedBy(driver.passport_issued_by));
		dispatch(setDriverPassportIssueDate(driver.passport_issue_date));
		//dispatch(setDriverPassportDepartment(driver.passport_department));
		dispatch(setDriverAddress(driver.address));	
		//dispatch(setDriverPassportRegistration(driver.passport_registration, driver.same_address));
		dispatch(setDriverPassportPhoto(0, driver.passport_photo_0, driver.passport_photo_id_0));
		dispatch(setDriverPassportPhoto(1, driver.passport_photo_1, driver.passport_photo_id_1));
		dispatch(setDriverPassportPhoto(2, driver.passport_photo_2, driver.passport_photo_id_2));
		dispatch(setDriverLicenseNumber(driver.license_number));
		dispatch(setDriverLicenseIssueDate(driver.license_issue_date));
		dispatch(setDriverLicenseValidity(driver.license_validity));
		dispatch(setDriverLicenseIssuedBy(driver.license_issued_by));
		dispatch(setDriverLicenseCountry(driver.license_country));
		dispatch(setDriverLicenseCategories(driver.license_categories));
		dispatch(setDriverLicensePhoto(0, driver.license_photo_0, driver.license_photo_id_0));
		dispatch(setDriverLicensePhoto(1, driver.license_photo_1, driver.license_photo_id_1));
		dispatch(setDriverComplete(validateDriver(driver)));
	} else {
		dispatch(setDriverComplete(false));
	}
}

export default driverReducer;
