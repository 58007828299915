import React from "react";
import Phone from "./Phone";
import { connect } from "react-redux";
import {
	setPhoneEntered,
	setPhone,
	setAgree,
	requestCodeThunk,
	setCodeEntered,
	setCode,
	verifyCodeThunk,
	setCodeVerifyStatus,
	setCodeRequestStatus,
	setRecaptchaThunk
} from "../../redux/reducers/phoneReducer";
import { setId, loadData } from "../../redux/reducers/partnerReducer";

class PhoneContainer extends React.Component {
	render() {
		return (
			<>
				<Phone
					state={this.props.state}
					setPhoneEntered={this.props.setPhoneEntered}
					setPhone={this.props.setPhone}
					setAgree={this.props.setAgree}
					requestCode={this.props.requestCodeThunk}
					setCodeEntered={this.props.setCodeEntered}
					setCode={this.props.setCode}
					// verifyCode={this.props.verifyCodeThunk}
					setCodeVerifyStatus={this.props.setCodeVerifyStatus}
					setCodeRequestStatus={this.props.setCodeRequestStatus}
					setRecaptcha={this.props.setRecaptchaThunk}
					setId={this.props.setId}
					loadData={this.props.loadData}
				/>
			</>
		);
	};
}

let mapStateToProps = (state) => ({
	state: state.phoneReducer
});

export default connect(mapStateToProps, {
	setPhoneEntered,
	setPhone,
	setAgree,
	requestCodeThunk,
	setCodeEntered,
	setCode,
	verifyCodeThunk,
	setCodeVerifyStatus,
	setCodeRequestStatus,
	setRecaptchaThunk,
	setId,
	loadData
})(PhoneContainer);
